import { MyScriptLocaleType, MyScriptMimeType, MyScriptRecognitionType } from "../../../nl-lib3-common/typedef/myscript-types/myscript-types";
import { MauiRelaySocket, MauiToJsBridge, sleep } from "../nl-lib-maui";
import { IDiscoveredDevices } from "../../../nl-lib3-common/interfaces/IDiscoveredDevices";
import { MauiToJsEventName } from "../nl-lib-maui/maui-bridge/maui-to-js-types";
import { PEN_CHARACTERISTICS_NOTIFICATION_UUID_128, PEN_CHARACTERISTICS_NOTIFICATION_UUID_16_STR, PEN_CHARACTERISTICS_WRITE_UUID_128, PEN_CHARACTERISTICS_WRITE_UUID_16_STR, PEN_SERVICE_UUID_128, PEN_SERVICE_UUID_16_STR } from "../nl-lib-pensdk/pencomm/pencomm_const";
import { NativeConversionResult } from "./ImportFromFileTypes";

export enum AcrobatReaderPrintErrorCode {
	Success = 0,
	PDFFileNotFound = 1,
	AcrobatAppNotFound = 2,
	ErrorWhilePrinting = 3,

	AcrobatAppCreateFailed = 4,
	AcrobatAVDocCreateFailed = 5,
	AcrobatPDDocCreateFailed = 6,
	AcrobatPrintPagesSilentExFailed = 7,

	JsInteropError = 8,

	OtherError = 99
}

export class NativeMethods {
	/**
	 *
	 * @param fileName
	 * @param uint8Array
	 * @param separators 기본으로는 ##문제, ##지시문, ##지문, ##문항, ##끝
	 * @returns
	 */
	public static async GetQuestionsHtmlFromWordDocBytes(
		fileName: string,
		uint8Array: Uint8Array,
		separators?: {
			questionStartSeparator?: string,
			instructionSeparator?: string,
			passageSeparator?: string,
			subQuestionSeparator?: string,
			answerSeparator?: string,
			questionEndSeparator?: string
		}) {

		let {
			questionStartSeparator = "##문제",
			instructionSeparator = "##지시문",
			passageSeparator = "##지문",
			subQuestionSeparator = "##문항",
			answerSeparator = "##답안",
			questionEndSeparator = "##끝"
		} = separators ?? {}

		const result = await MauiRelaySocket.instance.bridgeInvokeMethodAsync<NativeConversionResult>(
			"GetQuestionsHtmlFromWordDocBytes",
			fileName,
			uint8Array,
			questionStartSeparator,
			instructionSeparator,
			passageSeparator,
			subQuestionSeparator,
			answerSeparator,
			questionEndSeparator
		);

		return result?.questionares;
	}

	/**
	 * Acrobat Reader를 통해 PDF를 인쇄합니다.
	 * @param fileName
	 * @param uint8Array
	 * @returns
	 */
	public static async PrintPDFThroughAcrobatReader(
		fileName: string,
		uint8Array: Uint8Array,
		acrobatExePath = null as string
	) {
		const ret = await MauiRelaySocket.instance.bridgeInvokeMethodAsync<{ success: boolean, message: string, errorCode: AcrobatReaderPrintErrorCode }>(
			"PrintPDFThroughAcrobatReader",
			fileName,
			uint8Array,
			acrobatExePath
		);

		return ret;
	}

	/**
	 * Acrobat Reader가 설치된 경로를 반환합니다. 복수개가 있으면 우선순위가 높은 순으로 반환합니다.
	 * @returns
	 */
	public static async FindAcrobatExecutables() {
		const ret = await MauiRelaySocket.instance.bridgeInvokeMethodAsync<
			{
				exeName: string,
				folderPath: string,
				priority: number
			}[]
		>("FindAcrobatExecutables");

		return ret;
	}


	/**
	 * Word가 설치되어 있는지 확인합니다.
	 * @returns
	 */
	public static async IsWordInstalled() {
		const ret = await MauiRelaySocket.instance.bridgeInvokeMethodAsync<boolean>("IsWordInstalled");

		return ret;
	}

	public static async IsAutomationIsntalled(echoStr = null as string) {
		const e = echoStr ?? "Hello, World!";
		try {
			const ret = await MauiRelaySocket.instance.bridgeInvokeMethodAsync<string>("Echo", e);

			if (ret === e) {
				return true;
			}
		} catch (e) {
			console.error(e);
		}

		return false

	}

	public static async GetAutomationVersion() {
		try {
			const ret = await MauiRelaySocket.instance.bridgeInvokeMethodAsync<string>("GetVersion");
			return ret;

		} catch (e) {
			console.error(e);
		}

		return null;
	}

	public static async RequestRecognizeWithTask(locale: MyScriptLocaleType, outputMimeType: MyScriptMimeType, inkstorePageJson: string, typeString: MyScriptRecognitionType) {
		const ret = await MauiRelaySocket.instance.bridgeInvokeMethodAsync<Uint8Array>(
			"RequestRecognizeWithTask",
			locale,
			outputMimeType,
			inkstorePageJson,
			typeString
		);

		if (!ret) return null;

		// convert to unicode string
		const decoder = new TextDecoder("utf-8");
		const decodedString = decoder.decode(ret);

		try {
			const result = JSON.parse(decodedString);
			return result;
		}
		catch (e) {
			console.error(e);
		}
		return decodedString;
	}

	public static async StartScan(args: {
		namePrefix?: string,
		timeout?: number,
		callBackFunc: (args: { devices: IDiscoveredDevices[] }) => void
	}) {
		const { namePrefix = null, timeout = 120, callBackFunc } = args;
		await MauiRelaySocket.instance.bridgeInvokeMethodAsync("SetUuidConstants",
			PEN_SERVICE_UUID_16_STR,
			PEN_CHARACTERISTICS_WRITE_UUID_16_STR,
			PEN_CHARACTERISTICS_NOTIFICATION_UUID_16_STR,
			PEN_SERVICE_UUID_128,
			PEN_CHARACTERISTICS_WRITE_UUID_128,
			PEN_CHARACTERISTICS_NOTIFICATION_UUID_128
		)
		const ret = await MauiRelaySocket.instance.bridgeInvokeMethodAsync<{ success: boolean, maxSamplingInterval: number, minSamplingInterval: number }>("StartScan");

		MauiToJsBridge.instance.setEventListener(MauiToJsEventName.onDeviceDiscoveringUpdate, callBackFunc);

		return ret;
	}


	public static async StopScan() {
		await MauiRelaySocket.instance.bridgeInvokeMethodAsync("StopScan");
		// await sleep(2000);
	}

	public static async DisconnectAllPens() {
		await MauiRelaySocket.instance.bridgeInvokeMethodAsync("DisconnectAll");
	}

	public static async GetNumConnectedPens() {
		const pens = await MauiRelaySocket.instance.bridgeInvokeMethodAsync("GetNumConnectedPens");
		return pens;
	}
}

