import { useTranslation } from 'react-i18next';
import { ReactComponent as ReportIcon } from "../../../../common/images/ReportIcon.svg";
import { ReactComponent as SideBarManage } from "../../../../common/images/SideBarManage.svg";
import { ReactComponent as SideBarOperate } from "../../../../common/images/SideBarOperate.svg";
import { ReactComponent as SideBarStudent } from "../../../../common/images/SideBarStudent.svg";
import { ReactComponent as SubSideMenu1 } from "../../../../common/images/SubSideMenu1.svg";
import { ReactComponent as SubSideMenu2 } from "../../../../common/images/SubSideMenu2.svg";
import { ReactComponent as SubSideMenu3 } from "../../../../common/images/SubSideMenu3.svg";
import { ReactComponent as SubSideMenu4 } from "../../../../common/images/SubSideMenu4.svg";
import { ReactComponent as TaskOpenMenu } from "../../../../common/images/TaskOpenMenu.svg";
import i18next from "../../../../lang";
import { globalConfig } from '../../../../config/globalConfig';

const Main = [
	// {
	//     label: i18next.t("대시보드"),
	//     // icon: <DashBoard/>,
	//     icon: <SideBarDashboard/>,
	//     link: "/dashboard",
	//     urlPrefix : "/dashboard"
	// },
	{
		label: "평가과제관리", // i18next.t("평가과제관리"),
		// icon: <ReportIcon/>,
		icon: <SideBarManage />,
		link: "/projects",
		urlPrefix: "/projects"
	}, {
		label: "평가과제운영", // i18next.t("평가과제운영"),
		// icon: <ReportIcon/>,
		icon: <SideBarOperate />,
		link: "/assignments",
		urlPrefix: "/assignments"
	}, {
		label: "학생 관리", // i18next.t("학생 관리"),
		// icon: <StudentIcon/>,
		icon: <SideBarStudent />,
		link: "/students",
		urlPrefix: "/students"
	},
]

const ProjectCreate = (projectCode: string) => {
	const childrenRag = globalConfig.useRag ? [{
		id: "rag",
		label: i18next.t("참고 자료"),
		icon: <TaskOpenMenu />,
		link: `/projects/${projectCode}/create/rag`,
		urlPrefix: "/create/rag",
		disabled: false,
		view: true,
		skip: true
	}] : [];

	const children = [
		{
			id: "info",
			label: i18next.t("과제 개요"),
			icon: <TaskOpenMenu />,
			link: `/projects/${projectCode}/create/info`,
			urlPrefix: "/create/info",
			disabled: false,
			view: true,
			skip: false
		}, {
			id: "exam",
			label: i18next.t("문항 생성"),
			icon: <TaskOpenMenu />,
			link: `/projects/${projectCode}/create/question`,
			urlPrefix: "/create/question",
			disabled: false,
			view: true,
			skip: false
		},
		...childrenRag,
		{
			id: "rubric",
			label: i18next.t("평가 기준표"),
			icon: <TaskOpenMenu />,
			link: `/projects/${projectCode}/create/rubric`,
			urlPrefix: "/create/rubric",
			disabled: false,
			view: true,
			skip: false
		}, {
			id: "view",
			label: i18next.t("과제 보기"),
			icon: <TaskOpenMenu />,
			link: `/projects/${projectCode}/create/view`,
			urlPrefix: "/create/view",
			disabled: false,
			view: false,
			skip: false
		}];

	return [
		{
			label: i18next.t("과제 만들기"),
			icon: <TaskOpenMenu />,
			link: null,
			urlPrefix: "/create",
			children: children.map((child, index) => {
				child.label = `${index + 1}. ${child.label}`
				return child
			}),
		}
	]
}

const ProjectPresent = (projectCode: string) => {
	const children = [
		{
			id: "info",
			label: i18next.t("과제 정보"),
			icon: <TaskOpenMenu />,
			link: `/assignments/${projectCode}/create/info`,
			urlPrefix: "/create/info",
			disabled: false,
			view: true,
			skip: false
		}, {
			id: "target",
			label: i18next.t("평가 대상"),
			icon: <TaskOpenMenu />,
			link: `/assignments/${projectCode}/create/target`,
			urlPrefix: "/target",
			disabled: false,
			view: true,
			skip: false
		}, {
			id: "paper",
			label: i18next.t("평가과제 선택"),
			icon: <TaskOpenMenu />,
			link: `/assignments/${projectCode}/create/paper`,
			urlPrefix: "/paper",
			disabled: false,
			view: true,
			skip: false
		}, {
			id: "submit",
			label: i18next.t("과제 제시하기"),
			icon: <TaskOpenMenu />,
			link: `/assignments/${projectCode}/create/submit`,
			urlPrefix: "/submit",
			disabled: false,
			view: true,
			skip: false
		},
	];


	return [
		{
			label: i18next.t("과제 제시하기"),
			icon: <TaskOpenMenu />,
			link: null,
			urlPrefix: "/create",
			children: children.map((child, index) => {
				child.label = `${index + 1}. ${child.label}`
				return child
			}),
		}
	]
}
const StudentFillinSubmit = (projectCode: string) => {
	return [];
}

const ProjectManager = (projectCode: string) => {
	return [
		{
			label: i18next.t("평가과제운영 상세"),
			icon: <SubSideMenu1 />,
			link: `/assignments/${projectCode}/view`,
			urlPrefix: "/view"
		}, {
			label: i18next.t("제출물 관리"),
			icon: <SubSideMenu2 />,
			link: `/assignments/${projectCode}/submissions`,
			urlPrefix: "/submissions"
		}, {
			label: i18next.t("채점 및 피드백"),
			icon: <SubSideMenu3 />,
			link: `/assignments/${projectCode}/feedbacks`,
			urlPrefix: "/feedbacks"
		}, {
			label: i18next.t("결과 내보내기"),
			icon: <SubSideMenu4 />,
			link: `/assignments/${projectCode}/export`,
			urlPrefix: "/export"
		}
	]
}

export const StudentMenu = () => {
	const i18next = useTranslation();

	return [
		{
			label: i18next.t("평가 과제"),
			icon: <ReportIcon />,
			link: `/projects`,
			urlPrefix: `/projects`
		},
	]
}

export enum MenuType {
	Main = "Main",
	ProjectCreate = "ProjectCreate",
	ProjectPresent = "ProjectPresent",
	ProjectManager = "ProjectManager",

	StudentFillinSubmit = "StudentFillinSubmit",
}

export const getMenus = (type: MenuType, projectCode: string) => {
	switch (type) {
		case MenuType.Main: return Main;
		case MenuType.ProjectCreate: return ProjectCreate(projectCode);
		case MenuType.ProjectPresent: return ProjectPresent(projectCode);
		case MenuType.ProjectManager: return ProjectManager(projectCode);
		case MenuType.StudentFillinSubmit: return StudentFillinSubmit(projectCode);
		default: return [];
	}
}

export const getMenuType = (pathname: string) => {
	let menuType = MenuType.Main;

	if (pathname.includes("/projects/") && pathname.includes("/create")) {
		menuType = MenuType.ProjectCreate;
	} else if (pathname.includes("/assignments/") && pathname.includes("/create")) {
		menuType = MenuType.ProjectPresent;
	} else if (pathname.includes("/assignments/") && pathname.includes("/submissions/test")) {
		menuType = MenuType.StudentFillinSubmit;
	} else if (pathname.includes("/assignments/")) {
		menuType = MenuType.ProjectManager;
	} else if (pathname.includes("/students/")) {
		menuType = MenuType.Main;
	}

	return menuType;
}
