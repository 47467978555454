


import { Repository } from "./Repository";
import { IProject } from "./model/IProject";
import { IProjectUser } from "./model/IProjectUser";
import { IExamInfo } from "./model/transfer/IExamInfo";
import { IExampleAnswerInfo } from "./model/transfer/IExampleAnswerInfo";
import { IProjectListOnDatabase } from "./model/transfer/IProjectListOnDatabase";


type Props = {
	serverContextPath: string;
};

export default class ProjectRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/projects";
	}

	// createProjectUsers = (data)=> {
	//   return new Promise((resolve, reject) => {
	//     this.getRequestPromise("put", `${this.requestPrefix}/users`, {},data)
	//       .then((data) => {
	//         resolve(data);
	//       })
	//       .catch((error) => {
	//         reject(error);
	//       });
	//   });
	// };


	public async createProject(data) {
		try {
			const result = await this.getRequestPromise("post", this.requestPrefix, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}
/*
	public async modifyProjectSharedState(projectCode, params) {
		try {
			const result = await this.getRequestPromise("put", this.requestPrefix + `/${projectCode}/sharedState`, params);
			return result;
		} catch (error) {
			throw error;
		}
	}
*/

	public async getProjectList(projectType: string, param, data) {
		try {
			const result = await this.getRequestPromise<IProjectListOnDatabase>("post", `${this.requestPrefix}/users/type/${projectType}/table`, param, data);
			return result;
		} catch (error) {
			throw error;
		}
	}
/*
	public async getProjectListForShare(projectType, param, data: { column: string, hint: string }[]) {
		try {
			const result = await this.getRequestPromise("post", `${this.requestPrefix}/users/type/${projectType}/share/table`, param, data);
			return result;
		} catch (error) {
			throw error;
		}
	}
*/
	public async getProject(projectCode: string) {
		try {
			const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async getProjectByProjectCode(projectCode: string) {
		try {
			const project = await this.getProject(projectCode);
			return project;
		}
		catch (error) {
			return null;
		}
	}

	public async updateProject(data) {
		try {
			const result = await this.getRequestPromise("put", this.requestPrefix, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async updateProjectState(projectCode, param) {
		try {
			const result = await this.getRequestPromise("put", `${this.requestPrefix}/${projectCode}`, param);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async deleteProject(projectCode) {
		try {
			const result = await this.getRequestPromise("delete", `${this.requestPrefix}/${projectCode}`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	
	public async getProjectStates(projectCode) {
		try {
			const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/state`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async getProjectPlanTransfer(projectCode) {
		try {
			const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/plans`, {});
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async createProjectPlan(projectCode, data) {
		try {
			const result = await this.getRequestPromise("post", `${this.requestPrefix}/${projectCode}/plans`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async modifyProjectPlan(projectCode, projectPlanCode, data) {
		try {
			const result = await this.getRequestPromise("put", `${this.requestPrefix}/${projectCode}/plans/${projectPlanCode}`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async createProjectExamList(projectCode: string, data) {
		try {
			const result = await this.getRequestPromise("post", `${this.requestPrefix}/${projectCode}/exams`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async updateProjectExamList(projectCode: string, data: IExamInfo[]) {
		try {
			const result = await this.getRequestPromise("put", `${this.requestPrefix}/${projectCode}/exams`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}
	
	public async updateExampleAnswer(data: IExampleAnswerInfo[]) {
		try {
			const result = await this.getRequestPromise("post", `${this.requestPrefix}/updateExampleAnswers`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async getProjectExamList(projectCode) {
		try {
			const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/exams`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async getProjectExamQuestions(projectCode) {
		try {
			const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/exams/questions`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async getProjectRubricList(projectExamCode, projectExamQuestionNum) {
		try {
			const result = await this.getRequestPromise("get", `${this.requestPrefix}/rubrics/${projectExamCode}/${projectExamQuestionNum}`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async createProjectRubricList(data) {
		try {
			const result = await this.getRequestPromise("post", `${this.requestPrefix}/rubrics`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}
	public async updateProjectRubricList(data) {
		try {
			const result = await this.getRequestPromise("put", `${this.requestPrefix}/rubrics`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async deleteProjectRubricList(ids: number[]) { // Accepts an array of Long (number in TypeScript)
		try {
			const result = await this.getRequestPromise(
				"post",
				`${this.requestPrefix}/rubrics/delete`,
				{}, 
				ids
			);
			return result;
		} catch (error) {
			throw error;
		}
	}
	

	public async getProjectUsers(projectCode: string) {
		try {
			const result = await this.getRequestPromise("get", `${this.requestPrefix}/${projectCode}/users`, {});
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async getProjectUserTransfers(projectCode: string, sortingHints) {
		try {
			const result = await this.getRequestPromise<IProjectUser[]>("post", `${this.requestPrefix}/${projectCode}/users/table`, {}, sortingHints);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async createProjectUsers(data) {
		try {
			const result = await this.getRequestPromise("post", `${this.requestPrefix}/users`, {}, data);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async updateProjectUserState(projectCode, userCode, params) {
		try {
			const result = await this.getRequestPromise("put", `${this.requestPrefix}/${projectCode}/users/${userCode}`, params);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async deleteProjectUser(projectCode, userCode) {
		try {
			const result = await this.getRequestPromise("delete", `${this.requestPrefix}/${projectCode}/users/${userCode}`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async deleteProjectUsers(projectCode, userCodeList) {
		try {
			const result = await this.getRequestPromise("delete", `${this.requestPrefix}/${projectCode}/users`, {}, userCodeList);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async getProjectMaxOrder(projectCode) {
		try {
			const result = await this.getRequestPromise<number>("get", `${this.requestPrefix}/${projectCode}/users/order`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async copyTemplateToCustom(customCode: string, templateCode: string) {
		try {
			const result = await this.getRequestPromise("put", `${this.requestPrefix}/CUSTOM/${customCode}/${templateCode}`);
			return result;
		} catch (error) {
			throw error;
		}
	}

	public async copyTemplate(templateCode: string) {
		try {
			const result = await this.getRequestPromise("put", `${this.requestPrefix}/TEMPLATE/${templateCode}`);
			return result;
		} catch (error) {
			throw error;
		}
	}

}
