import { IAiScore } from "./model/IAiScore";
import { ExternalApiStatus } from "./model/support/ExternalApiStatus";
import { Repository } from "./Repository";

type Props = {
	serverContextPath: string;
};

export default class AiScoreRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/projects/submissions/answers";
	}

	public async createAIScore(answerCode: string, language: string, force: boolean) {
		let locale = "ja-JP";
		switch (language) {
			case "ko":
				locale = "ko-KR";
				break;
			case "en":
				locale = "en-US";
				break;
			case "zh":
				locale = "zh-CN";
				break;
			default:
				locale = "ja-JP";
				break;
		}
		const data = await this.getRequestPromise<void>("post", `${this.requestPrefix}/${answerCode}/rubrics/ai?languageCode=${locale}${force ? "&force=true" : ""}`);
		return data;
	}

	public async createAIScores(answerCodes: string[], language: string, force: boolean) {
		let locale = "ja-JP";
		switch (language) {
			case "ko":
				locale = "ko-KR";
				break;
			case "en":
				locale = "en-US";
				break;
			case "zh":
				locale = "zh-CN";
				break;
			default:
				locale = "ja-JP";
				break;
		}
		const url = `${this.requestPrefix}/rubrics/ai/bulk?languageCode=${locale}${force ? "&force=true" : ""}`;
		const data = await this.getRequestPromise<void>("post", url, {}, answerCodes);
		return data;
	}

	public async getAIStatus(answerCode: string) {
		const data = await this.getRequestPromise<ExternalApiStatus>("get", `${this.requestPrefix}/${answerCode}/rubrics/ai/status`);
		return data;
	}

	public async getAIScore(answerCode: string) {
		const data = await this.getRequestPromise<IAiScore[]>("get", `${this.requestPrefix}/${answerCode}/rubrics/ai`);
		return data;
	}
}
