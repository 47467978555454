/* eslint-disable react/jsx-pascal-case */
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
// import LoginHome from "./views/login/LoginHome";
import { Backdrop, CircularProgress, Theme, Typography } from "@mui/material";
import { observer } from "mobx-react";
import MessageDialog from "./views/components/MessageDialog";
import Landing from "./views/main/00_SignUp_SignIn/0_Landing/Landing";
import SignUp from "./views/main/00_SignUp_SignIn/1_SignUp/SignUp";
import StudentTaskList from "./views/main/100_StudentHome/110_MyAssignments/StudentTaskList";
import StudentTestPaper from "./views/main/100_StudentHome/120_OnlineAssignment/StudentTestPaper";
import ResultReview from "./views/main/100_StudentHome/130_ResultView/ResultReview";
import TemplateProjectList from "./views/main/10_ProjectLists/0_Lists/TemplateProjectList";
import TempProjectCreateBasicInfo from "./views/main/10_ProjectLists/1_ExamBasicInfo/TempProjectCreateBasicInfo";
import CreateQuestionsMain from "./views/main/10_ProjectLists/2_CreateQuestions/CreateQuestionsMain";
// import Rag from "./views/main/10_ProjectLists/3_RAG/Rag";
import Login from "./views/main/00_SignUp_SignIn/2_Login/Login";
import ExamAnswer from "./views/main/10_ProjectLists/4_TeachersAnswer/ExamAnswer";
import Rubric from "./views/main/10_ProjectLists/5_Rubric/Rubric";
import TemplateProjectView from "./views/main/10_ProjectLists/6_Review/TemplateProjectView";
import AssignmentHome from "./views/main/20_Assignments/0_AssignmentList/AssignmentHome";
import AssignmentProjectMain from "./views/main/20_Assignments/1_AssignmentInfo/AssignmentProjectMain";
import SubmitManagement from "./views/main/20_Assignments/2_Submissions/OfflineSubmissions/SubmitManagement";
import MarkFeedbackList from "./views/main/20_Assignments/3_ScoringAndFeedback/MarkFeedbackList";
import MarkFeedback from "./views/main/20_Assignments/3_ScoringAndFeedback/MarkFeedBackPopUp/MarkFeedback";
import ResultExportList from "./views/main/20_Assignments/4_ExportResult/ResultExportList";
import StudentResult from "./views/main/20_Assignments/4_ExportResult/StudentResultPopUp/StudentResult";
import AssignmentProjectCreateBasicInfo from "./views/main/21_AssignAssessment/1_AssignmentInfo/AssignmentProjectCreateBasicInfo";
import TempExamTargetAppointment from "./views/main/21_AssignAssessment/2_AssignedStudents/TempExamTargetAppointment";
import ExamSelected from "./views/main/21_AssignAssessment/3_SelectAPorject/ExamSelected";
import CustomProjectAssignment from "./views/main/21_AssignAssessment/4_ConfirmAndAssign/CustomProjectAssignment";
import ManageStudent from "./views/main/30_StudentsManagement/ManageStudent";
import Dashboard from "./views/main/Dashboard";
import DataViewerWrapper from "./views/main/DataViewerWrapper";
import PrivacyPolicy_ko from "./views/main/locale/ko/PrivacyPolicy_ko";
import TermsOfUse_ko from "./views/main/locale/ko/TermsOfUse_ko";
import PrintAll from "./views/main/PrintAll";
import ProjectTeacher from "./views/main/ProjectTeacher";
import SubmitTypeCheck from "./views/main/SubmitTypeCheck";
import TemplateProjectListForPublic from "./views/main/TemplateProjectListForPublic";
import UploadTest from "./views/main/UploadTest";

import PrivacyPolicy_ja from "./views/main/locale/ja/PrivacyPolicy_ja";
import TermsOfUse_ja from "./views/main/locale/ja/TermsOfUse_ja";

import React from "react";

import { useStores } from "./hooks/useStores";
import { getBrowserAndDeviceInfo } from "./neolab-libs/nl-lib3-common";
import { UserType } from "./repositories/model/support/UserType";
import { AuthState } from "./stores/support/AuthState";
import { StoreStatus } from "./stores/support/StoreStatus";
import LandingEN from "./views/main/00_SignUp_SignIn/0_Landing/LandingEN";
import LandingJP from "./views/main/00_SignUp_SignIn/0_Landing/LandingJP";
import LandingKR from "./views/main/00_SignUp_SignIn/0_Landing/LandingKR";
import DownloadPage from "./views/main/00_SignUp_SignIn/3_DownloadNativeApp/DownloadPage";
import ForgetPassword from "./views/main/00_SignUp_SignIn/9_ForgetPassword/ForgetPassword";
import ResetPassword from "./views/main/00_SignUp_SignIn/9_ForgetPassword/ResetPassword";
import { LocaleProvider, useLocale } from "./views/main/locale/LocaleContext";
// import TempProjectCreateBasicInfo_steve from "./views/main/10_ProjectLists/1_ExamBasicInfo/TempProjectCreateBasicInfo_steve.tsx.bak";
import * as Sentry from "@sentry/react";
import ProjectStudent from "./views/main/ProjectStudent";
import UnableToSignUp from "./views/main/00_SignUp_SignIn/1_SignUp/UnableToSignUp";


const LocalizedTermsOfUse = () => {
	const { locale } = useLocale();

	switch (locale) {
		case 'ko':
			return <TermsOfUse_ko />;
		case 'ja':
			return <TermsOfUse_ja />;
		case 'en':
		default:
			return <TermsOfUse_ko />;
	}
};




const LocalizedPrivacyPolicy = () => {
	const { locale } = useLocale();

	switch (locale) {
		case 'ko':
			return <PrivacyPolicy_ko />;
		case 'ja':
			return <PrivacyPolicy_ja />;
		case 'en':
		default:
			return <PrivacyPolicy_ko />;
	}
};

type Props = {
}

function App(props: Props) {
	const location = useLocation();
	const { authStore, userStore, messageStore, storeManager } = useStores();
	const { loginState, loginUser } = authStore;
	const { message, } = messageStore;
	const { storeState } = storeManager;

	const [isChromium, setIsChromium] = React.useState(true);

	React.useEffect(() => {
		const info = getBrowserAndDeviceInfo();
		setIsChromium(info.isChromium);

		authStore.checkLogin(checkLoginCallback);
	}, []);


	React.useEffect(() => {
		if (isChromium) {
			messageStore.alert("Chrome 브라우저를 사용해 주세요.", "Chrome 또는 Edge에서 실행");
		}
	}, [isChromium, messageStore]);

	React.useEffect(() => {
		messageStore.init();
	}, [location]);

	const checkLoginCallback = () => {
		console.log("checkLoginCallback", authStore.loginState);
		if (authStore.loginState === AuthState.Authenticated) {
			userStore.getUserProfile(authStore.loginUser.code);
			console.log("componentDidMount get", userStore.userProfile);
		} else {
			userStore.initUserProfile();
			console.log("componentDidMount init");
		}
	};

	let NeoRoutes = Routes;
	if (process.env.NODE_ENV === "production") {
		NeoRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
	}

	return (
		<LocaleProvider>
			<div className="App">
				{/* TODO TapBar */}
				{/* TODO SideBar */}
				{loginState === AuthState.Pending ?
					<Backdrop sx={{ color: '#eee', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
						open={true}>
						<CircularProgress color="inherit" />
					</Backdrop>
					:
					loginState === AuthState.NeedPasswordChange || loginState === AuthState.NotAuthenticated || loginState === AuthState.PassWordUpdated ? (
						<NeoRoutes>
							<Route path="/" element={<Landing />} />

							<Route path="/ja" element={<LandingJP />} />
							<Route path="/ko" element={<LandingKR />} />
							<Route path="/en" element={<LandingEN />} />

							<Route path="/signup" element={<UnableToSignUp />} />

							{/* 2025-01-06, KT 때문에 임시로 막아 놓음 */}
							<Route path="/signup_force" element={<SignUp />} />
							<Route path="/login" element={<Login />} />
							<Route path="/download" element={<DownloadPage />} />
							<Route path="/forgot-password" element={<ForgetPassword />} />
							<Route path="/reset-password" element={<ResetPassword />} />

							<Route path="/termsofuse" element={<LocalizedTermsOfUse />} />
							<Route path="/privacypolicy" element={<LocalizedPrivacyPolicy />} />
							{/*<Route path="/login" element={<LoginHome />} />*/}
							<Route path="*" element={<Login />} />
						</NeoRoutes>
					) : loginState === AuthState.Authenticated &&
					(
						<NeoRoutes>
							<Route path="/signup_force" element={<SignUp />} />
							<Route path="/download" element={<DownloadPage />} />
							<Route path="/" element={
								loginUser.type === UserType.Admin ?
								<ProjectTeacher />
								: <ProjectStudent />
								}>
								<Route path="dashboard" element={<Dashboard />} />
								{/* 대시보드 */}
								<Route path="projects">
									{/* 평가 과제 */}
									<Route path="" element={
										loginUser.type === UserType.Admin ?
											<TemplateProjectList />
											: <StudentTaskList />}
									/>

									<Route path="search" element={<TemplateProjectListForPublic />} />
									{/* 평가 과제 리스트 */}
									< Route path="create/info" element={<TempProjectCreateBasicInfo />} />
									{/* } */}

									{/* 기본정보 과제만들기 */}
									<Route path=":projectCode">
										<Route path="create">
											{/* 과제 보기 */}
											<Route path="view" element={<TemplateProjectView />} />

											{/* 과제 만들기 위 원본 아래 수정본*/}
											{/*<Route path="info" element={<TableHomeTask />} />*/}

											{/* 과제 만들기 > 과제 개요 */}
											<Route path="info" element={<TempProjectCreateBasicInfo />} />

											{/* 과제 만들기 > 평가 계획 */}
											{/*<Route path="plan" element={<ResultPlan />} />*/}

											{/* 과제 만들기 > 문항 생성 */}
											<Route path="question" element={<CreateQuestionsMain />} />

											{/* 과제 만들기 > 참고 자료 */}
											{/* <Route path="rag" element={<Rag />} /> */}

											{/* 과제 만들기 > 모범 답안 */}
											{/* <Route path="answer" element={<ExamAnswer />} /> */}

											{/* 과제 만들기 > 평가 기준표 */}
											<Route path="rubric" element={<Rubric />} />

											{/*인쇄 문항지*/}
											{/*<Route path="printAnswer" element={<PrintAll />}/>*/}

											{/*인쇄 답안지 (전체)*/}
											{/*<Route path="printAnswer" element={<PrintAnswerSheet />}/>*/}

											<Route path="printPaper" element={
												loginUser.type === UserType.Admin
													? <PrintAll />
													: <StudentTestPaper />
											} />

											{/*/!*인쇄 문항지*!/*/}
											{/*<Route path="temp" element={<ExamSelected />} />*/}
										</Route>
									</Route>
								</Route>

								{/*출재 관리*/}
								<Route path="assignments">
									<Route path="" element={<AssignmentHome />} />
									<Route path="create/info" element={<AssignmentProjectCreateBasicInfo />} /> {/* 평가 과제 운영 정보 새로 만들기 */}

									<Route path=":projectCode">
										<Route path="view" element={<AssignmentProjectMain />} /> {/* 평가과제운영 상세 */}
										<Route path="create">
											<Route path="info" element={<AssignmentProjectCreateBasicInfo />} /> {/* 평가 과제 운영 정보 */}
											<Route path="target" element={<TempExamTargetAppointment />} /> {/* 평가 대상 */}
											<Route path="paper" element={<ExamSelected />} /> {/* 평가과제 선택 */}
											<Route path="submit" element={<CustomProjectAssignment />} /> {/* 과제 제시하기 */}
										</Route>


										<Route path="submissions">
											<Route path={"test"} element={<StudentTestPaper />} />
											{/*<Route path="view" element={<AssignmentProjectMain/>}/> /!* 평가과제운영 상세 *!/*/}
											{/*<Route path="" element={<SubmitManagement/>}/>*/}
											<Route path="" element={<SubmitTypeCheck />} />
											{/*<Route path=":submissionCode/compare/detail" element={<CompareData/>}/>*/}
											<Route path=":submissionCode/detail" element={<SubmitManagement />} />
											<Route path=":submissionCode/feedbacks/detail" element={<MarkFeedback />} />
										</Route>
										<Route path="feedbacks" element={<MarkFeedbackList />} />

										<Route path="export">
											<Route path="" element={<ResultExportList />} />
											<Route path=":submissionCode/result/detail" element={loginUser.type === UserType.Admin ? <StudentResult /> : <ResultReview />} />
										</Route>
									</Route>
								</Route>

								{/*학생 관리*/}
								<Route path="students">
									<Route path="" element={<ManageStudent />} />
								</Route>

								{/*<Route path="/:submissionCode/detail" element={<ResultReview />} /> /!* [학생] 결과 리뷰하기 - [학생] 라우터 분기처리 후 옮기기*!/*/}
								<Route path="test" element={<UploadTest />} />

								<Route path="" element={loginUser.type === UserType.Admin ? <TemplateProjectList /> : <StudentTaskList />} />
							</Route>
							<Route path="/preview/:projectCode/:fileId/:pages" element={<DataViewerWrapper />} />
							<Route path="/tempPreview/:fileUrl" element={<DataViewerWrapper />} />
						</NeoRoutes>
					)}

				{messageStore.open &&
					<MessageDialog
						open={messageStore.open}
						titleText={messageStore.titleText}
						submitText={messageStore.submitText}
						cancelText={messageStore.cancelText}
						isConfirm={messageStore.isConfirm}
						onClick={messageStore.onClick}
						onClose={messageStore.onClose}
					>
						{message}
					</MessageDialog>
				}

				<Backdrop
					style={{ color: '#fff', zIndex: 999999 }}
					open={storeState === StoreStatus.PROGRESS}>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; 조회 중입니다...</Typography>
				</Backdrop>

			</div>
		</LocaleProvider >
	);
}

// export default observer<typeof App>(App));
export default observer<typeof App>(App);
