import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { RubricStatus } from "../../../../repositories/model/support/RubricStatus";
import { stores } from "../../../../stores";

// SampleQuestionGenerator.ts
export interface SampleQuestionInfo {
  examOrder: number;
  questionOrder: number;
  sampleQuestionIndex: number;

  projectExamCode: string;
  isEssay: boolean;
}

export class SampleQuestionGenerator {
  private projectRepository = stores.projectStore.projectRepository;

  // Sample Questions
  static sampleQuestions = [
    { title: "이야기 완성하기", content: "다음 문장을 읽고, 이어질 내용을 상상하여 이야기를 완성해 보세요. '어느 날, 토끼는 숲속에서 반짝이는 열쇠를 발견했습니다.'" },
    { title: "그림 묘사하기", content: "다음 그림을 보고, 그림 속 장면을 글로 표현해 보세요." },
    { title: "경험 이야기하기", content: "가장 기억에 남는 가족 여행에 대해 써보세요." },
    { title: "편지 쓰기", content: "주변 사람에게 감사의 마음을 담은 편지를 써보세요." },
  ];

  // Instance function to generate rubric
  async generateRubricIfNonExistent(sampleQuestionInfos: SampleQuestionInfo[]) {
    console.log("Generating rubric for:", sampleQuestionInfos);

    const collectedTransfers: any[] = [];

    for (const info of sampleQuestionInfos) {
      if (!info.isEssay) continue; // Skip if isEssay is false
      if (info.sampleQuestionIndex < 0 || info.sampleQuestionIndex >= SampleQuestionGenerator.sampleQuestions.length) continue;

      // Fetch the rubric list
      const projectRubricList = await this.projectRepository.getProjectRubricList(
          info.projectExamCode,
          info.questionOrder
      );

      if (!projectRubricList || projectRubricList.length === 0) {

          // Create rubric list
          const transfer = this.newRubric(info.sampleQuestionIndex, info.projectExamCode, info.questionOrder);
          collectedTransfers.push(...transfer); 
      }
    }

    const data = await this.projectRepository.createProjectRubricList(collectedTransfers);
		
    if (!data) {
			console.log( "[SampleQuestionGenerator]", "Cannot createProjectRubricList ...");
			return false;
		}

    return true;
  }

  private newRubric(sampleQuestionIdx: number, projectExamCode: string, projectExamQuestionNum: number): any[] {
    // Generate rubric items dynamically
    const rubricItems = this.newRubricItems(sampleQuestionIdx, projectExamCode, projectExamQuestionNum);

    const result = rubricItems.map(category => ({
        id: "",
        systemRubricId: "",
        projectExamCode: projectExamCode,
        projectExamQuestionNum: projectExamQuestionNum,
        name: category.title, // ✅ 'name' comes from the 'title' of newRubricItems
        subject: "",
        status: RubricStatus.ENABLE,

        // TODO: - 15?
        score: 5,
        key: "",
        questionKey: "",
        changeFlag: ChangeFlag.CREATED,
        items: category.items
    }));

    return result;
  }


  private newRubricItems(sampleQuestionIdx: number, projectExamCode: string, projectExamQuestionNum: number): { title: string; items: any[] }[] {

    const rubricCategories: { title: string; items: any[] }[] = [];

    if (sampleQuestionIdx == 0) {
      
      // 상상력
      const items1 = [
        this.newRubricItem("상", 1, 5, "흥미로운 전개를 보이고, 예상하지 못한 반전이나 독창적인 요소가 포함됨"),
        this.newRubricItem("중", 2, 3, "전개가 단순하거나 익숙한 이야기 구조를 따름"),
        this.newRubricItem("하", 3, 1, "이야기의 흐름이 비논리적이며 창의성이 부족함")
      ]

      // 문장구성
      const items2 = [
        this.newRubricItem("상", 1, 5, "문장이 간결하고 명료하며, 문장의 흐름이 자연스러움"),
        this.newRubricItem("중", 2, 3, "문장이 전반적으로 이해 가능하나 일부 문장이 어색하거나 반복적임"),
        this.newRubricItem("하", 3, 1, "문장이 전반적으로 이해가 어렵고, 문장의 흐름이 부자연스러움")
      ]

      // 문장표현력
      const items3 = [
        this.newRubricItem("상", 1, 5, "문장이 적절한 길이로 구성되며, 맞춤법과 문법이 거의 정확하고 다양한 표현이 사용됨"),
        this.newRubricItem("중", 2, 3, "문장이 적절한 길이로 구성되지만, 맞춤법과 문법의 오류가 간혹 나타남. 혹은 표현이 별로 다양하지 않음"),
        this.newRubricItem("하", 3, 1, "문장이 매우 짧고 단조롭게 나열했거나 혹은 문법적 오류가 많아 문장을 이해하기 어려움")
      ]
      
      rubricCategories.push({ title: "상상력", items: items1 });
      rubricCategories.push({ title: "문장 구성", items: items2 });
      rubricCategories.push({ title: "문장 표현력", items: items3 });
      
    } else if (sampleQuestionIdx == 1) {

      // 관찰력
      const items1 = [
        this.newRubricItem("상", 1, 5, "그림 속 인물, 사물, 배경을 세심하게 관찰하고, 중요한 특징을 빠짐없이 표현함. 작은 디테일까지 언급하여 생동감 있게 묘사함."),
        this.newRubricItem("중", 2, 3, "그림 속 주요 요소를 묘사하지만, 세부적인 특징이나 작은 디테일이 부족함. 중요한 내용을 놓칠 수도 있음."),
        this.newRubricItem("하", 3, 1, "그림의 요소를 정확히 설명하지 않거나, 일부만 언급하여 내용이 부실함. 핵심적인 부분을 놓치거나 불명확하게 표현함.")
      ]

      // 표현력
      const items2 = [
        this.newRubricItem("상", 1, 5, "적절한 형용사와 다양한 표현을 사용하여 그림 속 장면을 생생하게 묘사함. 문장이 자연스럽고 읽기 쉬움."),
        this.newRubricItem("중", 2, 3, "간단한 단어와 표현을 사용하여 그림을 묘사하지만, 어휘가 반복되거나 문장이 다소 단순함."),
        this.newRubricItem("하", 3, 1, "어휘가 매우 제한적이며, 단순한 나열식 문장으로 표현하여 읽는 사람이 그림을 쉽게 떠올리기 어려움.")
      ]

      // 문장구성
      const items3 = [
        this.newRubricItem("상", 1, 5, "문장이 논리적으로 연결되며, 전체적인 흐름이 매끄럽고 자연스러움. 문장의 길이도 적절하며, 설명이 체계적으로 구성됨."),
        this.newRubricItem("중", 2, 3, "문장이 대부분 이해 가능하지만, 일부 연결이 부자연스럽거나 문장 구조가 어색한 부분이 있음."),
        this.newRubricItem("하", 3, 1, "문장이 지나치게 짧거나 단조롭고, 문법적 오류가 많아 내용을 이해하기 어려움. 설명이 불완전하거나 연결이 끊어짐.")
      ]

      rubricCategories.push({ title: "관찰력", items: items1 });
      rubricCategories.push({ title: "표현력", items: items2 });
      rubricCategories.push({ title: "문장구성", items: items3 });

    } else if (sampleQuestionIdx == 2) {
      // 경험 공유
      const items1 = [
        this.newRubricItem("상", 1, 5, "자신의 경험을 생생하게 서술하며, 구체적인 사건과 감정을 풍부하게 표현함. 독자가 상황을 쉽게 상상할 수 있도록 자세히 설명함."),
        this.newRubricItem("중", 2, 3, "자신의 경험을 서술하지만, 일부 내용이 부족하거나 세부적인 설명이 부족하여 상황이 명확하게 전달되지 않을 수 있음."),
        this.newRubricItem("하", 3, 1, "경험을 간단히 서술하거나, 핵심적인 내용이 빠져 있어 글이 부실함. 감정 표현이 거의 없거나, 내용이 두서없이 흩어짐.")
      ]

      // 감정 표현
      const items2 = [
        this.newRubricItem("상", 1, 5, "자신의 감정을 솔직하고 다양하게 표현하며, 이야기와 잘 연결됨. 감정의 변화도 자연스럽게 나타남."),
        this.newRubricItem("중", 2, 3, "감정을 표현하나, 반복적이거나 단순하게 서술됨. 감정 변화가 명확하지 않거나 다소 어색할 수 있음."),
        this.newRubricItem("하", 3, 1, "감정 표현이 거의 없거나, 단순한 문장으로 감정을 설명함. 이야기와 감정이 잘 연결되지 않음.")
      ]

      // 시간 순서
      const items3 = [
        this.newRubricItem("상", 1, 5, "이야기가 시간의 흐름에 따라 자연스럽게 전개되며, 시작, 중간, 결말이 명확하게 구분됨."),
        this.newRubricItem("중", 2, 3, "이야기의 흐름이 대체로 이해되지만, 일부 시간 순서가 어색하거나 연결이 부자연스러움."),
        this.newRubricItem("하", 3, 1, "시간 순서가 맞지 않거나, 사건이 무작위로 나열되어 있어 흐름을 이해하기 어려움. 시작과 끝이 명확하지 않음.")
      ]

      rubricCategories.push({ title: "경험 공유", items: items1 });
      rubricCategories.push({ title: "감정 표현", items: items2 });
      rubricCategories.push({ title: "시간 순서", items: items3 });

    } else if (sampleQuestionIdx == 3) {
      // 진솔함
      const items1 = [
        this.newRubricItem("상", 1, 5, "상대방에게 전하고 싶은 마음이 진심으로 담겨 있으며, 따뜻하고 자연스러운 표현이 사용됨. 상대방을 배려하는 마음이 드러남."),
        this.newRubricItem("중", 2, 3, "상대방에게 전하고 싶은 내용이 있지만, 감정 표현이 다소 부족하거나 형식적인 느낌이 있음."),
        this.newRubricItem("하", 3, 1, "내용이 너무 짧거나 형식적이며, 상대방을 향한 감정이 거의 드러나지 않음.")
      ]

      // 구체성
      const items2 = [
        this.newRubricItem("상", 1, 5, "경험을 구체적으로 서술하여 편지의 내용이 풍부하고 감동적임."),
        this.newRubricItem("중", 2, 3, "경험을 서술하지만, 내용이 다소 단순하거나 반복될 수 있음."),
        this.newRubricItem("하", 3, 1, "경험이 명확하지 않거나, 단순한 인사말만 나열되어 있음. 내용이 부실하여 편지의 목적이 명확하지 않음.")
      ]

      // 형식 준수
      const items3 = [
        this.newRubricItem("상", 1, 5, "편지의 기본 형식(인사말, 본문, 마무리 인사 등)을 올바르게 사용하며, 문장이 자연스럽고 문법적으로 정확함."),
        this.newRubricItem("중", 2, 3, "편지의 기본 형식을 따르지만, 일부 생략되거나 문장이 어색한 부분이 있음."),
        this.newRubricItem("하", 3, 1, "편지 형식을 제대로 따르지 않거나, 인사말과 마무리가 부족함. 문장이 부자연스럽거나 문법적 오류가 많음.")
      ]

      rubricCategories.push({ title: "진솔함", items: items1 });
      rubricCategories.push({ title: "구체성", items: items2 });
      rubricCategories.push({ title: "형식 준수", items: items3 });
    }

    return rubricCategories
  }


  private newRubricItem(name: string, itemNum: number, score: number, description: string): any {
    const EmptyProjectRubricItemTransfer = {
      rubricId: "",
      rubricItemNum: itemNum,
      name: name,
      score: score,
      standard: description,
      changeFlag: ChangeFlag.CREATED
    };
    return EmptyProjectRubricItemTransfer;
  }
}

/*
const EmptyProjectRubricItemTransfer = {
	rubricId: "",
	rubricItemNum: "",
	name: "",
	score: 0,
	standard: "",
	changeFlag: ChangeFlag.CREATED
};

const EmptyProjectRubricTransfer = {
	id: "",
	systemRubricId: "", // data 받아오는 용으로만
	projectExamCode: "",
	projectExamQuestionNum: 0,
	name: "",
	subject: "",
	status: RubricStatus.ENABLE,

	// systemRubricName: "",
	score: 0,
	key: "",
	questionKey: "",

	changeFlag: ChangeFlag.CREATED,
	// items: Array(3).fill({ ...EmptyProjectRubricItemTransfer })
	items: [
		{ ...EmptyProjectRubricItemTransfer, rubricItemNum: 1, name: '상' },
		{ ...EmptyProjectRubricItemTransfer, rubricItemNum: 2, name: '중' },
		{ ...EmptyProjectRubricItemTransfer, rubricItemNum: 3, name: '하' }
	]
};
*/