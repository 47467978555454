import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputBase,
	LinearProgress,
	MenuItem,
	MenuList,
	Paper,
	Popover,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import useEmotionStyles from "../styles/useEmotionStyles";
import { ManageStudentStlye } from "../styles/ManageStudentStlye";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import clsx from "clsx";
import { ReactComponent as DotsMoreMenu } from '../../../common/images/DotsMoreMenu.svg';
import { ReactComponent as SearchNameIcon } from '../../../common/images/SearchNameIcon.svg';
import { ReactComponent as SelectIcon } from '../../../common/images/SelectIcon.svg';
import { ReactComponent as TableListOrder } from '../../../common/images/TableListOrder.svg';
// import { ReactComponent as CopyProfile } from '../../common/images/CopyProfile.svg';
import { ReactComponent as EditProfile } from '../../../common/images/EditProfile.svg';
// import { ReactComponent as MoveGroupIcon } from '../../common/images/MoveGroupIcon.svg';
// import { ReactComponent as AccountStop } from '../../common/images/AccountStop.svg';
// import { ReactComponent as CheckLoginOff } from '../../common/images/CheckLoginOff.svg';
// import { ReactComponent as CheckLogin } from '../../common/images/CheckLogin.svg';
import { ReactComponent as GroupSetIconN } from '../../../common/images/GroupSetIconN.svg';
import { ReactComponent as SaveAllIconN } from '../../../common/images/SaveAllIconN.svg';
import { ReactComponent as StudentEmptyTable } from '../../../common/images/StudentEmptyTable.svg';
import { ReactComponent as UserProfileIconN } from '../../../common/images/UserProfileIconN.svg';

import { observer } from "mobx-react";

import { CircularProgress } from "@mui/material";
// import 'dayjs/locale/ko';
import CreateBatchSTDialog from "../../../components/CreateBatchSTDialog";
import CreateStudentDialog from "../../../components/CreateStudentDialog";
import GroupAppointDialog from "../../../components/GroupAppointDialog";
import GroupDialog from "../../../components/GroupDialog";
import ModifyStudentDialog from "../../../components/ModifyStudentDialog";
import { convertUTCToKST } from "../../../hooks/convertUTCToKST";
import MessageDialog from "../../components/MessageDialog";
import { HtmlTooltip } from "../../components/Tooltip";

import React from "react";
import { useTranslation } from 'react-i18next';
import { useStores } from "../../../hooks/useStores";
import { useNeoConfirm } from "../../../hooks/useNeoConfirm";


type Props = {
}

function ManageStudent(props: Props) {

	const i18next = useTranslation();
	const classes = useEmotionStyles(ManageStudentStlye);

	// const theme = useTheme();
	const [deadLineSortRotated, setDeadLineSortRotated] = React.useState(false);
	const [selectedGroup, setSelectedGroup] = React.useState("DEFAULT");
	const [searchKeyword, setSearchKeyword] = React.useState("");
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(30); // 사실상 바뀌지 않음
	const [sorting, setSorting] = React.useState([{ column: "up.name", hint: "DESC" }]);

	const [createStudentDialogOpen, setCreateStudentDialogOpen] = React.useState(false);
	const [createBatchDialogOpen, setCreateBatchDialogOpen] = React.useState(false);
	const [groupConfigDialog, setGroupConfigDialog] = React.useState(false);
	const [groupAppointDialog, setGroupAppointDialog] = React.useState(false);
	const [modifyStudentDialog, setModifyStudentDialog] = React.useState(false);

	const {alert, confirm} = useNeoConfirm();
	const [deleteStudentPopperOpen, setDeleteStudentPopperOpen] = React.useState(false);

	const [selectedUser, setSelectedUser] = React.useState(null);

	const { userStore, navigateStore } = useStores();

	const {
		myGroups, myUsers, myUserCount, myUserLoading, saveComplete,
		updateGroupFinishDialog, updateGroupState, appointGroupFinishDialog
	} = userStore;

	React.useEffect(() => {
		// 1. select users and groups
		userStore.getMyUsersForManage(convertGroupForRequest(selectedGroup), searchKeyword, page - 1, rowsPerPage, sorting);

		return () => {
		}
	}, []);

	const handleDeadLineSortClick = () => {
		setDeadLineSortRotated(!deadLineSortRotated);
	};

	const [registrationAnchorEl, setRegistrationAnchorEl] = React.useState(null);

	const handleClickRegistration = (event, user) => {
		setRegistrationAnchorEl(event.currentTarget);
		setSelectedUser(user);
	};

	const handleClosePopover = () => {
		setRegistrationAnchorEl(null);
	};

	const handleSelectState = (e) => {
		// console.log(e);
		setSelectedGroup(e.target.value);
		let group = null;
		if (e.target.value !== "DEFAULT") {
			group = e.target.value;
		}
		userStore.getMyUsersForManage(group, searchKeyword, page - 1, rowsPerPage, sorting);
	}

	const registrationOpen = Boolean(registrationAnchorEl);

	const handleClickCreateStudentDialogOpen = () => {
		setCreateStudentDialogOpen(true);
	};
	const handleClickCreateStudentDialogClose = () => {
		setCreateStudentDialogOpen(false);
	};

	const handleClickCreateBatchDialogOpen = () => {
		setCreateBatchDialogOpen(true);
	};
	const handleClickCreateBatchDialogClose = () => {
		setCreateBatchDialogOpen(false);
	};

	const groupConfigDialogOpen = () => {
		setGroupConfigDialog(true);
	};

	const groupConfigDialogClose = () => {
		userStore.updateGroupFinishDialogClose();
		setGroupConfigDialog(false);
	};

	// const groupAppointDialogOpen = () => {
	// 	setRegistrationAnchorEl(null);
	// 	setGroupAppointDialog(true);
	// };

	const groupAppointDialogClose = () => {
		userStore.appointGroupFinishDialogClose();
		setGroupAppointDialog(false);

		userStore.getMyUsersForManage(convertGroupForRequest(selectedGroup), searchKeyword, page - 1, rowsPerPage, sorting);
	};

	const modifyStudentDialogOpen = () => {
		setRegistrationAnchorEl(null);
		setModifyStudentDialog(true);
	}

	const modifyStudentDialogClose = () => {
		setModifyStudentDialog(false);
	}

	const deleteStudentClicked = async () => {
		const accepted = await confirm(i18next.t("사용자를 삭제하시겠습니까?"));
		if (!accepted) {
			return;
		}

		handleClosePopover();
		userStore.deleteMyUsers([selectedUser.userCode]);

	}

	// const covertNumToState = (num) => {
	// 	switch (num) {
	// 		case 0:
	// 			return "";
	// 		case 1:
	// 			return HomeworkStateType.CREATED;
	// 		case 2:
	// 			return HomeworkStateType.POSED;
	// 		case 3:
	// 			return HomeworkStateType.SUBMITTED;
	// 		case 4:
	// 			return HomeworkStateType.SCORED;
	// 	}
	// }

	const handleClickPage = (e, value) => {
		setPage(value);
		// if(selectedGroup === "DEFAULT")
		userStore.getMyUsersForManage(convertGroupForRequest(selectedGroup), searchKeyword, value - 1, rowsPerPage, sorting);
	}

	const convertGroupForRequest = (groupCode) => {
		if (groupCode === "DEFAULT") {
			return null;
		} else {
			return selectedGroup;
		}
	}

	const handleChangeSearchKeyword = (e) => {
		// console.log(navigateStore.projectSearchKeyword);
		// navigateStore.changeProjectSearchKeyword(e.target.value);
		setSearchKeyword(e.target.value);
	}

	const handleClickKeywordSearch = () => {
		setPage(1);
		userStore.getMyUsersForManage(convertGroupForRequest(selectedGroup), searchKeyword, 0, rowsPerPage, sorting);
	}

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			handleClickKeywordSearch();
		}
	}

	const convertGradeInfoForView = (user) => {
		const grade = user.grade;
		const className = user.className;
		const number = user.number;

		if (number) {
			return i18next.t("{{grade}}학년 {{className}}반 {{number}}번", { grade, className, number });
		} else {
			return i18next.t("{{grade}}학년 {{className}}반", { grade, className });
		}
	}

	const handleClickSaveCompleteDialogClose = () => {
		userStore.saveCompleteClose();
		handleClickCreateStudentDialogClose();
		handleClickCreateBatchDialogClose();
		modifyStudentDialogClose();

		userStore.getMyUsersForManage(convertGroupForRequest(selectedGroup), searchKeyword, page - 1, rowsPerPage, sorting);
	}

	return (
		<div className={classes.root}>

			{/* ******콘텐츠 영역******* */}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)'
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box sx={{ marginTop: "12px" }}>
						<Typography className={classes.commonSubTitle}>{i18next.t("학생관리")} (<span>{myUserCount}</span>)</Typography>
					</Box>
					<Box display='flex' alignItems='center' justifyContent='space-between'>
						<Box display='flex' alignItems='center' justifyContent='space-between'>
							<Typography className={classes.selectText}>{i18next.t("그룹 선택")}</Typography>
							<Box>
								<FormControl className={classes.formControl}>
									<Select
										IconComponent={(props) => (
											<Box>
												<SelectIcon  {...props} />
											</Box>
										)}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "right"
											},
											transformOrigin: {
												vertical: 'top',
												horizontal: 'right',
											},
											// getContentAnchorEl: null,
										}}
										value={selectedGroup}
										onChange={(e) => handleSelectState(e)}
									>
										<MenuItem value={"DEFAULT"}>{i18next.t("전체")}</MenuItem>
										{myGroups && myGroups.map((group, index) => (
											<MenuItem id={`group-select-menuItem-${index}`} key={index} value={group.code}>{group.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							{/*<FormControlLabel*/}
							{/*    control={*/}
							{/*        <Checkbox*/}
							{/*            icon={<CheckLoginOff/>}*/}
							{/*            // checked={basicInfoComplete}*/}
							{/*            checkedIcon={<CheckLogin className={classes.checkedColor} />}*/}
							{/*            disableRipple*/}
							{/*        />*/}
							{/*    }*/}
							{/*    label="계정 일시중지 학생만"*/}
							{/*    className={classes.rememberId}*/}
							{/*/>*/}
							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
								className={classes.urlInputBox}
							>
								<IconButton className={classes.iconBtnReset} onClick={handleClickKeywordSearch} disableRipple>
									<SearchNameIcon />
								</IconButton>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder={i18next.t("학생이름 검색")}
									value={searchKeyword}
									onChange={handleChangeSearchKeyword}
									onKeyDown={handleKeyDown}
									inputProps={{ 'aria-label': '학생이름 검색' }}
								/>
							</Paper>
						</Box>
						<Box style={{ display: 'flex', flexDirection: 'row', }}>
							<Button className={clsx(classes.lineBtn, classes.iconAdd)}
								onClick={groupConfigDialogOpen}
								disableRipple>
								<GroupSetIconN />
								<Typography>{i18next.t("그룹 설정")}</Typography>
							</Button>
							<Button className={clsx(classes.greenBtn, classes.iconAdd)} disableRipple onClick={handleClickCreateStudentDialogOpen}>
								<UserProfileIconN />
								<Typography>{i18next.t("개별 등록")}</Typography>
							</Button>
							{(createStudentDialogOpen) &&
								<CreateStudentDialog
									open={createStudentDialogOpen}
									onClose={handleClickCreateStudentDialogClose}
								/>
							}
							<Button className={clsx(classes.greenBtnN, classes.iconAdd)} disableRipple onClick={handleClickCreateBatchDialogOpen}>
								<SaveAllIconN />
								<Typography>{i18next.t("일괄 등록")}</Typography>
							</Button>
							{createBatchDialogOpen &&
								<CreateBatchSTDialog
									open={createBatchDialogOpen}
									onClose={handleClickCreateBatchDialogClose}
								/>}
						</Box>
					</Box>

					<Box>
						{myUsers && myUsers.length > 0 ?
							<TableContainer component={Paper} className={classes.tableBox}>
								<Table aria-label="myStudent list">
									<TableHead>
										<TableRow>
											<TableCell width='4.5%' className={classes.tableNoBox}>No.</TableCell>
											<TableCell width='8%' >{i18next.t("이름")}</TableCell>
											{(!navigateStore.simpleMode) &&
												<TableCell width='12%'>
													<Box className={classes.sortButton} style={{ cursor: 'default' }}>
														<Typography style={{ minWidth: 62 }}>{i18next.t("학년반/번호")}</Typography>
														<IconButton onClick={handleDeadLineSortClick} className={classes.iconBtnReset} style={{ transform: deadLineSortRotated ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>
															<TableListOrder />
														</IconButton>
													</Box>
												</TableCell>
											}

											<TableCell width='20%'>{i18next.t("이메일")}</TableCell>
											<TableCell width='13%'>
												<Box className={classes.sortButton} style={{ cursor: 'default' }}>
													<Typography>{i18next.t("학교")}</Typography>
													<IconButton onClick={handleDeadLineSortClick} className={classes.iconBtnReset} style={{ transform: deadLineSortRotated ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} disableRipple>
														<TableListOrder />
													</IconButton>
												</Box>
											</TableCell>

											<TableCell width='8%'>
												{i18next.t("그룹")}
											</TableCell>
											<TableCell width='18%'>
												{i18next.t("오프라인 제출율")}
											</TableCell>
											<TableCell width='18%'>{i18next.t("온라인 제출율")}</TableCell>
											<TableCell width='10%'>{i18next.t("등록일")}</TableCell>
											<TableCell width='4%' align="center"></TableCell>
										</TableRow>
									</TableHead>

									{myUserLoading ?
										<TableBody style={{ height: rowsPerPage * 50, width: '100%' }}>
											<TableRow>
												<TableCell colSpan={10}>
													<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<CircularProgress size={50} />
													</Box>

												</TableCell>
											</TableRow>
										</TableBody>
										:
										<TableBody>
											{myUsers.map((user, index) => (
												<TableRow id={`myUser-list-${index}`}
													key={index}
													sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>
													<TableCell className={classes.tableNoBox}>
														{(index + 1) + ((page - 1) * rowsPerPage)}
													</TableCell>
													<TableCell>
														<Button className={classes.tableStudentNameBtn} disableRipple>
															<Typography>{user.name}</Typography>
														</Button>
													</TableCell>
													{(!navigateStore.simpleMode) &&
														<TableCell>
															{convertGradeInfoForView(user)}
														</TableCell>
													}
													<TableCell>
														{user.email}
													</TableCell>
													<TableCell>
														{user.school}
													</TableCell>
													<TableCell>
														{user.groupName}
													</TableCell>
													<TableCell>
														<Box display='flex' alignItems='center'>
															<Box className={classes.numberBox}>
																<Typography><span>{user.offSubmitCount}</span>/{user.projectTotalCount}</Typography>
															</Box>
															<Box className={classes.progressBox}>
																<LinearProgress variant="determinate" value={user.projectTotalCount === 0 ? 0 : (user.offSubmitCount / user.projectTotalCount) * 100} />
															</Box>
															<Typography className={classes.progressText}>{user.projectTotalCount === 0 ? 0 : ((user.offSubmitCount / user.projectTotalCount) * 100).toFixed(0)}%</Typography>
														</Box>
													</TableCell>
													<TableCell align="center">
														<Box display='flex' alignItems='center'>
															<Box className={classes.numberBox}>
																<Typography><span>{user.onSubmitCount}</span>/{user.projectTotalCount}</Typography>
															</Box>
															<Box className={classes.progressBox}>
																<LinearProgress variant="determinate" value={user.projectTotalCount === 0 ? 0 : (user.onSubmitCount / user.projectTotalCount) * 100} />
															</Box>
															<Typography className={classes.progressText}>{user.projectTotalCount === 0 ? 0 : ((user.onSubmitCount / user.projectTotalCount) * 100).toFixed(0)}%</Typography>
														</Box>
													</TableCell>
													<TableCell>
														<HtmlTooltip title={
															user.createdDatetime
																? convertUTCToKST(user.createdDatetime).format("YYYY-MM-DD")
																: convertUTCToKST().format("YYYY-MM-DD")}>
															<div>
																{user.createdDatetime
																	? convertUTCToKST(user.createdDatetime).format("YYYY-MM-DD")
																	: convertUTCToKST().format("YYYY-MM-DD")}
															</div>
														</HtmlTooltip>
													</TableCell>
													<TableCell>
														<IconButton className={classes.iconBtnReset} disableRipple
															style={{ border: 'solid lightgray 0.7px' }}
															onClick={(e) => handleClickRegistration(e, user)}>
															<DotsMoreMenu />
														</IconButton>
														<Popover
															id={`popover-dot-${index}`}
															open={registrationOpen}
															anchorEl={registrationAnchorEl}
															onClose={handleClosePopover}
															// anchorOrigin={{
															//     vertical: 'bottom',
															//     horizontal: 'right',
															// }}
															// transformOrigin={{
															//     // vertical: 'top',
															//     horizontal: 'right',
															// }}

															className={classes.popoverBox}
														>
															<Box>
																{/*<MenuList>*/}
																{/*    <MenuItem disableRipple>*/}
																{/*        <CopyProfile/><Typography>계정 복사</Typography>*/}
																{/*    </MenuItem>*/}
																{/*</MenuList>*/}
																<MenuList>
																	<MenuItem disableRipple onClick={modifyStudentDialogOpen}>
																		<EditProfile /><Typography>{i18next.t("정보 편집")}</Typography>
																	</MenuItem>
																	<MenuItem disableRipple onClick={deleteStudentClicked}>
																		<EditProfile /><Typography>{i18next.t("삭제")}</Typography>
																	</MenuItem>
																</MenuList>
																{/*<MenuList>*/}
																{/*    <MenuItem disableRipple onClick={groupAppointDialogOpen}>*/}
																{/*        <MoveGroupIcon/><Typography>그룹 지정</Typography>*/}
																{/*    </MenuItem>*/}
																{/*</MenuList>*/}
																{/*<MenuList>*/}
																{/*    <MenuItem disableRipple>*/}
																{/*        <AccountStop/><Typography*/}
																{/*        className={classes.deleteText}>계정 일시 중지</Typography>*/}
																{/*    </MenuItem>*/}
																{/*</MenuList>*/}
															</Box>
														</Popover>
													</TableCell>
												</TableRow>
											))}
											{myUsers.length < rowsPerPage &&
												Array.from({ length: rowsPerPage - myUsers.length }, (_, index) => (
													<TableRow id={`myUser-list-empty-${index}`}
														key={index}>
														<TableCell
															colSpan={10}
															style={{ border: 0 }}>
															&nbsp;
														</TableCell>
													</TableRow>
												))
											}
										</TableBody>}
								</Table>
							</TableContainer>
							:
							!myUserLoading &&
							<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ height: 'calc(100vh - 460px)', borderBottom: '1px solid #cbcbcb' }}>
								<StudentEmptyTable />
								<Typography className={classes.fileUploadTitle}>{i18next.t("등록된 학생이 없습니다.")}</Typography>
								<Typography className={classes.fileUploadText}>{i18next.t("'학생 등록'버튼을 눌러 추가해 주세요.")}</Typography>
							</Box>
						}

					</Box>
					<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
						<Stack spacing={2}>
							<Pagination
								count={myUserCount && myUserCount > 0 ?
									myUserCount > rowsPerPage ? Math.ceil(myUserCount / rowsPerPage) : 1 : 0}
								// count={4}
								page={page}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				</Box>
			</Box>
			{groupConfigDialog &&
				<GroupDialog open={groupConfigDialog}
					onClose={groupConfigDialogClose} />}
			{groupAppointDialog &&
				<GroupAppointDialog open={groupAppointDialog}
					selectedUser={selectedUser}
					onClose={groupAppointDialogClose} />}

			{modifyStudentDialog &&
				<ModifyStudentDialog open={modifyStudentDialog}
					selectedUser={selectedUser}
					onClose={modifyStudentDialogClose} />}
			{saveComplete &&
				<MessageDialog
					titleText={i18next.t("저장 완료")}
					onClose={handleClickSaveCompleteDialogClose}
					children={i18next.t("저장이 완료 되었습니다.")}
					onClick={handleClickSaveCompleteDialogClose}
					open={saveComplete} />}

			{updateGroupFinishDialog &&
				<MessageDialog
					titleText={i18next.t("그룹 설정 결과")}
					onClose={groupConfigDialogClose}
					children={updateGroupState ? i18next.t("설정이 완료 되었습니다.") : i18next.t("설정에 실패 하였습니다. 다시 시도해 주세요.")}
					onClick={groupConfigDialogClose}
					open={updateGroupFinishDialog} />}

			{appointGroupFinishDialog &&
				<MessageDialog
					titleText={i18next.t("그룹 지정 결과")}
					onClose={groupAppointDialogClose}
					children={updateGroupState ? i18next.t("지정이 완료 되었습니다.") : i18next.t("지정에 실패 하였습니다. 다시 시도해 주세요.")}
					onClick={groupAppointDialogClose}
					open={appointGroupFinishDialog} />}
		</div>
	);
}

export default observer<typeof ManageStudent>(ManageStudent);
