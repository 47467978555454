import { makeAutoObservable } from "mobx";
import { SampleQuestionInfo } from "../SampleQuestionGenerator";

class QuestionSampleStore {
  private static instance: QuestionSampleStore;
  private selectedSamples: SampleQuestionInfo[] = []; // ✅ Use an array instead of a Map

  private constructor() {
    makeAutoObservable(this);
  }

  public static getInstance(): QuestionSampleStore {
    if (!QuestionSampleStore.instance) {
      QuestionSampleStore.instance = new QuestionSampleStore();
    }
    return QuestionSampleStore.instance;
  }

  // ✅ Add or update a sample in the array
  setSelectedSample(sample: SampleQuestionInfo) {
    // Check if a sample already exists with the same examOrder and questionOrder
    const index = this.selectedSamples.findIndex(
      (s) => s.examOrder === sample.examOrder && s.questionOrder === sample.questionOrder
    );

    if (index !== -1) {
      // ✅ Update existing sample
      this.selectedSamples[index] = sample;
    } else {
      // ✅ Add new sample
      this.selectedSamples.push(sample);
    }
  }

  // ✅ Get a selected sample
  getSelectedSample(examOrder: number, questionOrder: number): SampleQuestionInfo | undefined {
    return this.selectedSamples.find(
      (sample) => sample.examOrder === examOrder && sample.questionOrder === questionOrder
    );
  }

  // ✅ Get all selected samples
  getAllSelectedSamples() {
    return this.selectedSamples;
  }

  // ✅ Delete all entries with the given `examOrder` and adjust others accordingly
  adjustAfterDeleteExam(examOrder: number) {
    // ✅ Remove all questions belonging to the deleted exam
    this.selectedSamples = this.selectedSamples.filter((sample) => sample.examOrder !== examOrder);

    // ✅ Adjust `examOrder` for remaining exams
    this.selectedSamples = this.selectedSamples.map((sample) => {
        if (sample.examOrder > examOrder) {
            return { ...sample, examOrder: sample.examOrder - 1 }; // ✅ Move down only for higher exams
        }
        return sample;
      });
  }


  adjustAfterDeleteQuestion(examOrder: number, questionOrder: number) {
    // ✅ Remove the specific question from the list
    this.selectedSamples = this.selectedSamples.filter(
        (sample) => !(sample.examOrder === examOrder && sample.questionOrder === questionOrder)
    );

    // ✅ Adjust questionOrder for remaining questions in the same exam
    this.selectedSamples = this.selectedSamples.map((sample) => {
        if (sample.examOrder === examOrder && sample.questionOrder > questionOrder) {
            return { ...sample, questionOrder: sample.questionOrder - 1 }; // Move down within the same exam
        }
        return sample;
    });
}

}


export const questionSampleStore = QuestionSampleStore.getInstance(); 