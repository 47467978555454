import { Box, Button, LinearProgress, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import "@mui/x-date-pickers/AdapterDayjs";
import clsx from "clsx";
import "dayjs/locale/ko";
import React from "react";
import { useTranslation } from 'react-i18next';
import { getSubjectName } from "../../../../common/SchoolLevelSubjects";
import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";
import { IProject } from "../../../../repositories/model/IProject";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import { ProjectSchoolType } from "../../../../stores/enums/ProjectStoreEnums";
import { ProjectSchoolLabelType } from "../../../../stores/ProjectStore";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TableHomeStlye } from "../../styles/TableHomeStlye";
import { getSemesterTypeString } from "../../000_Common/001_Functions/getSemesterTypeString";

type Props = {
	project: IProject;
	rowIndex: number;
	rowsPerPage: number;
	page: number;
}

export function AssignmentRow(props: Props) {
	const { navigateStore, projectStore } = useStores();
	const i18next = useTranslation();
	const classes = useEmotionStyles(TableHomeStlye);

	const { project, rowIndex, rowsPerPage } = props;
	// const theme = useTheme();
	// const [open, setOpen] = React.useState(false);
	// const [showIcon, setShowIcon] = React.useState(true);
	// const [isTestIcon, setIsTestIcon] = React.useState(true);
	const [page, setPage] = React.useState(1);

	const [templateProject, setTemplateProject] = React.useState<IProject | null>(null);

	const naviCustom = useNaviCustom();

	React.useEffect(() => {
		let timer = 0;
		if (projectStore && project?.templateCode) {
			const templateProject = projectStore.findTemplateProjectListFromLoaded(project.templateCode);

			if (templateProject) {
				setTemplateProject(templateProject);
			}
			else {
				timer = window.setTimeout(() => {
					timer = 0;
					projectStore.fetchProject(project.templateCode).then((result) => {
						setTemplateProject(result);
					});
				}, 500);
			}
		}

		return () => {
			if (timer > 0) {
				clearTimeout(timer);
			}
		}
	}, [project, projectStore, projectStore.templateProjectList]);


	const projectStateButton = (projectCode: string, state: ProjectStateType) => {
		switch (state) {
			case ProjectStateType.CREATED:
				return (
					<Button
						className={classes.stepOpen}
						onClick={(e) => handleClickWriteExam(projectCode)}
						disableRipple
					>
						<Typography>{i18next.t('제시하기')}</Typography>
					</Button>
				);
			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
				return (
					<Button
						className={classes.stepOpen}
						onClick={(e) => handleClickStartExam(projectCode)}
						disableRipple
					>
						<Typography>{i18next.t('제출물 관리')}</Typography>
					</Button>
				);
			case ProjectStateType.COMPARED:
				return (
					<Button
						className={classes.stepOpen}
						onClick={(e) => handleClickScoredExam(projectCode)}
						disableRipple
					>
						<Typography>{i18next.t('채점하기')}</Typography>
					</Button>
				);
			case ProjectStateType.SCORED:
				return (
					<Button
						className={clsx(classes.stepOpen, classes.taskResult)}
						onClick={(e) => handleClickOpenScore(projectCode)}
						disableRipple
					>
						<Typography>{i18next.t('결과 내보내기')}</Typography>
					</Button>
				);
			case ProjectStateType.EXPORTED:
				return (
					<Button
						className={clsx(classes.stepOpen, classes.taskResult)}
						onClick={(e) => handleClickOpenScore(projectCode)}
						disableRipple
					>
						<Typography>{i18next.t('결과 내보내기')}</Typography>
					</Button>
				);
		}
	};

	const checkPosed = (type) => {

		switch (type) {
			case ProjectStateType.CREATED: return false;
			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
			case ProjectStateType.OFFLINE_UPLOADED:
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
			case ProjectStateType.REVIEWED: return true;
			default: return false;
		}
	};

	const handleClickWriteExam = (projectCode) => {
		naviCustom.go(`/assignments/${projectCode}/create/info`);
	};

	const handleClickStartExam = (projectCode) => {
		naviCustom.go(`/assignments/${projectCode}/submissions`);
	};

	const handleClickScoredExam = (projectCode) => {
		naviCustom.go(`/assignments/${projectCode}/feedbacks`);
	};

	const handleClickOpenScore = (projectCode) => {
		naviCustom.go(`/assignments/${projectCode}/export`);
	};

	const handleClickProjectName = (projectCode, state) => {
		switch (state) {
			case ProjectStateType.CREATED:
				handleClickWriteExam(projectCode);
				break;
			default:
				naviCustom.go(`/assignments/${projectCode}/view`);
				break;
		}
	};

	const handleClickTemplateProjectName = (templateProjectCode: string) => {
		naviCustom.go(`/assignments/${templateProjectCode}/view`);
	};

	return (
		<TableRow id={project.code}
			key={project.code}
			sx={{ "&:last-child .MuiTableCell-body": { border: "0px !important" } }}>

			{/* No */}
			<TableCell className={classes.tableNoBox}>
				{(page - 1) * rowsPerPage + rowIndex + 1}
			</TableCell>

			{/* 평가과제운영명 */}
			<TableCell>
				<Button
					className={classes.assignmenttNameBtn}
					onClick={(e) => handleClickProjectName(project.code, project.state)}
					disableRipple>
					<Typography>{project.name}</Typography>
				</Button>
			</TableCell>

			{/* 평가과제 */}
			<TableCell>
				<Typography style={{ fontSize: 12 }}>{templateProject ? templateProject.name : ""}</Typography>
			</TableCell>

			{/* 학교급 */}
			{(!navigateStore.simpleMode) &&
				<TableCell>
					{
						i18next.t(ProjectSchoolLabelType[project.schoolType || ProjectSchoolType.NOGRADE])
					}
				</TableCell>
			}

			{/* 학년도/학기 */}
			{(!navigateStore.simpleMode) &&
				<TableCell>
					{getSemesterTypeString(project.year, project.semesterType)}
				</TableCell>
			}


			{/* 등록일 */}
			<TableCell style={{ fontSize: 12 }}>
				{convertUTCToKST(project.createdDatetime).format("YYYY.MM.DD")}
			</TableCell>

			{/* 제출 기한 */}
			<TableCell style={{ fontSize: 12 }}>
				{project.endDatetime ? convertUTCToKST(project.endDatetime).format("MM-DD HH:mm") : "-"}
			</TableCell>

			{/* 제출 진행율 */}
			<TableCell >
				<Box display="flex" alignItems="center">
					<Tooltip placement="bottom" title={`${project.submittedCount}/${project.totalCount}`}>
						<Box className={classes.numberBox} style={{ width: "calc(20%)" }}>
							<Typography noWrap={true}>
								<span>{project.submittedCount}</span>/{project.totalCount}
							</Typography>
						</Box>
					</Tooltip>
					<Box className={classes.progressBox} style={{ width: "calc(55%)" }}>
						<LinearProgress variant="determinate"
							value={project.totalCount === 0 ? 0 : (project.submittedCount / project.totalCount) * 100} />
					</Box>
					<Typography className={classes.progressText} style={{ width: "calc(25%)", textAlign: "right" }}>
						{project.totalCount === 0 ? "0%" : `${Math.round((project.submittedCount / project.totalCount) * 100).toFixed(1)}%`}
					</Typography>
				</Box>
			</TableCell>

			{/* 채점 진행율 */}
			<TableCell >
				<Box display="flex" alignItems="center">
					<Tooltip placement="bottom" title={`${project.scoredCount}/${project.totalCount}`}>
						<Box className={classes.numberBox} style={{ width: "calc(20%)" }}>
							<Typography noWrap={true}>
								<span>
									{
										project.scoredCount
									}
								</span>/{project.totalCount}
							</Typography>
						</Box>
					</Tooltip>
					<Box className={classes.progressBox} style={{ width: "calc(55%)" }}>
						<LinearProgress variant="determinate"
							value={project.totalCount === 0 ? 0 : (project.scoredCount / project.totalCount) * 100} />
					</Box>
					<Typography className={classes.progressText} style={{ width: "calc(25%)", textAlign: "right" }}>
						{project.totalCount === 0 ? "0%" : `${Math.round((project.scoredCount / project.totalCount) * 100).toFixed(1)}%`}
					</Typography>
				</Box>
			</TableCell>

			{/* 제시 상태 */}
			<TableCell>
				<Typography className={checkPosed(project.state) ? classes.suggestCom : classes.suggestBefore} style={{ fontSize: "0.8rem" }}>
					{checkPosed(project.state) ? i18next.t('제시완료') : i18next.t('제시 전')}
				</Typography>
			</TableCell>

			{/* 실행 단계 */}
			<TableCell align="center">
				{projectStateButton(project.code, project.state)}
			</TableCell>
		</TableRow>
	);
}