import { IUserProfile } from "../repositories/model/IUserProfile";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import axios from "axios";
import ProjectSubmissionRepository from "../repositories/ProjectSubmissionRepository";

import { ISubmissionTransfer, ISubmissionTransferResponse } from "../repositories/model/transfer/ISubmissionTransfer";
import { IProjectSubmissionTransfer } from "../repositories/model/transfer/IProjectSubmissionTransfer";
import { ProjectSubmitType } from "../repositories/model/support/ProjectSubmitType";
import { StoreStatus } from "./support/StoreStatus";
import { OcrStatus } from "../repositories/model/support/OcrStatus";
import { blobToUint8Array, IPageSOBP, NeoStroke } from "../neolab-libs/nl-lib3-common";
import { toInkstoreStroke } from "../neolab-libs/nl-lib3-ndp/functions/toInkstoreStroke";
import { neoUnzip, neoZip } from "../neolab-libs/nl-lib3-common/util/functions/unzip-gunzip";
import { ProjectStateType } from "../repositories/model/support/ProjectStateType";
import ProjectRepository from "../repositories/ProjectRepository";
import { IAnswerInfo } from "../repositories/model/transfer/IAnswerInfo";
import ProjectSubmissionAnswerRepository from "../repositories/ProjectSubmissionAnswerRepository";
import { fromInkstoreStroke } from "../neolab-libs/nl-lib3-ndp/functions/fromInkstoreStroke";

const LogPrefix = "[ProjectSubmissionStore]";

const EmptyProjectSubmissionTransfer: IProjectSubmissionTransfer = {
	projectCode: "",
	userCode: "",
	state: null,
	submissionCode: "",
	submitType: ProjectSubmitType.OFFLINE,
	createdDatetime: "",
	updatedDatetime: "",
	userProfile: null as IUserProfile,
	files: [],


	ocrStatus: null, // string; // "string"
	ocrRequestCount: null, // number; // 0
	exportedDatetime: null, // string; // "2024-09-02T05:14:21.378Z"

	project: null, // IProject; // Project object

	projectName: null, // string; // "string"
	school: null, // string; // "string"
	userName: null, // string; // "string"
	grade: null, // number; // 0
	className: null, // string; // "string"
	order: null, // number; // 0
	totalCount: null, // number; // 0
	preCode: null, // string; // "string"
	nextCode: null, // string; // "string"
	exams: null, // IExamInfo[]; // Array of exams
}

// const EmptyProjectSubmissionFileTransfer = {
// 	code: "",
// 	projectSubmissionCode: "",
// 	file: "",
// 	ocrResult: "",
// 	fileName: "",
// 	downloadUrl: ""
// }

type Props = {
	projectRepository: ProjectRepository;
	projectSubmissionRepository: ProjectSubmissionRepository;
	projectSubmissionAnswerRepository: ProjectSubmissionAnswerRepository;
};

export default class ProjectSubmissionStore {
	public projectRepository: ProjectRepository;
	public projectSubmissionRepository: ProjectSubmissionRepository;
	public projectSubmissionAnswerRepository: ProjectSubmissionAnswerRepository;

	public projectSubmissionList: IProjectSubmissionTransfer[];
	public projectSubmissionTotalCount: number;
	public projectSubmission: IProjectSubmissionTransfer; // typeof EmptyProjectSubmissionTransfer;
	public projectSubmissionCode: string;

	public projectSubmissionStatus: StoreStatus;
	public ocrRequestFlag: boolean;

	public studentProjectSubmissionList: ISubmissionTransferResponse;


	constructor(props: Props) {
		this.projectSubmissionRepository = props.projectSubmissionRepository;
		this.projectRepository = props.projectRepository;
		this.projectSubmissionAnswerRepository = props.projectSubmissionAnswerRepository;

		this.projectSubmissionList = [];
		this.projectSubmissionTotalCount = 0;
		this.projectSubmission = Object.assign({}, EmptyProjectSubmissionTransfer);
		this.projectSubmissionCode = null;

		this.projectSubmissionStatus = StoreStatus.INITIAL;
		this.ocrRequestFlag = false;

		this.studentProjectSubmissionList = null;

		makeAutoObservable(this);
	}

	init() {
		this.projectSubmission = Object.assign({}, EmptyProjectSubmissionTransfer);
		this.projectSubmissionStatus = StoreStatus.INITIAL;
		this.projectSubmissionCode = null;
		this.projectSubmissionList = [];
	}
	changeOcrStatus(ocrStatus: OcrStatus, index) {
		if (this.projectSubmissionList[index]) {
			this.projectSubmissionList[index].ocrStatus = ocrStatus;
		}
	}
	changeProjectSubmissionCode(code) {
		this.projectSubmissionCode = code;
	}
/*
	async createAndUploadFileForSubmission(projectCode: string, userCode: string, submitType: ProjectSubmitType, files: FormData) {
		try {
			if (this.projectSubmissionStatus === StoreStatus.PROGRESS)
				throw new Error("Store Progressing");

			this.ocrRequestFlag = true;
			this.projectSubmissionStatus = StoreStatus.PROGRESS;
			console.log("Start createSubmission ...", projectCode, userCode, submitType, files);

			let response = await this.projectSubmissionRepository.createAndUploadFileForSubmission(projectCode, userCode, {}, files);
			this.projectSubmissionStatus = StoreStatus.COMPLETED;

			console.log(LogPrefix, "Success createSubmission ...", response);

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.COMPLETED;
				this.ocrRequestFlag = false;
			});
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot createSubmission ...", e);
			this.projectSubmissionStatus = StoreStatus.FAILED;

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.FAILED;
				this.ocrRequestFlag = false;
			});
			return false;
		}
	}
*/

	async addFileForSubmission(projectCode: string, userCode: string, submissionCode: string, submitType: ProjectSubmitType, files: FormData) {
		try {
			if (this.projectSubmissionStatus === StoreStatus.PROGRESS)
				throw new Error("Store Progressing");

			this.ocrRequestFlag = true;
			this.projectSubmissionStatus = StoreStatus.PROGRESS;
			console.log("Start addFileForSubmission ...", projectCode, userCode, submitType, files);

			let response = await this.projectSubmissionRepository.addFileForSubmission(projectCode, userCode, submissionCode, {}, files);
			this.projectSubmissionStatus = StoreStatus.COMPLETED;
			console.log(LogPrefix, "Success addFileForSubmission ...", response);

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.COMPLETED;
				this.ocrRequestFlag = false;
			});
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot addFileForSubmission ...", e);
			this.projectSubmissionStatus = StoreStatus.FAILED;

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.FAILED;
				this.ocrRequestFlag = false;
			});
			return false;
		}
	}




	async createOnlineSubmission(projectCode: string, userCode: string) {
		try {
			if (this.projectSubmissionStatus === StoreStatus.PROGRESS)
				throw new Error("Store Progressing");

			this.projectSubmissionStatus = StoreStatus.PROGRESS;
			console.log("Start createSubmission ...", projectCode, userCode);

			let response = await this.projectSubmissionRepository.createOnlineSubmission(projectCode, userCode, { submitType: ProjectSubmitType.ONLINE });
			this.projectSubmissionStatus = StoreStatus.COMPLETED;

			console.log(LogPrefix, "Success createSubmission ...", response);

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.COMPLETED;
				this.projectSubmissionCode = response;
			});

			return response;
		} catch (e) {
			console.log(LogPrefix, "Cannot createSubmission ...", e);
			this.projectSubmissionStatus = StoreStatus.FAILED;

			runInAction(() => {
				this.projectSubmissionCode = null;
				this.projectSubmissionStatus = StoreStatus.FAILED;
			});
			return null;
		}
	}

	async deleteSubmission(submissionCode: string) {
		try {
			if (this.projectSubmissionStatus === StoreStatus.PROGRESS)
				throw new Error("Store Progressing");

			this.projectSubmissionStatus = StoreStatus.PROGRESS;
			console.log("Start deleteSubmission ...", submissionCode);

			let response = await this.projectSubmissionRepository.deleteSubmission(submissionCode);
			this.projectSubmissionStatus = StoreStatus.COMPLETED;
			console.log(LogPrefix, "Success deleteSubmission ...", response);

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.COMPLETED;
			});
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot deleteSubmission ...", e);
			this.projectSubmissionStatus = StoreStatus.FAILED;

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.FAILED;
			});
			return false;
		}
	}

	private entering = 0;

	async getSubmissionTransfers(projectCode: string, state, keyword, page, rowsPerPage, sortingHints) {
		const prevState = this.projectSubmissionStatus;
		this.projectSubmissionStatus = StoreStatus.PROGRESS;

		console.log(LogPrefix, `enter getSubmissionTransfers ${++this.entering}`);
		try {
			if (prevState === StoreStatus.PROGRESS) {
				console.log(LogPrefix, `leave getSubmissionTransfers, ${--this.entering}`);
				return false;
			}


			let params: { state?: any, keyword?: string, page?: any, rowsPerPage?: number } = {};
			if (state) params.state = state;
			if (keyword) params.keyword = keyword;
			if (page) params.page = page;
			if (rowsPerPage) params.rowsPerPage = rowsPerPage;
			let data = [];
			if (sortingHints)
				data = sortingHints;
			// console.log("Start getSubmissionTransfers ...", projectCode, params, data)
			let response = await this.projectSubmissionRepository.getSubmissionTransfers(projectCode, params, data);
			this.projectSubmissionStatus = StoreStatus.COMPLETED;
			console.log(LogPrefix, "Success getSubmissionTransfers ...", response);

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.COMPLETED;
				this.projectSubmissionTotalCount = response.totalCount;
				this.projectSubmissionList = response.transfers;
			});
			console.log(LogPrefix, `leave getSubmissionTransfers, ${--this.entering}`);
			return true;
		} catch (e) {
			console.log(LogPrefix, "Cannot getSubmissionTransfers ...");
			this.projectSubmissionStatus = StoreStatus.FAILED;

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.FAILED;
			});

			console.log(LogPrefix, `leave getSubmissionTransfers, ${--this.entering}`);
			return false;
		}


	}

	// individual call for requesting submission transfer object
	async getSubmissionTransfersWithReturnForStudentPenSubmit(projectCode: string, userCode: string) {
		let params: { state?: any, keyword?: string, page?: any, rowsPerPage?: number } = {};
		let data = [];
		let response = await this.projectSubmissionRepository.getSubmissionTransfers(projectCode, params, data);

		// Filter the response to find the matching userCode
		const matchedSubmission = response.transfers.find(submission => submission.userCode === userCode);

		if (matchedSubmission) {
			console.log("Found matching submission for userCode:", matchedSubmission);
			// You can now return the matched submission or do anything else with it
			return matchedSubmission;
		} else {
			console.log("No matching submission found for userCode:", userCode);
			return null;  // Return null if no match is found
		}
	}

	async downloadSubmissionTransferAndStore(projectCode: string, submissionCode: string) {
		try {
			// if(this.projectSubmissionStatus === StoreStatus.PROGRESS)
			//     throw new Error("Store Progressing");

			// this.projectSubmissionStatus = StoreStatus.PROGRESS;
			console.log("Start getSubmissionTransfer ...", projectCode, submissionCode)
			let response = await this.projectSubmissionRepository.getSubmissionTransfer(projectCode, submissionCode);
			console.log(LogPrefix, "Success getSubmissionTransfer ...", response);

			runInAction(() => {
				this.projectSubmission = response;
			});
			// this.projectSubmissionStatus = StoreStatus.COMPLETED;
			return response;
		} catch (e) {
			console.log(LogPrefix, "Cannot getSubmissionTransfer ...", e);
			// this.projectSubmissionStatus = StoreStatus.FAILED;
			return e;
		}
	}
	async getSubmissionTransferForInterval(projectCode, submissionCode) {
		try {
			// if(this.projectSubmissionStatus === StoreStatus.PROGRESS)
			//     throw new Error("Store Progressing");

			// this.projectSubmissionStatus = StoreStatus.PROGRESS;
			console.log("Start getSubmissionTransferForInterval ...", projectCode, submissionCode)
			let response = await this.projectSubmissionRepository.getSubmissionTransfer(projectCode, submissionCode);
			console.log(LogPrefix, "Success getSubmissionTransferForInterval ...", response);
			return response;
		} catch (e) {
			console.log(LogPrefix, "Cannot getSubmissionTransferForInterval ...", e);
			// this.projectSubmissionStatus = StoreStatus.FAILED;
			return e;
		}
	}
	//
	// *getSubmissionSubmission(projectCode){
	//     try{
	//         console.log("Start getSubmissionSubmission ...",projectCode)
	//        let response = await  this.reposityory.getSubmissionSubmission(projectCode);
	//         console.log(LogPrefix, "Success getSubmissionSubmission ...",response);
	//         this.projectSubmission = response;
	//         console.log("getSubmissionSubmission", !!this.projectSubmission);
	//         // this.projectSubmissionStatus = StoreStatus.COMPLETED;
	//         return response;
	//     }catch (e) {
	//         console.log(LogPrefix, "Cannot getSubmissionSubmission ...", e);
	//         this.projectSubmission = null;
	//         return e;
	//     }
	// }

	async requestRetryOCR(projectCode, userCode) {
		try {
			if (this.projectSubmissionStatus === StoreStatus.PROGRESS)
				throw new Error("Store Progressing");

			this.projectSubmissionStatus = StoreStatus.PROGRESS;
			console.log("Start requestRetryOCR ...")
			let response = await this.projectSubmissionRepository.requestRetryOCR(projectCode, userCode);
			this.projectSubmissionStatus = StoreStatus.COMPLETED;
			console.log(LogPrefix, "Success requestRetryOCR ...", response);

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.COMPLETED;
			});

			return response;
		} catch (e) {
			console.log(LogPrefix, "Cannot requestRetryOCR ...", e);
			this.projectSubmissionStatus = StoreStatus.FAILED;

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.FAILED;
			});
			return false;
		}
	}

	// eslint-disable-next-line require-await
	async uploadTest(projectCode: string, userCode: string, submitType: ProjectSubmitType, files: File[]) {
		console.log(`uploadTest start... projectCode=${projectCode}, userCode=${userCode}`);

		const formData = new FormData();
		files.forEach(file => formData.append('mFiles', file))
		formData.append('submitType', new Blob([JSON.stringify(submitType)], { type: 'application/json' }))

		// let response = await  this.reposityory.createSubmission(projectCode, userCode, params, formData);

		const response = axios({
			url: `/api/v1/projects/${projectCode}/users/${userCode}/submissions`,
			method: 'post',
			data: formData,
			headers: {
				contentType: "multipart/form-data"
			}
		});
	}

	async getStudentProjectSubmissionTransfer(state, keyword, page, rowsPerPage, sortingHints) {

		try {
			if (this.projectSubmissionStatus === StoreStatus.PROGRESS)
				throw new Error("Store Progressing");

			this.projectSubmissionStatus = StoreStatus.PROGRESS;

			const data = sortingHints;
			const param = {
				state: state,
				keyword: keyword,
				page: page,
				rowsPerPage: rowsPerPage,
			};

			console.log("Start getStudentProjectSubmissionTransfer... param=", param)
			let response = await this.projectSubmissionRepository.getStudentProjectSubmissionTransfer(param, data);
			this.projectSubmissionStatus = StoreStatus.COMPLETED;

			console.log(LogPrefix, "Success getStudentProjectSubmissionTransfer ...", response);

			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.COMPLETED;
				this.studentProjectSubmissionList = response;
			});
			return response;
		} catch (e) {
			this.projectSubmissionStatus = StoreStatus.FAILED;

			console.log(LogPrefix, "Cannot getStudentProjectSubmissionTransfer ...", e);
			runInAction(() => {
				this.projectSubmissionStatus = StoreStatus.FAILED;
			});
			return e;
		}
	}

	/**
	 * submissionCode가 null이거나, undefined이면 submissionCode를 만들어서 넘기고, 그렇지 않은 경우에는 submissionCode와 같은 값을 넘긴다.
	 * @param projectCode
	 * @param userCode
	 * @param submissionCode
	 * @param strokes
	 * @returns
	 */
	private async uploadAnswerStrokeForSubmission(projectCode: string, userCode: string, submissionCode: string, strokes: NeoStroke[]) {
		try {
			console.log("Start createSubmission ...", projectCode, userCode);

			let formData = new FormData();
			formData.append('submitType', new Blob([JSON.stringify(ProjectSubmitType.PEN)], { type: 'application/json' }))

			const inkStrokes = strokes.map(stroke => toInkstoreStroke(stroke));
			const inkStrokesText = JSON.stringify(inkStrokes);
			const inkStrokesUint8 = new TextEncoder().encode(inkStrokesText);
			const zipped = await neoZip(inkStrokesUint8);
			const zippedBlob = new Blob([zipped], { type: 'application/zip' });

			const dateTimeString = new Date().toISOString().replace(/:/g, "-");

			const file = new File([zippedBlob], `strk_${submissionCode || "first"}-${dateTimeString}.zip`, { type: "application/vnd.neolab.stroke+json+zip" });
			formData.append('mFiles', file);

			let returnedSubmissionCode = null;
			if (submissionCode === null || submissionCode === undefined) {
				// 처음 제출하는 경우
				returnedSubmissionCode = await this.projectSubmissionRepository.createAndUploadFileForSubmission(projectCode, userCode, {}, formData);
			} else {
				returnedSubmissionCode = await this.projectSubmissionRepository.addFileForSubmission(projectCode, userCode, submissionCode, {}, formData);
			}

			console.log(LogPrefix, "Success createSubmission ... submissionCode:", returnedSubmissionCode);

			return returnedSubmissionCode;
		} catch (e) {
			console.log(LogPrefix, "Cannot createSubmission ...", e);
			return null;
		}
	}

	/**
	 * submissionCode가 null이거나, undefined이면 submissionCode를 만들어서 넘기고, 그렇지 않은 경우에는 submissionCode와 같은 값을 넘긴다.
	 * @param args
	 * @returns
	 */
	public async uploadAnswerStrokes(args: {
		projectCode: string,
		userCode: string,
		range: { start: IPageSOBP, end: IPageSOBP },
		strokes: NeoStroke[],
		submissionCode?: string
	}) {
		const { projectCode, userCode, strokes, submissionCode } = args;
		console.log(LogPrefix, "upload strokes start ...", projectCode, userCode, strokes?.length);

		const returnedSubmissionCode = await this.uploadAnswerStrokeForSubmission(projectCode, userCode, submissionCode, strokes);
		console.log(LogPrefix, `compareFinish createPenSubmission ... submissionCode: ${submissionCode} ==>  ${returnedSubmissionCode}`,);


		const params = {
			state: ProjectStateType.OFFLINE_UPLOADED
		}
		await this.projectRepository.updateProjectUserState(projectCode, userCode, params);
		console.log(LogPrefix, "compareFinish updateProjectUserState");

		return returnedSubmissionCode;
	}


	private async uploadRenderedAnswerPdfForSubmission(projectCode: string, userCode: string, pdfBlobURL: string) {
		try {
			console.log(LogPrefix, "Start createSubmission ...", projectCode, userCode);

			let formData = new FormData();

			const res = await fetch(pdfBlobURL);
			const pdfUrl = await res.blob();

			formData.append('submitType', new Blob([JSON.stringify(ProjectSubmitType.PEN)], { type: 'application/json' }))

			const file = new File([pdfUrl], "pdfWithStroke.pdf", { type: "application/pdf" });
			formData.append('mFiles', file);

			const submissionCode = await this.projectSubmissionRepository.createAndUploadFileForSubmission(projectCode, userCode, {}, formData);
			console.log(LogPrefix, "Success createSubmission ... submissionCode:", submissionCode);

			return submissionCode;
		} catch (e) {
			console.log(LogPrefix, "Cannot createSubmission ...", e);
			return null;
		}
	}



	public async uploadAnswerDataAndRenderedPdf(args: {
		projectCode: string,
		userCode: string,
		answerList: {
			projectExamCode: string,
			projectExamQuestionNum: number,
			answer: string,
			ocrResult: string,

			// 여기서 할당하는 값
			projectSubmissionCode? : string
		}[],
		range: { start: IPageSOBP, end: IPageSOBP },
		pdfBlobURL: string,
		submissionCode: string,
	}) {
		const { projectCode, userCode, answerList, pdfBlobURL } = args;
		let { submissionCode } = args;
		console.log(`compareFinish start ... projectCode=${projectCode}, userCode=${userCode}, submissionCode=${submissionCode}, pdfBlobURL=${pdfBlobURL}, answerList=${answerList}`);

		if (pdfBlobURL && !submissionCode) {
			submissionCode = await this.uploadRenderedAnswerPdfForSubmission(projectCode, userCode, pdfBlobURL);
			console.log("compareFinish createPenSubmission ... submissionCode:", submissionCode);
		}

		for (const answer of answerList) {
			answer.projectSubmissionCode = submissionCode;
		}

		try {
			await this.projectSubmissionAnswerRepository.createSubmissionAnswers(submissionCode, answerList)
		} catch (e) {
			await this.projectSubmissionRepository.deleteSubmission(submissionCode);
			return;
		}

		console.log("compareFinish createSubmissionAnswers answerList:", answerList);
		const params = {
			state: ProjectStateType.COMPARED
		}

		await this.projectRepository.updateProjectUserState(projectCode, userCode, params);
		// await this.removeOfflineData({ range });

		console.log("compareFinish updateProjectUserState");
	}


	public async downloadStrokesFromServer(submissionTransfer: IProjectSubmissionTransfer) {
		const c = toJS(submissionTransfer?.files || []);

		const files = c.filter((item) => item.downloadUrl.endsWith(".zip") || item.contentsType.toUpperCase() === "STRK_ZIP");
		const sorted = files.sort((a, b) => b.order - a.order);

		if (sorted.length > 0) {
			// 가장 최신의 파일을 다운로드
			const file = sorted[0];
			const url = file.downloadUrl;

			try {
				const response = await axios.get(url, { responseType: 'blob' });
				const blob = new Blob([response.data], { type: 'application/vnd.neolab.stroke+json+zip' });
				// blob to ArrayBuffer
				const buf = await blobToUint8Array(blob);
				const unzipped = await neoUnzip(buf);
				// convert unziiped data to json text
				const jsonText = new TextDecoder().decode(unzipped);
				const inkStrokes = JSON.parse(jsonText);

				const strokes = inkStrokes.map((item) => {
					return fromInkstoreStroke({ sobp: item.sobp, stroke: item })
				});

				console.log(url)

				return strokes;
			} catch (error) {
				console.error(error);
			}
		}
		return null;
	}


}
