import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Theme,
	Typography
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as AddExamIcon } from '../../../../common/images/AddExamIcon.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ExamRangeUserStyle } from "../../styles/ExamRangeUserStyle";

import { observer } from "mobx-react";
import React, { useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
// import { withRouter } from "../../../../components/WithRouter";
import useNaviCustom from "../../../../hooks/useNaviCustom";

import fileDialog from 'file-dialog';
import { globalConfig } from "../../../../config/globalConfig";
import { useStores } from "../../../../hooks/useStores";
import { DocImporter, NativeConvertedQuestion } from "../../../../neolab-libs/nl-lib3-pengateway/src";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { ProjectExamListUpdateType } from "../../../../repositories/model/support/ProjectExamListUpdateType";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import ProjectPreviewRightPannel from "../0_RightPannel/ProjectPreviewRightPannel";
import { ExamRow } from "./ExamRow";
import { ImportButton } from "./ImportButton";
import { uploadImageFilesToCloud } from "./uploadImageFileToCloud";
import { useParams } from "react-router-dom";
import { useNeoConfirm } from "../../../../hooks/useNeoConfirm";
import { SampleQuestionGenerator, SampleQuestionInfo } from "./SampleQuestionGenerator";
import { questionSampleStore } from './stores/QuestionSampleStore';

type Props = {
};

function CreateQuestionsMain(props: Props) {
	const params = useParams();
	const { navigateStore, projectExamStore, fileInfoStore, projectStore, } = useStores();
	const classes = useEmotionStyles( ExamRangeUserStyle );
	const i18next = useTranslation();
	// const [projectCode, setProjectCode] = React.useState("");
	const [countScrollingTop, setCountScrollingTop] = React.useState(0);
	const contentsBoxRef = React.useRef(null);
	let leftBoxScrollTopSetTimeOut;

	const naviCustom = useNaviCustom();
	const {alert, confirm} = useNeoConfirm();

	const [sampleQuestions] = useState(SampleQuestionGenerator.sampleQuestions);

	React.useEffect(() => {
		navigateStore.changeNavigateRootPage(false);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);
		navigateStore.changeProjectSideBarStep(2);
		navigateStore.changeSideBarStepNum(2);

		return () => {
			navigateStore.changeIsConfirmForUpdateRequired(false);
			// projectExamStore.changeIsConfirmForUpdateRequired(false);
			const imageIdsToBeDeleted = async () => {
				return await projectExamStore.getImgeFileIdListToBeDeleted();
			}

			const flushUp = async () => {
				try {
					// const idList = await imageIdsToBeDeleted()
					const idList = projectExamStore.getImgeFileIdListToBeDeleted();

					console.log("idList=", idList)
					if (idList.length === 0) return;

					fileInfoStore.deleteList(idList);
				} catch (e) {
					console.log("[ExamRangeUser] getImgeFileIdListToBeDeleted" + e);
				}
				finally {
					projectExamStore.init();
					navigateStore.changeNavigateDetailPage(null);
				}
			}

			flushUp();
		}
	}, []);

	React.useEffect(() => {
		const { projectCode } = params;
		if (!projectCode || projectCode === projectExamStore.projectCode) return;

		projectExamStore.setProjectCode(projectCode);
		projectExamStore.downloadProjectExamListAndStore(projectCode);
	}, [params, projectExamStore.projectCode]);

	React.useEffect(() => {
		if (countScrollingTop > 0) return;

		if (projectExamStore.projectExamList.length > 0) {
			leftBoxScrollTopSetTimeOut = setTimeout(
				() => {
					if (!contentsBoxRef.current) return;

					contentsBoxRef.current.scrollTop = 0;
					setCountScrollingTop(prev => prev + 1);
					clearTimeout(leftBoxScrollTopSetTimeOut);
				}, 10);
		}
	}, [projectExamStore.projectExamList]);

	React.useEffect(() => {
		if (!projectExamStore.isConfirmForUpdateRequired
			|| navigateStore.isConfirmForUpdateRequired
		) return;

		navigateStore.changeIsConfirmForUpdateRequired(true);
	}, [projectExamStore.isConfirmForUpdateRequired]);

	const nextExamOrder = React.useMemo(() => {
		const next = projectExamStore.projectExamList.reduce((acc, cur) => {
			return acc > cur.order ? acc : cur.order;
		}, 0) + 1;
		return next;
	}, [projectExamStore.projectExamList]);

	const isReady = React.useMemo(() => {
		const isProjectCodeExisting =
			(projectExamStore.projectExamList.length > 0
				&& !!projectExamStore.projectExamList[0]?.projectCode);

		return isProjectCodeExisting;
	}, [projectExamStore.projectExamList]);

	const handleClickAddExam = () => {
		projectExamStore.addExam();
	};

	const handleUploadFile = async (file: File) => {
		const { id, downloadUrl } = await fileInfoStore.create(file);
		if (!id || !downloadUrl) return;

		projectExamStore.addImageId(id);
		return { downloadUrl, id };
	};

	const createOrUpdateProject = async () => {

		if (projectExamStore.isNew) {
			// 새로 만드는 경우
			return await projectExamStore.createProjectExamList();
		} else {
			// 기존 과제를 업데이트하는 경우
			return await projectExamStore.updateProjectExamListSteve(ProjectExamListUpdateType.QUESTION);
		}

	};

	// Modify handleClickNextBtn
	const handleClickNextBtn = async () => {
		const confirmed = await confirm(i18next.t("저장하고 다음으로 넘어가시겠습니까?"));
		if (!confirmed) return;

		const result = await createOrUpdateProject();

		if (result.success == false || !projectExamStore.projectCode) {
			
			
			if (result.message != null) {
				await alert(result.message);
			}
			
			return;
		}

		processAfterStorage();

		// Log all selections
		console.log("Selected Samples Status:");
		
		const sampleQuestionInfos: SampleQuestionInfo[] = questionSampleStore.getAllSelectedSamples().map((sample) => {
		    const sampleInstance: SampleQuestionInfo = {
      			examOrder: sample.examOrder,
      			questionOrder: sample.questionOrder,
      			sampleQuestionIndex: sample.sampleQuestionIndex,
      			projectExamCode: sample.projectExamCode,
      			isEssay: sample.isEssay,
		    };

	    	console.log(`Question ${sample.examOrder}-${sample.questionOrder}:`, sampleInstance);
    		return sampleInstance;
	  });


		const sampleQuestionGenerator = new SampleQuestionGenerator();

		if (sampleQuestionInfos.length > 0) {
			await sampleQuestionGenerator.generateRubricIfNonExistent(sampleQuestionInfos);
		}

		// Navigate directly to rubric
		naviCustom.go(`/projects/${projectExamStore.projectCode}/create/rubric`);
	};

	//#region 문제 추출 관련
	const [isLoading, setIsLoading] = useState(false);
	const [importingFileName, setImportingFileName] = useState<string>('');
	const [questions, setQuestions] = useState<NativeConvertedQuestion[]>([]);

	const handleClickImportFile = async () => {

		try {
			const files = await fileDialog({ accept: ['.doc', '.docx'] });

			setIsLoading(true);
			setQuestions([]); // 기존 데이터 초기화
			const file = files[0];
			setImportingFileName(file.name);
			const uint8Array = new Uint8Array(await file.arrayBuffer());

			const questionares = await DocImporter.GetQuestionsHtmlFromWordDocBytes(file.name, uint8Array);

			//
			const { projectCode } = params;
			// const prevProject = toJS(projectExamStore.projectExamList);
			// for (const exam of prevProject) {
			// 	exam.changeFlag = ChangeFlag.DELETED;
			// 	const { questions } = exam;
			// 	for (const question of questions) {
			// 		question.changeFlag = ChangeFlag.DELETED;
			// 	}
			// }
			// await projectExamStore.repository.updateProjectExamList(projectCode, prevProject);

			// 프로젝트의 과제 리스트를 초기화
			projectExamStore.initProjectExamListWithProjectCode(projectCode);

			// 문제수의 루프
			for (let questionIdx = 0; questionIdx < questionares.length; questionIdx++) {
				const examOrder = questionIdx + 1;

				// 문제와 문항의 추가
				if (examOrder > 1) {
					projectExamStore.addExam();
				}

				for (let j = 1; j < (questionares[questionIdx].subQuestions?.length || 0); j++) {
					projectExamStore.addQuestion(examOrder);
				}

				// 각 문제별 "지시문"과 "지문을 추가"
				const { instruction, passage } = questionares[questionIdx];

				// 지시문
				if (instruction) {
					const instructionHtml = await uploadImageFilesToCloud(instruction, fileInfoStore, projectExamStore);
					projectExamStore.changeExamDirective(examOrder, instruction?.text || "");
				}

				// 지문
				if (passage) {
					const passageHtml = await uploadImageFilesToCloud(passage, fileInfoStore, projectExamStore);
					projectExamStore.changeExamParagraph(examOrder, passageHtml);
				}

				// 각 문항별 문제의 내용을 추가
				let subQuestionIdx = 0;
				for (let subQuestion of questionares[questionIdx].subQuestions) {
					const processedHtml = await uploadImageFilesToCloud(subQuestion, fileInfoStore, projectExamStore);
					projectExamStore.changeQuestionContent(questionIdx + 1, subQuestionIdx + 1, processedHtml);
					subQuestionIdx++;
				}
			}

			setQuestions(questionares);
		} catch (error) {
			console.error('문제 추출 중 오류:', error);
		} finally {
			setIsLoading(false);
		}
	}
	//#endregion

	const processAfterStorage = () => {
		navigateStore.changeIsConfirmForUpdateRequired(false);
		projectExamStore.changeIsConfirmForUpdateRequired(false);
	};

	console.log(`isLoading: ${isLoading}`);

	return (
		<div className={classes.root}>
			{
				isReady &&
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						background: '#F2F7F3',
						height: 'calc(100vh - 86px)',
					}}
					style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					className={classes.padding}
				>
					<Box className={classes.minaContentLeftRight} sx={{ position: 'relative' }}>
						<Box className={classes.mainContentBoxLeft}>
							<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginBottom: "0px" }}>
								<Box display='flex' alignItems='center' justifyContent='center'>
									<Typography className={classes.commonSubTitle}>{i18next.t("2. 문항 생성")}</Typography>
								</Box>

								<Box sx={{ marginBottom: "15px" }} display='flex' alignItems='center' gap={2}>
									<ImportButton
										handleClickImportFile={handleClickImportFile}
										isLoading={isLoading}
										classes={classes}
										i18next={i18next}
									/>

									<Button
										className={clsx(classes.greenBtn, classes.greenBtnActive)}
										onClick={handleClickNextBtn}
										disableRipple
									>
										<Typography>{i18next.t("다음")}</Typography>
									</Button>
								</Box>

							</Box>
							<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginBottom: "10px" }}>
								<Typography className={classes.importAnnounce}>
									* {i18next.t("불러오기의 대상이 되는 파일은 doc, docx 파일만 가능합니다.")}
								</Typography>
							</Box>


							<Box className={classes.mainContentBoxLeftInner} ref={contentsBoxRef}>
								{/*<Button className={clsx(classes.importTaskBtn, classes.importTaskBtnActive)} disableRipple>*/}
								{/*    <TaskView /><Typography>문항 가져오기</Typography>*/}
								{/*</Button>*/}

								{projectExamStore.projectExamList.length > 0 &&
									projectExamStore.projectExamList
										.filter(exam => exam.changeFlag !== ChangeFlag.DELETED)
										.map((exam, idx) => {
											return (
												<ExamRow
													key={exam.key}
													idx={idx}
													onUploadFile={handleUploadFile}
													examInfo={exam}
													isNowBulkLoading={isLoading}
													sampleQuestions={sampleQuestions}
												/>
											)
										})
								}

								<Box style={{ width: '100%' }}>
									<Button
										className={clsx(classes.summaryAiBtn, classes.summaryAiBtnActive)}
										onClick={handleClickAddExam}
										disableRipple>
										<AddExamIcon />
										<Typography>
											{i18next.t("{{nextExamOrder}}번 문항 추가", { nextExamOrder })}
										</Typography>
									</Button>
								</Box>
							</Box>
						</Box>

						<ProjectPreviewRightPannel />
					</Box>
				</Box>
			}

			{projectExamStore.isSelectingProjectExamList &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectExamStore.isSelectingProjectExamList}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("조회 중입니다...")}</Typography>
				</Backdrop>
			}

			{(projectExamStore.isCreatingProjectExamList || projectExamStore.isUpdatingProjectExamList) &&
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => (theme as Theme).zIndex.modal + 1 }}
					open={projectExamStore.isCreatingProjectExamList || projectExamStore.isUpdatingProjectExamList}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("저장 중입니다...")}</Typography>
				</Backdrop>
			}

			{(isLoading) &&
				<Backdrop
					sx={{ color: '#fff', zIndex: 9999999 }}
					open={isLoading}
				>
					<CircularProgress color="inherit" />
					<Typography style={{ color: '#fff' }}>&nbsp; {i18next.t("{{importingFileName}}을 분석하고 있습니다.", { importingFileName })}</Typography>
				</Backdrop>
			}


		</div>
	);
}

export default observer<typeof CreateQuestionsMain>(CreateQuestionsMain);

