import { useMemo } from 'react';
import { IExamInfo } from '../../../../repositories/model/transfer/IExamInfo';

// const currentParagraphHTML = React.useMemo(() => {
// 	if (!examInfo.paragraph && !examInfo.source) return "<></>";
// 	const sourceFontSize = "0.75rem";
// 	const sourceColor = "#787878";
// 	const source = examInfo.source.replace(/\n/g, "<br>");
// 	return "<p>" + examInfo.paragraph + "\n\n" + `<div style='margin-top: 15px; color: ${sourceColor};font-size: ${sourceFontSize}; text-align: right'>` + source + '</div>' + "</p>";
// }, [examInfo.paragraph, examInfo.source]);

export function useCurrentParagraphHTML(args) {
    const { examInfo, paragraph, source } = args;

    let paragraphDivTag = "";
    let paragraphDivEndTag = "";
    if (paragraph) {
        const {
            fontSize: f2,
            color: c2,
            paddingLeft: pl,
            paddingRight: pr,
            paddingTop: pt,
            paddingBottom: pb,
            fontWeight: fw,
            marginTop: mt,
            marginBottom: mb,
            marginLeft: ml,
            marginRight: mr,
            textAlign: ta
        } = paragraph;

        const flag = pl || pr || pt || pb || fw || mt || mb || ml || mr || c2 || f2 || ta;
        if (flag) {
            paragraphDivTag =
                `<div style='` +
                `${pl ? `padding-left: ${pl}; ` : ""}` +
                `${pr ? `padding-right: ${pr}; ` : ""}` +
                `${pt ? `padding-top: ${pt}; ` : ""}` +
                `${pb ? `padding-bottom: ${pb}; ` : ""}` +
                `${fw ? `font-weight: ${fw}; ` : ""}` +
                `${mt ? `margin-top: ${mt}; ` : ""}` +
                `${mb ? `margin-bottom: ${mb}; ` : ""}` +
                `${ml ? `margin-left: ${ml}; ` : ""}` +
                `${mr ? `margin-right: ${mr}; ` : ""}` +
                `${c2 ? `color: ${c2}; ` : ""}` +
                `${f2 ? `font-size: ${f2}; ` : ""}` +
                `${ta ? `text-align: ${ta}; ` : ""}` +
                `'>`;
        } else {
            paragraphDivTag = "<div>";
        }
        paragraphDivEndTag = "</div>";
    }

    const fontSize = "0.75rem";
    const color = "#787878";
    let sourceDivTag = `<div style='margin-top: 15px; color: ${color};font-size: ${fontSize}; text-align: right'>`;
    let sourceDivEndTag = "</div>";

    if (source) {
        const {
            fontSize: f2,
            color: c2,
            paddingLeft: pl,
            paddingRight: pr,
            paddingTop: pt,
            paddingBottom: pb,
            fontWeight: fw,
            marginTop: mt,
            marginBottom: mb,
            marginLeft: ml,
            marginRight: mr,
            textAlign: ta
        } = source;

        const flag = pl || pr || pt || pb || fw || mt || mb || ml || mr || c2 || f2 || ta;
        if (flag) {
            sourceDivTag =
                `<div style='` +
                `${pl ? `padding-left: ${pl}; ` : ""}` +
                `${pr ? `padding-right: ${pr}; ` : ""}` +
                `${pt ? `padding-top: ${pt}; ` : ""}` +
                `${pb ? `padding-bottom: ${pb}; ` : ""}` +
                `${fw ? `font-weight: ${fw}; ` : ""}` +
                `${mt ? `margin-top: ${mt}; ` : ""}` +
                `${mb ? `margin-bottom: ${mb}; ` : ""}` +
                `${ml ? `margin-left: ${ml}; ` : ""}` +
                `${mr ? `margin-right: ${mr}; ` : ""}` +
                `${c2 ? `color: ${c2}; ` : ""}` +
                `${f2 ? `font-size: ${f2}; ` : ""}` +
                `${ta ? `text-align: ${ta}; ` : ""}` +
                `'>`;
        } else {
            sourceDivTag = "<div>";
        }
    }

    return useMemo(() => {
        if (!examInfo.paragraph && !examInfo.source) return "";
        const source = examInfo.source ? examInfo.source.replace(/\n/g, "<br>") : "";

        return (
            "<p>" +
            (paragraphDivTag + (examInfo.paragraph || "") + paragraphDivEndTag || "") +
            "\n\n" +
            (sourceDivTag + source + sourceDivEndTag || "") +
            "</p>"
        );
    }, [examInfo.paragraph, examInfo.source]);
}


// 사용 예시
// const currentParagraphHTML = useCurrentParagraphHTML({examInfo});
