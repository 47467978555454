import { Box, Button, Popover, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { StudentTaskListStlye } from "../../styles/StudentTaskListStlye";

import useEmotionStyles from "../../styles/useEmotionStyles";
import { ISubmissionTransfer } from "../../../../repositories/model/transfer/ISubmissionTransfer";
import { convertUTCToKST } from "../../../../hooks/convertUTCToKST";

import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
//import { ReactComponent as SmartPenEnroll } from '../../../../../common/images/SmartPenEnroll.svg';
import { ReactComponent as SmartPenEnroll } from '../../../../common/images/SmartPenEnroll.svg';

import clsx from "clsx";
import { IProjectSubmissionTransfer } from "../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import { ProjectSubmitType } from "../../../../repositories/model/support/ProjectSubmitType";
import i18next from "i18next";
import React from "react";
import { INcodeAllocationResponse } from "../../../../repositories/model/transfer/INcodeAllocationResponse";
import { useStores } from "../../../../hooks/useStores";
import { toJS } from "mobx";
import dayjs from 'dayjs';

type Props = {
	studentTransfer: ISubmissionTransfer,
	index: number,
	rowsPerPage: number,
	page: number,
	handleClickAnswer: any,
	handleClickCompletedReview: any,
	handleClickReview: any,
	handleClickProjectName: any,
}

export function StudentTaskListRow(props: Props) {

	const { studentTransfer, index, rowsPerPage, page, handleClickAnswer, handleClickCompletedReview, handleClickReview, handleClickProjectName } = props;
	const classes = useEmotionStyles(StudentTaskListStlye);


	const checkCompared = (state) => {
		switch (state) {
			case ProjectStateType.CREATED:
			case ProjectStateType.POSED:
			case ProjectStateType.SUBMITTED:
			case ProjectStateType.TRANSLATED:
				return false;
			case ProjectStateType.COMPARED:
			case ProjectStateType.SCORED:
			case ProjectStateType.EXPORTED:
				return true;
		}
	}

	const transfer = toJS(studentTransfer);
	console.log(transfer);

	const deadLineString = (transfer: ISubmissionTransfer) => {
		if (transfer.deadline) {
			return convertUTCToKST(transfer.deadline).format("YYYY.MM.DD hh:mm");
		}

		if (transfer.submittedDatetime)
			return i18next.t("(오프라인 제출)");
		else
			return "-";
	}

	const isDeadlinePassed = (deadline?: string) => {
		if (!deadline) return false;
		return dayjs().isAfter(dayjs(deadline));
	};

	const renderActionButton = (transfer: ISubmissionTransfer) => {
		const deadlinePassed = isDeadlinePassed(transfer.deadline);

		if (transfer.state === ProjectStateType.EXPORTED) {
			return transfer.reviewedDatetime ? (
				<Button
					className={clsx(classes.stepOpen, classes.taskSave)}
					onClick={() => handleClickCompletedReview(transfer.projectCode, transfer.submissionCode)}
					disableRipple
				>
					<Typography variant="caption">{i18next.t("결과 리뷰완료")}</Typography>
				</Button>
			) : (
				<Button
					className={clsx(classes.stepOpen, classes.taskReview)}
					onClick={() => handleClickReview(transfer.projectCode, transfer.submissionCode)}
					disableRipple
				>
					<Typography variant="caption">{i18next.t("결과 리뷰하기")}</Typography>
				</Button>
			);
		}

		if (transfer.state === ProjectStateType.OFFLINE_UPLOADED) {
			return <Button
				className={clsx(classes.stepOpen,  deadlinePassed ? classes.taskResultDisabled : classes.taskResult)}
				onClick={() => handleClickAnswer(transfer.projectCode, transfer.state, transfer.submissionCode)}
				disableRipple
				disabled={deadlinePassed}
			>
				<Typography variant="caption">
					{i18next.t("필기 업로드 완료")
					}
				</Typography>
			</Button>
		}
		

		return checkCompared(transfer.state) ? (
			<Button
				className={clsx(classes.stepOpen, classes.taskSave)}
				onClick={() => handleClickAnswer(transfer.projectCode, transfer.state, transfer.submissionCode)}
				disableRipple
			>
				<Typography variant="caption">{i18next.t("답안 제출완료")}</Typography>
			</Button>
		) : (
			<Button
				className={clsx(classes.stepOpen,  deadlinePassed ? classes.taskResultDisabled : classes.taskResult)}
				onClick={() => handleClickAnswer(transfer.projectCode, transfer.state, transfer.submissionCode)}
				disableRipple
				disabled={deadlinePassed}
			>
				<Typography variant="caption">
					{deadlinePassed ? i18next.t("제출시한 만료") : i18next.t("답안 제출하기")
					}
				</Typography>
			</Button>
		);
	};

	return (
		<TableRow key={index} sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>
			<TableCell align="center">
				{(rowsPerPage * (page - 1)) + index + 1}
			</TableCell>
			<TableCell>
				<Button className={classes.tableStudentNameBtn} onClick={e => handleClickProjectName(studentTransfer.state, studentTransfer.projectCode, studentTransfer.submissionCode)} disableRipple>
					<Typography>{studentTransfer.projectName}</Typography>
				</Button>
			</TableCell>
			<TableCell align="center">
				<Typography className={classes.resultNumber}><span>{studentTransfer.scoredCount}</span>/{studentTransfer.totalCount}</Typography>
			</TableCell>
			<TableCell>
				{/*todo 채점결과 발표 === exported 일때 인데  어떻게 발표일을 정해야 할지 정해야함*/}
				{studentTransfer.scoredDatetime ? convertUTCToKST(studentTransfer.scoredDatetime).format("YYYY.MM.DD hh:mm") : "-"}
			</TableCell>
			<TableCell>
				{/*todo transfer에 온라인 제출 여부 포함필요*/}
				{studentTransfer.submittedDatetime ? convertUTCToKST(studentTransfer.submittedDatetime).format("YYYY.MM.DD hh:mm") : "-"}
			</TableCell>
			<TableCell>
				{/*todo transfer에 온라인 제출 기한 포함필요*/}
				{deadLineString(studentTransfer)}
			</TableCell>
			{/*<TableCell>*/}
			{/*    {transfer.reviewedDatetime ? dayjs(transfer.reviewedDatetime).format("YYYY.MM.DD hh:mm") : "-"}*/}
			{/*</TableCell>*/}
			<TableCell>
				{renderActionButton(studentTransfer)}
			</TableCell>
		</TableRow>)

}