import { makeAutoObservable, runInAction, toJS } from "mobx";

import { PDFDocument, PDFFont, PDFPage, rgb } from "pdf-lib";
import NcodeAllocationRepository from "../repositories/NcodeAllocationRepository";
import { IProject } from "../repositories/model/IProject";
import { IExamInfo } from "../repositories/model/transfer/IExamInfo";
import { INcodeAllocationResponse } from "../repositories/model/transfer/INcodeAllocationResponse";
import { getSemesterTypeString } from "../views/main/000_Common/001_Functions/getSemesterTypeString";
import { convertSubjectNameTypeEnToKr } from "./ProjectStore";
import { ProjectExamType } from "../repositories/model/support/ProjectExamType";
import fontkit from "@pdf-lib/fontkit";
import { serverContextPath } from "../AppConstants";
import { nprojToIManualRegisterPdfType } from "../neolab-libs/nl-lib2-pagestorage/background-pdf-mapper/functions/nprojToIManualRegisterPdfType";
import { manualRegisterAtMappingStorage } from "../neolab-libs/nl-lib2-pagestorage/background-pdf-mapper/functions/manualRegisterAtMappingStorage";
import generateNprojFromSizes from "../neolab-libs/nl-lib3-common/nproj/generateNprojFromSizes";
import { IPageSOBP } from "../neolab-libs/nl-lib3-common";
import { ISimpleUser } from "../repositories/model/ISimpleUser";


const LogPrefix = "[NcodeAllocationStore]";

type NcodeAllocationStoreProps = {
	ncodeAllocationRepository: NcodeAllocationRepository;
};

export default class NcodeAllocationStore {
	public ncodeAllocationRepository: NcodeAllocationRepository;


	// Observable state
	private _currentAllocation: INcodeAllocationResponse | null = null;
	private _projectAllocations: INcodeAllocationResponse[] = [];
	private _isLoading: boolean = false;
	private _error: string | null = null;

	public questionNameToPageInfo: {
		[key: string]: {
			page: number
		}
	} = {};

	constructor(props: NcodeAllocationStoreProps) {
		this.ncodeAllocationRepository = props.ncodeAllocationRepository;
		makeAutoObservable(this);
	}

	// Getters
	get currentAllocation() { return this._currentAllocation; }
	get projectAllocations() { return this._projectAllocations; }
	get isLoading() { return this._isLoading; }
	get error() { return this._error; }


	/**
	 * projectCode는 Assignment일 수도 있고, templateProjectCode일 수도 있다.
	 * templateProjectCode가 있으면, Assignment, null 이면 templateProject
	 * 2024-11-17에 이 정책으로 바꾸었다.
	 *
	 * @param projectCode
	 * @param templateProjectCode
	 * @param pageCount
	 * @returns
	 */

	async allocateCode(projectCode: string, templateProjectCode: string, pageCount: number) {
		try {
			this._isLoading = true;
			this._error = null;

			let finalProjectCode = projectCode;

			// template project code로 할당
			if (templateProjectCode) finalProjectCode = templateProjectCode;
			const response = await this.ncodeAllocationRepository.allocateCode({
				projectCode: finalProjectCode,
				pageCount
			});


			runInAction(() => {
				this._currentAllocation = response;
			});

			console.log(LogPrefix, "Success allocate code...", response);
			return response;
		} catch (e) {
			console.error(LogPrefix, "Cannot allocate code...", e);
			runInAction(() => {
				this._error = e.message;
			});
			return null;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	/**
	 * 할당 ID로 할당 정보를 조회합니다.
	 */
	async getAllocation(allocationId: string) {
		try {
			this._isLoading = true;
			this._error = null;

			const allocation = await this.ncodeAllocationRepository.getAllocation(allocationId);
			runInAction(() => {
				this._currentAllocation = allocation;
			});

			console.log(LogPrefix, "Success get allocation...", allocation);
			return allocation;
		} catch (e) {
			console.error(LogPrefix, "Cannot get allocation...", e);
			runInAction(() => {
				this._error = e.message;
			});
			throw e;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	/**
	 * 프로젝트의 모든 할당 정보를 조회합니다.
	 */
	async getProjectAllocations(projectCode: string, filterReturned = true) {
		try {
			this._isLoading = true;
			this._error = null;

			let allocations = await this.ncodeAllocationRepository.getAllocationsByProject(projectCode);

			runInAction(() => {
				if (filterReturned) {
					allocations = allocations.filter(allocation => !allocation.returned);
				}

				this._projectAllocations = allocations;
			});

			// console.log(LogPrefix, "Success get project allocations...", allocations);
			return allocations;
		} catch (e) {
			// console.error(LogPrefix, "Cannot get project allocations...", e);
			runInAction(() => {
				this._error = e.message;
			});
			return [];
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	/**
	 * projectCode는 Assignment일 수도 있고, templateProjectCode일 수도 있다.
	 * templateProjectCode가 있으면 Assignment, null 이면 templateProject
	 * 2024-11-17에 이 정책으로 바꾸었다.
	 *
	 * @param projectCode
	 * @param templateProjectCode
	 * @param pageCount
	 * @returns
	 */
	async getProjectLastAllocation(projectCode: string, templateProjectCode: string, pageCount = 0): Promise<INcodeAllocationResponse> {
		try {
			const allocationsAssignment = await this.getProjectAllocations(projectCode);
			allocationsAssignment.forEach((allocation) => { allocation.isAssignmentProject = true; });

			const allocationsTemplate = templateProjectCode ? await this.getProjectAllocations(templateProjectCode) : [];
			allocationsTemplate.forEach((allocation) => { allocation.isAssignmentProject = false; });

			const allocations = [];

			allocations.push(...allocationsTemplate, ...allocationsAssignment);
			allocations.sort((a, b) => {
				// assignment project이면 나중에 나오도록
				if (a.isAssignmentProject && !b.isAssignmentProject) return 1;
				if (!a.isAssignmentProject && b.isAssignmentProject) return -1;

				// 같은 종류의 프로젝트이면 최신 것이 나중에 나오도록
				return b.createdAt > a.createdAt ? 1 : -1;
			});

			const last = allocations[0];
			if (last && pageCount > 0) {
				if (last.totalPages === pageCount) {
					return last;
				}
				else {
					return null;
				}
			}

			return last;
		} catch (e) {
			console.error(LogPrefix, "Cannot get project allocations...", e);
			runInAction(() => {
				this._error = e.message;
			});
			return null;
		}
	}
	/**
	 * 할당을 반환합니다.
	 */
	async returnAllocation(allocationId: string, reason: string) {
		try {
			this._isLoading = true;
			this._error = null;

			await this.ncodeAllocationRepository.returnAllocation(allocationId, reason);
			runInAction(() => {

				// 현재 할당이 반환된 경우 null로 설정
				if (this._currentAllocation?.allocationId === allocationId) {
					this._currentAllocation = null;
				}

				// 프로젝트 할당 목록에서도 제거
				this._projectAllocations = this._projectAllocations.filter(
					allocation => allocation.allocationId !== allocationId
				);
			});

			console.log(LogPrefix, "Success return allocation...", allocationId);
		} catch (e) {
			console.error(LogPrefix, "Cannot return allocation...", e);
			runInAction(() => {
				this._error = e.message;
			});
			throw e;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	/**
	 * 원본 PDF 파일을 업로드합니다.
	 */
	async uploadOrgPdf(allocationId: string, file: File, onProgress?: (progress: number) => void) {
		try {
			this._isLoading = true;
			this._error = null;

			await this.ncodeAllocationRepository.uploadOrgPdf(
				allocationId,
				file,
				(progressEvent: any) => {
					const progress = (progressEvent.loaded / progressEvent.total) * 100;
					onProgress?.(progress);
				}
			);

			console.log(LogPrefix, "Success upload original PDF...", allocationId);
		} catch (e) {
			console.error(LogPrefix, "Cannot upload original PDF...", e);
			runInAction(() => {
				this._error = e.message;
			});
			throw e;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	/**
	 * 원본 DOC 파일을 업로드합니다.
	 */
	async uploadOrgDoc(allocationId: string, file: File, onProgress?: (progress: number) => void) {
		try {
			this._isLoading = true;
			this._error = null;

			await this.ncodeAllocationRepository.uploadOrgDoc(
				allocationId,
				file,
				(progressEvent: any) => {
					const progress = (progressEvent.loaded / progressEvent.total) * 100;
					onProgress?.(progress);
				}
			);

			console.log(LogPrefix, "Success upload original DOC...", allocationId);
		} catch (e) {
			console.error(LogPrefix, "Cannot upload original DOC...", e);
			runInAction(() => {
				this._error = e.message;
			});
			throw e;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	/**
	 * N-Code PDF 파일을 업로드합니다.
	 */
	async uploadNcodedPdf(allocationId: string, file: File, onProgress?: (progress: number) => void) {
		try {
			this._isLoading = true;
			this._error = null;

			await this.ncodeAllocationRepository.uploadNcodedPdf(
				allocationId,
				file,
				(progressEvent: any) => {
					const progress = (progressEvent.loaded / progressEvent.total) * 100;
					onProgress?.(progress);
				}
			);

			console.log(LogPrefix, "Success upload N-Coded PDF...", allocationId);
		} catch (e) {
			console.error(LogPrefix, "Cannot upload N-Coded PDF...", e);
			runInAction(() => {
				this._error = e.message;
			});
			throw e;
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	// Helper methods
	clearCurrentAllocation() {
		this._currentAllocation = null;
	}

	clearProjectAllocations() {
		this._projectAllocations = [];
	}

	clearError() {
		this._error = null;
	}


	async addTextToPDF(
		args: {
			project: IProject,
			templateProject: IProject,
			currentSemesterString: string,
			examList: IExamInfo[],
			lang: string,
			teacher: ISimpleUser
		}) {
		const { project, templateProject, currentSemesterString, examList, lang, teacher } = args;

		const pdfDoc = await this.createPDF(project, currentSemesterString, examList, lang);
		

		// 첫 번째 페이지 선택
		const pages = pdfDoc.getPages();
		const fonts = await this.registerFontToPDF(pdfDoc);

		this.addExamInfoToPages({ project, templateProject, pages, fonts, teacher });
		await this.addQuestionInfoToPages(project, examList, pages, pdfDoc, fonts);

		// PDF 저장 및 표시
		const pdfBytes = await pdfDoc.save();

		const blob = new Blob([pdfBytes], { type: 'application/pdf' });

		return { pdfBlobURL: URL.createObjectURL(blob), pageCount: pdfDoc.getPageCount() };
	};

	async registerPdfAtMappingStorage(type: ProjectExamType, pdfBlobURL: string, lang: string, sobp: IPageSOBP, numPages: number) {
		console.log("registerPdfAtMappingStorage");

		// const nprojUrl = '/pdf/config.nproj';

		const pdfUrl = (type === ProjectExamType.DESCRIPTIVE) ? `/pdf/${lang}/DESCRIPTIVE.pdf` : `/pdf/${lang}/ESSAY.pdf`;

		// const nprojStr = await fetch(nprojUrl)
		// 	.then((response) => response.text())
		// 	.then((text) => {
		// 		return text;
		// 	});

		const nprojStr = generateNprojFromSizes({
			title: 'title',
			filename: `${sobp.section}.${sobp.owner}.${sobp.book}.${sobp.page}-${numPages}`,
			startSobp: sobp,
			sizes: Array.from({ length: numPages }).map((item) => ({ width: 585, height: 842 })), // a4
		});

		// nprojStr로부터 dataURL을 생성하여 nrpojUrl로 저장
		const nprojUrl = 'data:text/plain;charset=utf-8,' + encodeURIComponent(nprojStr);

		const registerItem = nprojToIManualRegisterPdfType(nprojStr, {
			// nproj_url: ``,
			nproj_url: nprojUrl,
			// pdf_url_from_paperhub: ``,
			pdf_url_from_paperhub: pdfUrl,
			// pdf_url_for_mapper: ``
			pdf_url_for_mapper: pdfBlobURL //pdfDataUrl
		});

		await manualRegisterAtMappingStorage(registerItem);
	};


	// eslint-disable-next-line require-await
	private addExamInfoToPages(args: {
		project: IProject,
		templateProject: IProject,
		pages: PDFPage[],
		fonts: {
			[name: string]: PDFFont,

		},
		teacher: ISimpleUser
	}) {
		const { project, templateProject, pages, fonts, teacher } = args;
		const { PretendardKR, PretendardBoldKR } = fonts;
		const projectName = `${project.name}`;

		for (const page of pages) {
			page.drawText(
				`${(teacher?.email || "")} (${templateProject?.code ? templateProject.code + ":" : ""} ${templateProject?.name || ""})`,
				{
					x: 20,
					y: page.getHeight() - 28, // 페이지 상단으로부터의 거리
					size: 10,
					color: rgb(0, 0, 0),
					font: PretendardKR,
				}
			);
			page.drawText(
				projectName,
				{
					x: 20,
					y: page.getHeight() - 46, // 페이지 상단으로부터의 거리
					size: 16,
					color: rgb(0, 0, 0),
					font: PretendardBoldKR,
				}
			);

			// 학교명
			page.drawText(
				project.targetGroupName,
				{
					x: 115,
					y: page.getHeight() - 72, // 페이지 상단으로부터의 거리
					size: 11,
					color: rgb(0, 0, 0),
					font: PretendardKR,
				}
			);

			//학년도/학기
			const smesterString = getSemesterTypeString(project.year, project.semesterType);

			page.drawText(`${smesterString}`, {
				x: 300,
				y: page.getHeight() - 72, // 페이지 상단으로부터의 거리
				size: 11,
				color: rgb(0, 0, 0),
				font: PretendardKR,
			});

			// 과목
			page.drawText(convertSubjectNameTypeEnToKr(project.subjectName), {
				x: 485,
				y: page.getHeight() - 72, // 페이지 상단으로부터의 거리
				size: 11,
				color: rgb(0, 0, 0),
				font: PretendardKR,
			});
		}



	}


	async createPDF(
		project: IProject,
		currentSemesterType,
		examList: IExamInfo[],
		lang: string
	) {
		const descriptivePdfFile = await fetch(`/pdf/${lang}/DESCRIPTIVE.pdf`) // 서술형
		const descriptiveArrayBuffer = await descriptivePdfFile.arrayBuffer();
		const descriptivePdfDoc = await PDFDocument.load(descriptiveArrayBuffer);

		const essayPdfFile = await fetch(`/pdf/${lang}/ESSAY.pdf`) // 서술형
		const essayArrayBuffer = await essayPdfFile.arrayBuffer();
		const essayPdfDoc = await PDFDocument.load(essayArrayBuffer);

		const mainPdfDoc = await PDFDocument.create();

		let descriptiveCount = 0;

		for (let examIndex = 0; examIndex < examList.length; examIndex++) {
			const type = examList[examIndex].type;
			const questions = examList[examIndex].questions;

			for (let questionIndex = 0; questionIndex < questions.length; questionIndex++) {
				let questionText = (examIndex + 1) + "-" + (questionIndex + 1);
				console.log("questionText", questionText, type);
		
				if (type === ProjectExamType.DESCRIPTIVE) {
					console.log("add page ProjectExamType.DESCRIPTIVE");
		
					const otherPdfPages = await mainPdfDoc.copyPages(descriptivePdfDoc, descriptivePdfDoc.getPageIndices());
					otherPdfPages.forEach(page => mainPdfDoc.addPage(page));
				}
		
				if (type === ProjectExamType.ESSAY) {
					console.log("add page ProjectExamType.ESSAY");
		
					const otherPdfPages = await mainPdfDoc.copyPages(essayPdfDoc, essayPdfDoc.getPageIndices());
					otherPdfPages.forEach(page => mainPdfDoc.addPage(page));
				}
			}
			
		}

		return mainPdfDoc;
	}


	private async registerFontToPDF(pdfDoc: PDFDocument) {
		pdfDoc.registerFontkit(fontkit);

		let fontBytes = await fetch('/font/Pretendard-Light.ttf').then(res => res.arrayBuffer());
		const PretendardKR = await pdfDoc.embedFont(fontBytes);

		fontBytes = await fetch('/font/Pretendard-SemiBold.ttf').then(res => res.arrayBuffer());
		const PretendardBoldKR = await pdfDoc.embedFont(fontBytes);

		return { PretendardKR, PretendardBoldKR };
	}

	// eslint-disable-next-line require-await
	private async addQuestionInfoToPages(project: IProject, examList: IExamInfo[], pages: PDFPage[], pdfDoc: PDFDocument, fonts: { [name: string]: PDFFont }) {


		const { PretendardBoldKR } = fonts;
		const { PretendardKR } = fonts;
 
		let pageIndex = 0;

		let descriptiveCount = 0;

		for (let examIndex = 0; examIndex < examList.length; examIndex++) {
			const questions = examList[examIndex].questions;
			const type = examList[examIndex].type;
	
			for (let questionIndex = 0; questionIndex < questions.length; questionIndex++) {
				let questionText = (examIndex + 1) + "-" + (questionIndex + 1);
	
				this.questionNameToPageInfo = {
					...this.questionNameToPageInfo,
					[questionText]: { page: pageIndex }
				};
	
				if (questions.length === 1) {
					questionText = `${examIndex + 1}`;
				}
	
				console.log(`print text ${type} on page: ${pageIndex}, question: ${questionText}`);
	
				// Calculate Y-position
				const yPosition1 = pages[pageIndex].getHeight() - (158 + (2 - 2) * 350); 
				const yPosition2 = pages[pageIndex].getHeight() - (158 + (2 - 1) * 350); 

				const maxWidth = pages[pageIndex].getWidth();
				const question = examList[examIndex].questions[questionIndex].question;

				pages[pageIndex].drawText(`${questionText}번 문항`, {
					x: 20,
					y: yPosition1, // Ensuring the same lower position
					size: 11,
					color: rgb(0, 0, 0),
					font: PretendardBoldKR,
				});

				await this.drawWrappedText(pages[pageIndex], `${question}`, 30, yPosition1 - 30, PretendardKR, 11, maxWidth - 60);

				pages[pageIndex].drawText(`${questionText}번 문항 답안 (${type === ProjectExamType.DESCRIPTIVE ? '서술형' : '논술형'})`, {
					x: 20,
					y: yPosition2, // Ensuring the same lower position
					size: 11,
					color: rgb(0, 0, 0),
					font: PretendardBoldKR,
				});

				pages[pageIndex].drawText(``);

				pageIndex++; // Move to the next page for every question
			}
		}
	}

	// Helper function to fetch an image as a Uint8Array
	async fetchImageAsUint8Array(url: string): Promise<Uint8Array> {
    	const response = await fetch(url);
    	const blob = await response.blob();
    	return new Uint8Array(await blob.arrayBuffer());
	}


	private getOrderedNodes(parent: Node): Node[] {
		let result: Node[] = [];
	
		// Convert childNodes (NodeList) to an array
		const childNodes = Array.from(parent.childNodes);
	
		for (const node of childNodes) {
			// Process only the <p> tags
			if (node instanceof HTMLElement && node.tagName === "P") {
				// Directly append all child nodes of <p> tag to the result
				result.push(...Array.from(node.childNodes)); // Flatten <p> content
			} else if (node instanceof Text || node instanceof HTMLElement) {
				result.push(node); // Add other nodes (e.g., text nodes or image nodes) directly
			}
		}
	
		// Remove the last element (if any) as requested
		result.pop();
	
		return result;
	}
	
	

	// Function to draw wrapped text and images
	async drawWrappedText(page: any, htmlString: string, x: number, y: number, font: any, fontSize: number, maxWidth: number): Promise<void> {
		// Remove the outer <p> tags, if they exist
		if (htmlString.startsWith('<p>') && htmlString.endsWith('</p>')) {
			htmlString = htmlString.slice(3, -4); // Remove the first <p> and last </p>
		}
	
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, "text/html");
	
		// Get all child nodes inside the <body> (which should be the content inside <p>)
		const childNodes = Array.from(doc.body.childNodes);

	    let yPosition = y; // Start position

		for (const element of childNodes) {
			if (element.nodeType === Node.TEXT_NODE) {
				const text = element.textContent?.trim();
				if (text) {
					yPosition = this.drawTextWrapped(page, text, x, yPosition, font, fontSize, maxWidth);
				}
			} else if (element instanceof HTMLElement) { // Ensure it's an HTML element
				if (element.tagName === "P") {
					const text = element.textContent?.trim();
					if (text) {
						yPosition = this.drawTextWrapped(page, text, x, yPosition, font, fontSize, maxWidth);
					}
				} else if (element.tagName === "IMG") {
					
					const imageUrl = element.getAttribute("src");
					if (imageUrl) {
						const imageBytes = await this.fetchImageAsUint8Array(imageUrl);
						const image = await page.doc.embedJpg(imageBytes); // Change to embedPng for PNGs
						const { width, height } = image.scale(0.5); // Adjust scaling
						

						page.drawImage(image, {
							x,
							y: yPosition - height, // Position below last text
							width,
							height,
						});
	
						yPosition -= height + 10; // Space below the image
					}

				}
			}
		}
	}

	// Helper function to wrap text properly
	private drawTextWrapped(page: any, text: string, x: number, y: number, font: any, fontSize: number, maxWidth: number): number {
		const words = text.replace(/\u00A0/g, " ").split(" ");

		let line = "";
		let yPosition = y;

	    for (const word of words) {
			let wordToAdd = word;
	
			// If the word itself is too long, break it into chunks
			while (font.widthOfTextAtSize(wordToAdd, fontSize) > maxWidth) {
				let splitIndex = Math.floor(wordToAdd.length / 2); // Start splitting at half length
				while (splitIndex < wordToAdd.length && font.widthOfTextAtSize(wordToAdd.substring(0, splitIndex), fontSize) < maxWidth) {
					splitIndex++;
				}
				splitIndex--; // Ensure it stays within limits
	
				const part1 = wordToAdd.substring(0, splitIndex);
				const part2 = wordToAdd.substring(splitIndex);
	
				// Draw the first part
				page.drawText(part1, {
					x,
					y: yPosition,
					size: fontSize,
					font,
					color: rgb(0, 0, 0),
				});
	
				yPosition -= fontSize + 5; // Move to next line
				wordToAdd = part2; // Continue with the remaining part
			}
	
			// Check if the line with the new word exceeds maxWidth
			const lineWidth = font.widthOfTextAtSize(`${line} ${wordToAdd}`, fontSize);
			if (lineWidth > maxWidth) {
				// Draw current line before adding the new word
				page.drawText(line, {
					x,
					y: yPosition,
					size: fontSize,
					font,
					color: rgb(0, 0, 0),
				});
	
				yPosition -= fontSize + 5; // Move to next line
				line = wordToAdd;
			} else {
				line += ` ${wordToAdd}`;
			}
		}
	
		// Draw the last remaining line
		if (line) {
			page.drawText(line, {
				x,
				y: yPosition,
				size: fontSize,
				font,
				color: rgb(0, 0, 0),
			});
			yPosition -= fontSize + 5;
		}

	    return yPosition;
	}

	private downloadFile = (fileName: string, fileData: Uint8Array) => {
		const blob = new Blob([fileData], { type: "application/pdf" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
		URL.revokeObjectURL(link.href);
	  };
}



// 사용 예시:
/*
const store = new NcodeAllocationStore({
		ncodeAllocationRepository: new NcodeAllocationRepository({
				serverContextPath: 'http://your-server'
		})
});

// 새로운 할당 생성
const allocation = await store.allocateCode('PROJECT-001', 50);

// 파일 업로드
const file = new File([''], 'document.pdf', { type: 'application/pdf' });
await store.uploadOrgPdf(allocation.allocationId, file, (progress) => {
		console.log(`Upload progress: ${progress}%`);
});
*/

const repositoryProps = {
	serverContextPath: serverContextPath,
};

export const ncodeAllocationRepository = new NcodeAllocationRepository(repositoryProps);
