import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputBase,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import React from "react";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TestManageTableNStlye } from "../../styles/TestManageTableNStlye";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import '@mui/x-date-pickers/AdapterDayjs';
import clsx from "clsx";
import { observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddTaskIcon } from '../../../../common/images/AddTaskIcon.svg';
import { ReactComponent as SearchNameIcon } from '../../../../common/images/SearchNameIcon.svg';
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { ReactComponent as TableListOrder } from '../../../../common/images/TableListOrder.svg';
import { TemplateProjectStates } from "../../../../components/common/CommonEnum";
import { useStores } from "../../../../hooks/useStores";
import { DetailPageType } from "../../../../stores/support/DetailPageType";
import TemplateProjectListRows from "./TemplateProjectListRows";
import useNaviCustom from "../../../../hooks/useNaviCustom";

type Props = {
}

function TemplateProjectList(props: Props) {
	const { navigateStore, projectStore } = useStores();

	const classes = useEmotionStyles( TestManageTableNStlye );
	const i18next = useTranslation();

	const [createdSortRotated, setCreatedSortRotated] = React.useState(false);
	const [deadLineSortRotated, setDeadLineSortRotated] = React.useState(false);
	const [stepFilter, setStepFilter] = React.useState('all');
	const [searchKeyword, setSearchKeyword] = React.useState("");
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [selectedSortBtn, setSelectedSortBtn] = React.useState(1);

	const sortByCreateTime = "created_datetime";
	const sortByEndTime = "end_datetime";
	const customNavi = useNaviCustom();

	React.useEffect(() => {
		// const getTemplateProjectList = async () => {
		// 	console.log(`TemplateProjectList React.useEffect [] `);
		// 	const dir = convertSortingDirection(createdSortRotated);
		// 	const sortingHints = [{ column: sortByCreateTime, hint: dir }];
		// 	console.log(`getTemplateProjectList searchKeyword=${searchKeyword} page=${page} rowsPerPage=${rowsPerPage} sortingHints=`, sortingHints);
		// 	await projectStore.getTemplateProjectList(null, searchKeyword, page - 1, rowsPerPage, sortingHints);
		// }

		navigateStore.changeNavigateRootPage(true);
		navigateStore.changeNavigateDetailPage(DetailPageType.PROJECT);

		// getTemplateProjectList();
		return () => {
			navigateStore.changeNavigateDetailPage(null);
		}
	}, []);

	React.useEffect(() => {
		// const { loginUser } = authStore;
		console.log(`TemplateProjectList React.useEffect [stepFilter, rowsPerPage, createdSortRotated, deadLineSortRotated] `);

		let state = null;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== 'all')
			state = stepFilter;

		if (selectedSortBtn === 2) {
			sort = sortByEndTime;
			dir = deadLineSortRotated ? "ASC" : "DESC";
		}
		const sortingHints = [{ column: sort, hint: dir }];

		const getTemplateProjectList = async () => {
			console.log(`getTemplateProjectList searchKeyword=${searchKeyword} page=${page} rowsPerPage=${rowsPerPage} sortingHints=`, sortingHints);
			await projectStore.getTemplateProjectList(state, searchKeyword, page - 1, rowsPerPage, sortingHints);
		}

		getTemplateProjectList();

	}, [stepFilter, rowsPerPage, createdSortRotated, deadLineSortRotated, page, selectedSortBtn, searchKeyword, projectStore])

	// React.useEffect(() => {
	// 	// const { loginUser } = authStore;
	// 	console.log(`TemplateProjectList React.useEffect [page] `);

	// 	let state = null;
	// 	let sort = sortByCreateTime;
	// 	let dir = createdSortRotated ? "ASC" : "DESC";
	// 	if (stepFilter !== 'all')
	// 		state = stepFilter;

	// 	if (selectedSortBtn === 2) {
	// 		sort = sortByEndTime;
	// 		dir = deadLineSortRotated ? "ASC" : "DESC";
	// 	}
	// 	const sortingHints = [{ column: sort, hint: dir }];

	// 	const getTemplateProjectList = async () => {
	// 		console.log(`getTemplateProjectList searchKeyword=${searchKeyword} page=${page} rowsPerPage=${rowsPerPage} sortingHints=`, sortingHints);
	// 		await projectStore.getTemplateProjectList(state, searchKeyword, page - 1, rowsPerPage, sortingHints);
	// 	}

	// 	getTemplateProjectList();

	// }, [page])

	const convertSortingDirection = (flag) => {
		if (flag)
			return "ASC"
		else
			return "DESC"
	}

	const handleDeadLineSortClick = () => {
		setSelectedSortBtn(1);
		setCreatedSortRotated(!createdSortRotated);
	};

	const handleSelectState = async (e) => {
		setStepFilter(e.target.value);
	}

	// const handleClickNewProject = () => {
	// 	naviCustom.go(`/projects/create/info`);
	// }

	const handleChangePage = (value) => {
		customNavi.go(value);
	}

	const handleClickPage = (e, value) => {
		setPage(value);
	}

	const handleChangeSearchKeyword = (e) => {
		setSearchKeyword(e.target.value);
	}

	const handleClickKeywordSearch = () => {
		searchProjectList();
	}

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			searchProjectList();
		}
	}

	const searchProjectList = () => {
		// const { loginUser } = authStore;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (selectedSortBtn === 2) {
			sort = sortByEndTime;
			dir = deadLineSortRotated ? "ASC" : "DESC";
		}
		const sortingHints = [{ column: sort, hint: dir }];

		const getTemplateProjectList = async () => {
			if (stepFilter === 'all')
				await projectStore.getTemplateProjectList(null, searchKeyword, 0, rowsPerPage, sortingHints);
			else
				await projectStore.getTemplateProjectList(stepFilter, searchKeyword, 0, rowsPerPage, sortingHints);
		}

		getTemplateProjectList();
	}

	const templateProjectList = projectStore.templateProjectList;

	const [lang, setLang] = React.useState(navigateStore.language);

	React.useEffect(() => {
		setLang(navigateStore.language);

	}, [navigateStore.language]);

	return (
		<div className={classes.root}>

			{/* ******콘텐츠 영역******* */}
			<Box component="main"
				sx={{
					flexGrow: 1,
					background: '#F2F7F3',
					height: 'calc(100vh - 86px)',
				}}
				display='flex' alignItems='center' justifyContent='center'
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box className={classes.titleTopMg}>
						<Typography className={classes.commonSubTitle}>
							{i18next.t('평가과제관리')} (<span>{templateProjectList && templateProjectList.totalCount && templateProjectList.totalCount}</span>)
						</Typography>
					</Box>
					<Box display='flex' alignItems='center' justifyContent='space-between'>
						<Box display='flex' alignItems='center' justifyContent='space-between'>
							<Typography className={classes.selectText}>{i18next.t('진행 상태')}</Typography>
							<Box>
								<FormControl className={classes.formControl}>
									<Select IconComponent={(props) => (
										<Box>
											<SelectIcon  {...props} />
										</Box>
									)}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "right"
											},
											transformOrigin: {
												vertical: 'top',
												horizontal: 'right',
											},
											// getContentAnchorEl: null,
										}}
										value={stepFilter}
										onChange={handleSelectState}
									>
										<MenuItem value={'all'}>{i18next.t('전체')}</MenuItem>
										{TemplateProjectStates.map(item => {
											return (
												<MenuItem value={item.id} key={item.id}>
													{i18next.t(item.text)}
												</MenuItem>)
										})}
									</Select>
								</FormControl>
							</Box>
							<Paper component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
								className={classes.urlInputBox}>

								<IconButton className={classes.iconBtnReset} onClick={handleClickKeywordSearch} disableRipple>
									<SearchNameIcon />
								</IconButton>

								<InputBase sx={{ ml: 1, flex: 1 }}
									placeholder={i18next.t('평가과제 검색')}
									value={searchKeyword}
									onChange={handleChangeSearchKeyword}
									onKeyDown={handleKeyDown}
									inputProps={{ 'aria-label': i18next.t('평가과제 검색') }} />
							</Paper>
						</Box>
						<Box>
							<Button className={clsx(classes.greenBtn, classes.iconAdd)}
								onClick={() => handleChangePage("/projects/create/info")}
								disableRipple>
								<AddTaskIcon />
								<Typography>{i18next.t('평가과제 만들기')}</Typography>
							</Button>
						</Box>
					</Box>
					<Box>
						<TableContainer component={Paper} className={classes.tableBox} >
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										{/* <TableCell width='4%' className={classes.tableNoBox}></TableCell> */}
										<TableCell width='3%' className={classes.tableNoBox}>{i18next.t('No.')}</TableCell>
										<TableCell width='15%'>{i18next.t('평가과제명')}</TableCell>
										{(!navigateStore.simpleMode) &&
											<TableCell width='7.5%'>{i18next.t('교육과정 시기')}</TableCell>
										}
										<TableCell width='6.5%'>{i18next.t('학교급')}</TableCell>
										<TableCell width='6.5%'>{i18next.t('학년')}</TableCell>
                                        <TableCell width='5.5%'>{i18next.t('교과')}</TableCell>
										{(!navigateStore.simpleMode) &&
											<TableCell width='5.5%'>{i18next.t('과목')}</TableCell>
										}
										<TableCell width='8.5%'>
											<IconButton onClick={handleDeadLineSortClick} className={clsx(classes.iconBtnReset, classes.sortButton)} disableRipple>
												<Typography style={{ fontWeight: 800 }}>{i18next.t('등록일시')}</Typography>
												<TableListOrder style={{ transform: createdSortRotated ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }} />
											</IconButton>
										</TableCell>
										<TableCell width='8.5%'>{i18next.t('진행 상태')}</TableCell>
										<TableCell width='3%'>{i18next.t('관리')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(templateProjectList && templateProjectList.projects && templateProjectList.projects.length > 0) &&
										templateProjectList.projects.map((project, index) => {
											return <TemplateProjectListRows
												key={project.code}
												project={project}
												index={index}
												page={page}
												rowsPerPage={rowsPerPage}
												isForShared={false}
												openAssignments={false}
												expendable={false}
												onClick={() => { }}
											/>
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Box className={classes.pageNumber} display='flex' alignItems='center' justifyContent='center'>
						<Stack spacing={2}>
							<Pagination count={templateProjectList && templateProjectList.projects && templateProjectList.totalCount > 0 ?
								Math.ceil(templateProjectList.totalCount / rowsPerPage) : 1}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				</Box>
			</Box>
		</div>
	);
}

export default observer<typeof TemplateProjectList>(TemplateProjectList);
