import {
	Box,
	Button,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	CircularProgress
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ReactComponent as AIAnswerBtnLoad } from '../../../../common/images/AIAnswerBtnLoad.svg';
import { ReactComponent as AIStandardBtnIcon } from '../../../../common/images/AIStandardBtnIcon.svg';
import { ReactComponent as AddTableIcon } from '../../../../common/images/AddTableIcon.svg';
import { ReactComponent as ChevronLeftRubric } from '../../../../common/images/ChevronLeftRubric.svg';
import { ReactComponent as ChevronLeftRubricOff } from '../../../../common/images/ChevronLeftRubricOff.svg';
import { ReactComponent as ChevronRightRubric } from '../../../../common/images/ChevronRightRubric.svg';
import { ReactComponent as ChevronRightRubricOff } from '../../../../common/images/ChevronRightRubricOff.svg';
import { ReactComponent as CloseMorePage } from '../../../../common/images/CloseMorePage.svg';
import { ReactComponent as MorePage } from '../../../../common/images/MorePage.svg';
import { ReactComponent as RubricInfoIcon } from '../../../../common/images/RubricInfoIcon.svg';
import { ReactComponent as RubricSearch } from '../../../../common/images/RubricSearch.svg';
import { ReactComponent as StandardBoxBtn } from '../../../../common/images/StandardBoxBtn.svg';
import { ReactComponent as TrashDeleteIcon } from '../../../../common/images/TrashDeleteIcon.svg';
import TextViewer from "../../../../components/common/TextViewer";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { RubricStyle } from "../../styles/RubricStyle";


import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { globalConfig } from "../../../../config/globalConfig";
import { useStores } from "../../../../hooks/useStores";
import { IProjectRubricList } from "../../../../repositories/model/transfer/IProjectRubricList";
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { ISystemRubricConditionsToSearch } from "../../../../repositories/model/transfer/ISystemRubricConditionsToSearch";
import { AIStatusType } from "../../../../stores/support/AIStatusType";
import { setQuestionHTMLWithOrderAndScore } from "../../../../util/setQuestionHTMLWithOrderAndScore";
import { ProjectRubricTable } from "./ProjectRubricTable";
import { SystemRubricRow } from "./SystemRubricRow";
import { useNeoConfirm } from "../../../../hooks/useNeoConfirm";
import TextEditor from "../../../../components/common/TextEditor";

type Props = {
	questionIdx,
	ref,

	questionInfo: IQuestionInfo,
	questionListLen: number,
	projectRubricList: IProjectRubricList,
	selectedProjectRubricIdx: number,
	mounted: boolean,
	handleSetMountedFalse,
	onVisibilityChangedTrue: (idx: number) => void,
	shownDetailIdx,
	onStatusChange,
	statusKey,
	isLastQuestion,
	changeIsConfirmForUpdateRequired,


	onVisibilityChanged: (idx: number, visible) => void,
}


export const QuestionRow = (props: Props) => {
	const { questionIdx, ref,
		questionInfo, questionListLen, projectRubricList,
		selectedProjectRubricIdx, mounted,
		handleSetMountedFalse, onVisibilityChangedTrue,
		shownDetailIdx, onStatusChange, statusKey, isLastQuestion,
		changeIsConfirmForUpdateRequired,
	} = props;

	const { projectRubricStore, navigateStore, projectExamStore } = useStores();
	const classes = useEmotionStyles(RubricStyle, { isLastQuestion: isLastQuestion });

	const anchorRef = React.useRef(null);
	const i18next = useTranslation();
	const [openMorePage, setOpenMorePage] = React.useState(true);
	// const [haveOpenedMorePage, setHaveOpenedMorePage] = React.useState(false);
	const [requestingAIRubric, setRequestingAIRubric] = React.useState(false);
	const [countOpenAiRubricTooltip, setCountOpenAiRubricTooltip] = React.useState(0);
	const [countOpenTooltip, setCountOpenTooltip] = React.useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openTooltipWhenMount, setOpenTooltipWhenMount] = React.useState(false);
	const [countCheckingAiRubricWhenMounted, setCountCheckingAiRubricWhenMounted] = React.useState(0);
	const [isMouseEnteringAiBtn, setIsMouseEnteringAiBtn] = React.useState(false);
	
	const [isExampleAnswerEditing, setIsExampleAnswerEditing] = useState(false);
	const [isExampleAnswerSaving, setIsExampleAnswerSaving] = useState(false);
	const [editedExampleAnswer, setEditedExampleAnswer] = useState(questionInfo.correctedAnswer);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;


	const getAIRubricId = React.useRef(null);
	const {alert, confirm} = useNeoConfirm();



	React.useEffect(() => {
		onStatusChange(statusKey, requestingAIRubric);
	}, [requestingAIRubric])



	// // fold가 필요한 경우에는 사용한다. kitty, 2024-10-31
	// React.useEffect(() => {
	// 	if (shownDetailIdx !== questionIdx) {
	// 		handleCloseTooltipWhenMount();
	// 	}

	// 	setOpenMorePage(shownDetailIdx === questionIdx);
	// }, [shownDetailIdx, questionIdx]);
/*
	const currentSystemRubricPaginationCount = React.useMemo(() => {
		const list = systemRubricList;
		const condition = systemRubricConditionsToSearch;

		return list?.rubrics?.length > 0
			? list?.totalCount > condition.rowsPerPage
				? Math.ceil(list?.totalCount / condition.rowsPerPage)
				: 1
			: 0
	}, [systemRubricList?.rubrics, systemRubricConditionsToSearch]);
*/

	const currentOrder = React.useMemo(() => {
		let result = projectExamStore.setQuestionFullOrderByQuestionListLen(questionListLen, questionInfo.parentOrder, questionInfo.order);
		return result;
	}, [questionListLen, questionInfo.parentOrder, questionInfo.order]);

	const currentQuestionHTML = React.useMemo(() => {
		return setQuestionHTMLWithOrderAndScore(questionInfo.question, questionInfo.score, '', currentOrder);
	}, [questionInfo, questionInfo.question, questionInfo.score, questionInfo.reference, currentOrder]);

	/*
	const systemRubricStartNum = React.useMemo(() => {
		const page = systemRubricConditionsToSearch.page;
		const rowsPerPage = systemRubricConditionsToSearch.rowsPerPage;

		return page * rowsPerPage + 1;
	}, [systemRubricConditionsToSearch.page, systemRubricConditionsToSearch.rowsPerPage]);
*/

	const currentProjectRubricList = React.useMemo(() => {
		console.log('projectRubricList updated:', projectRubricList);
		
		const filteredResult = projectRubricList.filter(rubric => 
			rubric.projectExamCode === questionInfo.projectExamCode && 
			rubric.projectExamQuestionNum === questionInfo.projectExamQuestionNum
		);
		return filteredResult;
	}, [projectRubricList, questionInfo.projectExamQuestionNum, questionInfo.projectExamCode]);

	const currentSelectedProjectRubricIdx = React.useMemo(() => {
		if (selectedProjectRubricIdx === undefined || selectedProjectRubricIdx < 0) return 0;

		return selectedProjectRubricIdx;
	}, [selectedProjectRubricIdx]);

	const disableToMoveToPrevPublicRubric = React.useMemo(() => {
		return currentSelectedProjectRubricIdx === 0 || requestingAIRubric;
	}, [currentSelectedProjectRubricIdx, requestingAIRubric]);

	const disableToMoveToNextPublicRubric = React.useMemo(() => {
		return currentSelectedProjectRubricIdx === currentProjectRubricList.length - 1 || requestingAIRubric;
	}, [currentSelectedProjectRubricIdx, currentProjectRubricList, requestingAIRubric]);

	const handleClickPublicRublicPage = (e, value) => {
		const idx = value - 1;
		projectRubricStore.changeQuestionKeyAndSelectedProjectRubricIdxMap(questionInfo.key, idx, true);
	};

	const handleClickMovePrevItem = () => {
		projectRubricStore.changeQuestionKeyAndSelectedProjectRubricIdxMap(questionInfo.key, currentSelectedProjectRubricIdx - 1, true);
	};

	const handleClickMoveNextItem = () => {
		projectRubricStore.changeQuestionKeyAndSelectedProjectRubricIdxMap(questionInfo.key, currentSelectedProjectRubricIdx + 1, true);
	};

	const handleKeyDownSystemRubricSearch = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
//			searchKeyword();
		}
	};
/*
	const handleChangeSearchKeyword = (e) => {
		const keyword = e.target.value;
		projectRubricStore.changeQuestionKeyAndSystemRubricConditionsMapKeyword(questionInfo.key, keyword);
	};

	const handleClickQuestionSystemRublicPage = (e, value) => {
		const page = value - 1;
		projectRubricStore.changeQuestionKeyAndSystemRubricConditionsMapPage(questionInfo.key, page);
	};
*/

	const handleClickMoreOver = () => {
		if (shownDetailIdx === questionIdx) {
			onVisibilityChangedTrue(null);
			return;
		}

		onVisibilityChangedTrue(questionIdx);
	};

	const handleClickAddTableBtn = () => {
		projectRubricStore.addEmptyProjectRubric(questionInfo, true);
		changeIsConfirmForUpdateRequired && changeIsConfirmForUpdateRequired();
	};

	const handleIncreaseCountOpenAiRubricTooltip = () => {
		setCountOpenAiRubricTooltip(prev => prev + 1);
	};

	const handleIncreaseCountOpenTooltip = () => {
		setCountOpenTooltip(prev => prev + 1);
	};

	const handleClickAIRubricCreation = async () => {
		const projectRubric = currentProjectRubricList[currentSelectedProjectRubricIdx];
		if (!projectRubric) return;

		const rubricName = projectRubric.name;
		const itemCount = projectRubric.items?.length;
		if (!rubricName) {
			await alert(i18next.t("과제 평가 기준표에 '평가 항목'을 입력하셔야 합니다."));
			return;
		}
		if (!questionInfo?.rag) {
			await alert(i18next.t("참고 자료 등록 단계에서 등록된 자료가 없습니다. 등록 후 재실행해 주세요."));
			return;
		}
		if (!questionInfo?.correctedAnswer) {
			await alert(i18next.t("모범 답안 입력 단계에서 입력된 모범 답안이 없습니다. 입력 후 재실행해 주세요."));
			return;
		}

		setRequestingAIRubric(true);

		await projectRubricStore.createAIRubric(questionInfo.projectExamCode, questionInfo.projectExamQuestionNum, rubricName, itemCount, navigateStore.language)
			.then(aiRubricInfo => {
				if (!aiRubricInfo.id) return;

				getAIRubricId.current = setInterval(() => getAIRubric(aiRubricInfo.id, projectRubric), 5000);
			})
			.catch(async e => {
				await alert(i18next.t("AI Rubric 생성에 실패했습니다."));
				console.log("Cannot fail requesting AI rubric at createAIRubric...", e);
				setRequestingAIRubric(false);
			})
	};

	React.useEffect(() => {
		if (currentProjectRubricList.length === 0 || countCheckingAiRubricWhenMounted > 0) return;
		if (currentProjectRubricList.length === 1 && currentProjectRubricList[0].name === '') return; // 과제평가 기준표를 작성하지 않고 비어있는 기준표 하나만 있는 경우

		setCountCheckingAiRubricWhenMounted(1);
		checkAiRubricProcessing(currentProjectRubricList);

	}, [currentProjectRubricList]);

	const checkAiRubricProcessing = async (projectRubricList) => {
		projectRubricStore.getAIRubricByExamCodeAndQuestionNum(questionInfo.projectExamCode, questionInfo.projectExamQuestionNum)
			.then(aiRubricInfoArr => {
				if (aiRubricInfoArr.length === 0) return;

				let isAiProcessing = false;
				let processingRubricInfo;

				aiRubricInfoArr.map(rubricInfo => {
					if (rubricInfo?.aiStatus !== AIStatusType.PROGRESS) return;

					isAiProcessing = true;
					processingRubricInfo = rubricInfo;
				})

				if (!isAiProcessing) return;

				setRequestingAIRubric(true);

				let selectedIdx = projectRubricList.findIndex(r => r?.name === processingRubricInfo?.name);

				let projectRubric;

				if (selectedIdx < 0) return;

				projectRubric = projectRubricList[selectedIdx];

				projectRubricStore.changeQuestionKeyAndSelectedProjectRubricIdxMap(questionInfo.key, selectedIdx, true);

				getAIRubric(processingRubricInfo.id, projectRubric);
				getAIRubricId.current = setInterval(() => getAIRubric(processingRubricInfo.id, projectRubric), 5000);
			})
	};

	const getAIRubric = async (rubricId, projectRubric) => {
		try {
			await projectRubricStore.getAIRubricByRubricId(rubricId)
				.then(async result => {
					if (result.aiStatus === AIStatusType.COMPLETED) {
						if (result.items && result.items.length > 0) {
							let itemNumArr = [];
							result.items.map(item => {
								itemNumArr.push(item.rubricItemNum);
							});
							itemNumArr.sort((a, b) => b - a);

							const rubricKey = projectRubric.key;
							if (!rubricKey) return;

							result.items
								.sort((a, b) => a.rubricItemNum - b.rubricItemNum)
								.map((item, idx) => {
									const itemNum = itemNumArr[idx];
									projectRubricStore.changeProjectRubricItemStandard(rubricKey, itemNum, item.standard);
								})
						}

						getAIRubricId.current && clearInterval(getAIRubricId.current);
						setRequestingAIRubric(false);
					}

					if (result.aiStatus === AIStatusType.FAILED) {
						getAIRubricId.current && clearInterval(getAIRubricId.current);
						setRequestingAIRubric(false);
						await alert(i18next.t("AI Rubric 생성에 실패했습니다."));
						console.log("Cannot fail requesting AI rubric by status 'failed' at getAIRubric...");

					}
				})
		} catch (e) {
			getAIRubricId.current && clearInterval(getAIRubricId.current);
			await alert(i18next.t("AI Rubric 생성에 실패했습니다."));
			console.log("Cannot fail requesting AI rubric by unknown error at getAIRubric ...", e);
			setRequestingAIRubric(false);
		}
	};

	const handleClickDelete = async (order) => {
		const result = await confirm(i18next.t("과제 평가 기준표 {{order}}(을)를 삭제하시겠습니까?", { order }), i18next.t("과제 평가 기준표 삭제"));
		if (!result) return;
		if (!currentProjectRubricList
			|| currentSelectedProjectRubricIdx < 0
			|| !currentProjectRubricList[currentSelectedProjectRubricIdx]?.key) return;

		const rubricKey = currentProjectRubricList[currentSelectedProjectRubricIdx]?.key;

		projectRubricStore.deleteProjectRubricByKey(questionInfo, rubricKey);
	};

	const handleSetTooltipAnchorEl = () => {
		if (anchorRef.current) {
			setAnchorEl(anchorRef.current);
		}
	};

	const handleMouseEnterInTooltip = () => {
		handleSetTooltipAnchorEl();
	};

	const handleMouseLeaveFromTooltip = () => {
		if (openTooltipWhenMount) return;

		setAnchorEl(null);
	};

	const handleCloseTooltipWhenMount = () => {
		setOpenTooltipWhenMount(false);
		setAnchorEl(null);
	};

	const handleClickTooltipCloseBtn = () => {
		handleCloseTooltipWhenMount();
	};

	const handleEnterAiBtn = () => {
		setIsMouseEnteringAiBtn(true);
	};

	const handleLeaveFromAiBtn = () => {
		setIsMouseEnteringAiBtn(false);
	};

	const openTooltipAboutAiBtn = React.useMemo(() => {
		return (isMouseEnteringAiBtn && !requestingAIRubric);
	}, [isMouseEnteringAiBtn, requestingAIRubric]);


	// kitty
	const rowRef = React.useRef(null);
	React.useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				// 요소가 화면에 50% 이상 보일 때 콜백 호출
				if (entry.isIntersecting) {
					props.onVisibilityChanged(questionIdx, true);
					// onVisibilityChange(index, true);
				} else {
					props.onVisibilityChanged(questionIdx, false);
				}
			},
			{
				threshold: 0.2 // 50% 이상 보일 때 트리거
			}
		);

		if (rowRef.current) {
			observer.observe(rowRef.current);
		}

		return () => {
			if (rowRef.current) {
				observer.unobserve(rowRef.current);
			}
		};
	}, [ref, questionIdx, props.onVisibilityChanged]);

	const handleSave = async () => {
		setIsExampleAnswerSaving(true);
		await projectExamStore.updateCorrectedAnswer(questionInfo.projectExamCode, questionInfo.projectExamQuestionNum, editedExampleAnswer);
		setIsExampleAnswerSaving(false);
		setIsExampleAnswerEditing(false);
	};

	return (
		<Box id={`question-${currentOrder}`} ref={ref} className={(questionIdx === shownDetailIdx) ? classes.rubricBox : clsx(classes.rubricBox, classes.rubricBoxOff)}>
			<Box ref={rowRef} className={openMorePage ? classes.rubricTaskBox : clsx(classes.rubricTaskBox, classes.rubricTaskBoxOff)}>
				<Box className={classes.rubricShownBox}>

					<div style={{ overflow: "hidden" }}>
						<Box className={clsx(classes.rubricQuestionViewer, classes.rubricMarginBottom)}>
							<TextViewer
								currentHTML={currentQuestionHTML}
							/>
						</Box>
					</div>
					<Box className={classes.btnBox}>
						{requestingAIRubric &&
							<Box className={classes.aiSpinner}>
								<AIAnswerBtnLoad />
							</Box>
						}
						<Box>
							{openMorePage ?
								<IconButton
									className={classes.iconBtnReset}
									onClick={handleClickMoreOver}
									disableRipple>
									<MorePage />
								</IconButton> :
								<IconButton
									className={classes.closedIconBtnReset}
									onClick={handleClickMoreOver}
									disableRipple>
									<CloseMorePage />
								</IconButton>
							}
						</Box>
					</Box>
				</Box>
				{openMorePage &&
					<>
						<Box className={clsx(classes.rubricQuestionViewer, classes.rubricMarginTop)}>
							<TextViewer
								currentHTML={questionInfo.reference}
							/>
							<Typography sx={{ 
								color: currentProjectRubricList.reduce((total, item) => total + item.score, 0) === questionInfo.score 
									? '#008c73'  // Green when scores match
									: '#F31825', // Red when scores don't match
								fontWeight: 'bold',
								display: 'flex',
								gap: 1,
								fontSize: '1.25rem',  // Increased from default
								alignItems: 'center'  // To ensure proper vertical alignment
							}}>
								<span>{currentProjectRubricList.reduce((total, item) => total + item.score, 0)}</span>
								<span>/</span>
								<span>{questionInfo.score}</span>
							</Typography>
						</Box>

						<Box className={classes.taskBox}>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
								<Typography>모범 답안(선택)</Typography>
								{isExampleAnswerEditing && (
									<Button
										onClick={handleSave}
										disabled={!isExampleAnswerEditing || isExampleAnswerSaving}
										sx={{
											backgroundColor: '#008C73',
											color: 'white',
											'&:hover': {
												backgroundColor: '#007B64',
											},
											'&:disabled': {
												backgroundColor: '#CCCCCC',
											},
											minWidth: '80px',
											height: '32px'
										}}
									>
										{isExampleAnswerSaving ? (
											<CircularProgress size={20} color="inherit" />
										) : (
											'저장'
										)}
									</Button>
								)}
							</Box>
							<TextField
								multiline
								minRows={5}
								variant="outlined"
								fullWidth
								value={editedExampleAnswer}
								onChange={(e) => {
									setEditedExampleAnswer(e.target.value);
									setIsExampleAnswerEditing(true);
								}}
							/>
						</Box>
						<Box display='flex' alignItems='center' justifyContent='left' className={classes.rubricInfoText}>
							<RubricInfoIcon />
							<Typography>{i18next.t("문항에 작성된 모범 답안입니다.")}</Typography>
						</Box>
					</>
				}
			</Box>

			{openMorePage &&
				<>
					<Box className={classes.standardBox}>
						<Box>
							<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ padding: '0px 20px 10px' }}>
								<IconButton
									className={classes.iconBtnReset}
									disabled={disableToMoveToPrevPublicRubric}
									onClick={handleClickMovePrevItem}
									disableRipple>
									{disableToMoveToPrevPublicRubric ?
										<ChevronLeftRubricOff /> :
										<ChevronLeftRubric />
									}
								</IconButton>

								<Box display='flex' alignItems='center' justifyContent='center'>
									<Typography className={classes.taskTableTitle}>
										{i18next.t("과제평가 기준표")}{currentSelectedProjectRubricIdx + 1}
									</Typography>
									{!requestingAIRubric && currentProjectRubricList.length > 1 &&
										<Tooltip
											title={i18next.t("과제평가 기준표 삭제")}
											classes={{ tooltip: classes.deleteTooltip }}
										>
											<IconButton
												className={clsx(classes.iconBtnReset, classes.titleTrashIcon)}
												onClick={() => handleClickDelete(currentSelectedProjectRubricIdx + 1)}
												disableRipple>
												<TrashDeleteIcon />
											</IconButton>
										</Tooltip>
									}
								</Box>
								<IconButton
									className={classes.iconBtnReset}
									disabled={disableToMoveToNextPublicRubric}
									onClick={handleClickMoveNextItem}
									disableRipple>
									{disableToMoveToNextPublicRubric ?
										<ChevronRightRubricOff /> :
										<ChevronRightRubric />
									}
								</IconButton>
							</Box>

							{currentProjectRubricList.length > 0 &&
								currentProjectRubricList[currentSelectedProjectRubricIdx]?.items?.length > 0 &&
								<ProjectRubricTable
									questionIdx={questionIdx}
									rubricInfo={currentProjectRubricList[currentSelectedProjectRubricIdx]}
									questionInfo={questionInfo}
									mounted={mounted}
									handleSetMountedFalse={handleSetMountedFalse}
									countOpenTooltip={countOpenTooltip}
									handleIncreaseCountOpenTooltip={handleIncreaseCountOpenTooltip}
									requestingAIRubric={requestingAIRubric}
								/>
							}

							<Box className={clsx(classes.pageNumber, classes.pageNumberBorder)} display='flex' alignItems='center' justifyContent='center'>
								{!requestingAIRubric &&
									<Stack spacing={2}>
										<Pagination count={currentProjectRubricList.length}
											page={Number(currentSelectedProjectRubricIdx) + 1}
											boundaryCount={2}
											showFirstButton
											showLastButton
											onChange={handleClickPublicRublicPage}
											variant="outlined"
										/>
									</Stack>
								}
							</Box>

							<Box className={classes.btnListBox}>
								{!requestingAIRubric &&
									<>
										<Box className={classes.addTableBtnBox}>
											<Button
												// display='flex'
												sx={{ display: 'flex' }}
												disableRipple
												onClick={handleClickAddTableBtn}
												className={classes.addTableBtn}>
												<AddTableIcon />
												<Typography><span>{currentOrder}</span>{i18next.t("번 문항")} <span>{i18next.t("평가 항목 (기준표) 추가")}</span></Typography>
											</Button>
										</Box>
									</>
								}

								{globalConfig.useRag && (
									<Box className={classes.addTableBtnBox}>
										<Tooltip
											title={
												<>
													<Box display={'flex'} justifyContent={'space-between'} className={clsx(classes.tooltipBtn, classes.aiRubricSupportTooltipBtn)}>
														<Box>
															<Typography className={clsx(classes.tooltipTitle, classes.scoreTooltipTitle)}>{i18next.t("AI 평가 기준표 생성")}</Typography>
														</Box>
													</Box>
													<Typography className={classes.tooltipText}>
														{i18next.t("문항, 참고 자료, 모범 답안 및 작성한 과제 평가 기준표(평가항목명, 배점 척도)를 기반으로 AI 시스템이 평가 기준표를 작성합니다. AI 기반 기준표를 사용하거나 사용자가 편집할 수도 있습니다.")}
													</Typography>
												</>
											}
											open={openTooltipAboutAiBtn}
											classes={{ tooltip: classes.aiRubricTooltip }}>
											<Button
												// display='flex'
												sx={{ display: 'flex' }}
												ref={anchorRef}
												disableRipple
												disabled={requestingAIRubric}
												className={classes.aiStandCreateBtn}
												onClick={requestingAIRubric ? null : handleClickAIRubricCreation}
												onMouseEnter={handleEnterAiBtn}
												onMouseLeave={handleLeaveFromAiBtn}
											>
												{
													requestingAIRubric ?
														<Typography>{i18next.t("AI 평가 기준표 생성 중")}</Typography> :
														<Box className={classes.aiStandCreateTextBox}><Typography>{i18next.t("AI 평가 기준표 생성")}</Typography><AIStandardBtnIcon /></Box>
												}
											</Button>
										</Tooltip>
									</Box>
								)}
							</Box>

						</Box>
					</Box>


				</>
			}
		</Box>
	)
};
