import React from "react";

import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import { observer } from "mobx-react";
import * as XLSX from "xlsx";
import { ReactComponent as CheckListSave } from "../common/images/CheckListSave.svg";
import { ReactComponent as DialogClose } from "../common/images/DialogClose.svg";
import { ReactComponent as GroupDeleteIcon } from "../common/images/GroupDeleteIcon.svg";
import { ReactComponent as InformationRed } from "../common/images/InformationRed.svg";
import { ReactComponent as SelectIcon } from "../common/images/SelectIcon.svg";
import { ReactComponent as WarningTableIcon } from "../common/images/WarningTableIcon.svg";
import useEmotionStyles from "../views/main/styles/useEmotionStyles";
import { ManageStudentStlye } from "../views/main/styles/ManageStudentStlye";

// import cloneDeep from "lodash/cloneDeep";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useStores } from "../hooks/useStores";
import { ProjectSchoolType } from "../stores/enums/ProjectStoreEnums";

type Props = {
	onClose: () => void,
	open: boolean,

}

function CreateBatchSTDialog(props: Props) {
	const classes = useEmotionStyles(ManageStudentStlye);
	const { onClose, open } = props;

	const hiddenFileInput = React.useRef(null);
	const dialogContentRef = React.useRef(null);

	const [sido, setSido] = React.useState("DEFAULT");
	const [siGunGoo, setSiGunGoo] = React.useState("DEFAULT");
	const [schoolType, setSchoolType] = React.useState("DEFAULT");
	const [school, setSchool] = React.useState("");
	const [studentList, setStudentList] = React.useState([]);
	const [fileName, setFileName] = React.useState("");
	const [localStNumDuplicate, setLocalStNumDuplicate] = React.useState(false);
	const [localEmailDuplicate, setLocalEmailDuplicate] = React.useState(false);
	const [localEmailFormBad, setLocalEmailFormBad] = React.useState(false);

	const theme = useTheme();
	const i18next = useTranslation();

	// 테이블에 보여지는 용도
	const [currentPage, setCurrentPage] = React.useState(1);
	const rowsPerPage = 5;

	// 현재 페이지에 해당하는 데이터 계산
	const indexOfLastRow = currentPage * rowsPerPage;
	const indexOfFirstRow = indexOfLastRow - rowsPerPage;
	const currentRows = studentList.slice(indexOfFirstRow, indexOfLastRow);

	const pageCount =
		studentList.length && studentList.length > 0
			? studentList.length > rowsPerPage
				? Math.ceil(studentList.length / rowsPerPage)
				: 1
			: 0;

	const { navigateStore, userStore } = useStores();

	const {
		checkedEmails,
		sidoList,
		stDuplicated,
		backdropOpen,
		siGunGooList,
		checkedStNumList,
		emailDuplicated,
		canSaveBatch,
	} = userStore;

	const handleClose = () => {
		onClose();
	};

	const handleChangePage = (event, newPage) => {
		setCurrentPage(newPage);
	};

	React.useEffect(() => {
		userStore.getSido({
			region: navigateStore.region,
		});

		return () => {
			userStore.initUserInfo();
		};
	}, []);

	React.useEffect(() => {
		if (studentList && studentList.length > 0) {
			dialogContentRef.current.scrollTop = dialogContentRef.current.scrollHeight;
		}
	}, [studentList]);

	React.useEffect(() => {
		if (canSaveBatch || stDuplicated || emailDuplicated) {
			dialogContentRef.current.scrollTop = dialogContentRef.current.scrollHeight;
		}
	}, [canSaveBatch, stDuplicated, emailDuplicated]);

	const handleSelectSido = (event) => {
		setSido(event.target.value);
		userStore.initCheckedStNums();
		// 시군구 날리기
		setSiGunGoo("DEFAULT");
		userStore.getSiGunGoo({
			ctprvnCd: event.target.value,
			region: navigateStore.region,
		});
	};

	const handleSelectSiGunGoo = (event) => {
		setSiGunGoo(event.target.value);
		userStore.initCheckedStNums();
	};

	const handleSelectSchoolType = (event) => {
		setSchoolType(event.target.value);
		userStore.initCheckedStNums();
	};

	const handleChangeSchoolName = (event) => {
		setSchool(event.target.value);
		userStore.initCheckedStNums();
	};

	const downloadExcelSample = () => {
		const data = [
			[
				i18next.t("이메일(@ 앞부분만 입력)"),
				i18next.t("이름"),
				i18next.t("학년"),
				i18next.t("반"),
				i18next.t("번호"),
			], // 헤더
		];

		// 워크시트 생성
		const ws = XLSX.utils.aoa_to_sheet(data);

		// 워크북 생성
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

		// 엑셀 파일 생성 및 다운로드
		XLSX.writeFile(wb, "sample.xlsx");
	};

	const handleClickExcelUpload = () => {
		handleClickUploadClear();
		hiddenFileInput.current.click();
	};

	const readExcel = (event) => {
		const file = event.target.files[0];

		if (!file) {
			return;
		}

		setFileName(file.name);

		const reader = new FileReader();
		reader.onload = (e) => {
			const workbook = XLSX.read(e.target.result, { type: "binary" });
			const worksheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[worksheetName];
			const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

			const convertedList = data.slice(1)
					.filter(user => user[0] && user[1] && user[2] && user[3] && user[4])
					.map(([email, name, grade, className, number]) => ({
						email,
						name,
						grade,
						className,
						number,
						duplicate: false,
						emailDuplicate: false,
						emailFormGood: true,
			}));


			const checkedEmailForm = checkEmailForm(convertedList);

			const checkedEmailDuplicate = checkEmailLocal(checkedEmailForm);

			setStudentList(checkStNumLocal(checkedEmailDuplicate));
		};
		reader.readAsBinaryString(file);

		event.target.value = null;
	};

	const convertGradeInfoForView = (user) => {
		const grade = user.grade;
		const className = user.className;
		const number = user.number;

		const text = i18next.t("{{grade}}학년 {{className}}반 {{number}}번", {
			grade,
			className,
			number: number ? number : "",
		});
		return text;
	};

	const handleClickUploadClear = () => {
		// 엑셀 업로드 관련값 모두 초기화
		setFileName("");
		setStudentList([]);
		setLocalStNumDuplicate(false);
		setLocalEmailFormBad(false);
		setCurrentPage(1);

		// TODO : 중복 체크 관련 값 초기화 로직 추가 필요
		userStore.initCheckedDatas();
	};

	const checkStNumLocal = (list) => {
		setLocalStNumDuplicate(false); // 초기화

		const checkDuplication = {};

		list.forEach((user) => {
			const key = `${user.grade}-${user.className}-${user.number}`;

			user.duplicate = false; // 초기화

			if (checkDuplication[key]) {
				user.duplicate = true;
				checkDuplication[key].duplicate = true; // 최초 중복 항목 에도 표시
				setLocalStNumDuplicate(true);
			} else {
				checkDuplication[key] = user;
			}
		});

		console.log("checkStNumLocal results : ", list);

		return list;
	};

	const checkEmailForm = (list) => {
		setLocalEmailFormBad(false); // 초기화

		list.forEach((user) => {
			const startWordIsGood = checkEmailStartWord(user.email);
			const noAt = checkNotIncludeAt(user.email);
			const lengthGood = checkEmailLength(user.email);

			user.emailFormGood = startWordIsGood && noAt && lengthGood;
			if (!user.emailFormGood) {
				setLocalEmailFormBad(true);
			}
		});

		return list;
	};

	const checkEmailLocal = (list) => {
		setLocalEmailDuplicate(false); // 초기화

		const checkDuplication = {};

		list.forEach((user) => {
			const key = user.email;

			user.emailDuplicate = false; // 초기화

			if (checkDuplication[key]) {
				user.emailDuplicate = true;
				checkDuplication[key].emailDuplicate = true; // 최초 중복 항목 에도 표시
				setLocalEmailDuplicate(true);
			} else {
				checkDuplication[key] = user;
			}
		});

		return list;
	};

	const checkEmailStartWord = (email) => {
		return /^\b[a-z]/.test(email);
	};

	const checkNotIncludeAt = (email) => {
		return !email.includes("@");
	};

	const checkEmailLength = (email) => {
		return !!(email.length >= 4 && email.length <= 20);
	};

	const handleClickCheckDuplication = () => {
		// 1. 이메일 중복 체크
		// 2. stNum 중복 체크
		const emailList = studentList.map((user) => user.email);
		const datas = convertForStNumCheck();

		userStore.initBatchCheckResult(); // 중복체크 값 초기화(이전 결과가 있는경우 떄문)
		userStore.checkEmails(emailList, true, datas);
	};

	// 학년 반 번호 체크
	const readyForStNumCheck = () => {
		let ready = true;
		if (!navigateStore.simpleMode) {
			ready = ready && (
				sido !== "DEFAULT"
				&& siGunGoo !== "DEFAULT"
				&& schoolType !== "DEFAULT"
			);
		}
/*
		ready = ready &&
			(school !== null &&
				school !== ""
			);
			*/
			
		return ready;
	};

	const convertForStNumCheck = () => {
		return studentList.map((user) => {
			return {
				city: sido,
				country: siGunGoo,
				schoolType: schoolType,
				schoolName: school,
				grade: user.grade,
				className: user.className,
				number: user.number,
			};
		});
	};

	const handleClickSaveBatch = () => {
		const datas = studentList.map((user) => {
			return {
				email: user.email,
				name: user.name,
				city: sido,
				country: siGunGoo,
				schoolType: schoolType,
				schoolName: school,
				grade: user.grade,
				className: user.className,
				number: user.number,

				//
				furigana: user.furigana || "",

				lastName: user.lastName || "",
				firstName: user.firstName || "",
				lastFurigana: user.lastFurigana || "",
				firstFurigana: user.firstFurigana || "",
			};
		});

		userStore.saveMyUsers(datas);
	};

	const handleClickDeleteStudent = (user, index) => {
		// 1. 항목 삭제
		const offset = indexOfFirstRow;
		const destination = offset + index;
		setStudentList((prev) => {
			const processList = compose(
				checkEmailForm,
				checkEmailLocal,
				checkStNumLocal
			);

			const list = prev.filter((_, i) => i !== destination);

			return processList(list);
		});

		let newPageCount =
			studentList.length && studentList.length - 1 > 0
				? studentList.length - 1 > rowsPerPage
					? Math.ceil((studentList.length - 1) / rowsPerPage)
					: 1
				: 0;

		if (newPageCount < currentPage) {
			setCurrentPage(newPageCount);
		}

		userStore.initCanSaveBatch(); // 계정 중복 체크(백엔드) 관련 값 초기화 -> 무조건 다시 해야함.
	};

	const compose = (...functions) => (data) =>
		functions.reduce((value, func) => func(value), data);

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
			className={classes.dataOpenDialog}
		>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				className={classes.dialogTop}
			>
				<Typography>{i18next.t("학생 일괄 등록")}</Typography>
				<IconButton className={classes.iconBtnReset} disableRipple onClick={handleClose}>
					<DialogClose />
				</IconButton>
			</Box>
			<Box ref={dialogContentRef} className={classes.dialogContent}>
				<Box className={classes.stepCont}>
					<Typography className={classes.stepNumber}>1</Typography>
					<Typography className={classes.listBoxTitleEach}>
						{i18next.t("학교 입력")}
					</Typography>
				</Box>
				<Box className={classes.borderBox}>
					<Typography className={classes.listBoxTitleSearch}>
						{i18next.t("학교 찾기")}
					</Typography>
					{(!navigateStore.simpleMode) && <Box>
						<FormControl className={clsx(classes.dialogInput, classes.area1_batch)}>
							<Select
								IconComponent={(props) => (
									<Box>
										<SelectIcon {...props} />
									</Box>
								)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "right",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "right",
									},
									// getContentAnchorEl: null,
									className: classes.selectPopover,
								}}
								value={sido}
								onChange={handleSelectSido}
							>
								<MenuItem value="DEFAULT">
									<em>{i18next.t("시•도 선택")}</em>
								</MenuItem>
								{sidoList.map((sido, index) => (
									<MenuItem id={`sido-list-${index}`} key={index} value={sido.ctprvnCd}>
										{sido.ctprvnNm}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={clsx(classes.dialogInput, classes.area2_batch)}>
							<Select
								IconComponent={(props) => (
									<Box>
										<SelectIcon {...props} />
									</Box>
								)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "right",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "right",
									},
									// getContentAnchorEl: null,
									className: classes.selectPopover,
								}}
								value={siGunGoo}
								onChange={handleSelectSiGunGoo}
							>
								<MenuItem value="DEFAULT">
									<em>{i18next.t("시•군•구 선택")}</em>
								</MenuItem>
								{siGunGooList.map((siGunGoo, index) => (
									<MenuItem id={`siGunGoo-list-${index}`} key={index} value={siGunGoo.signguCd}>
										{siGunGoo.signguNm}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={clsx(classes.dialogInput, classes.area3_batch)}>
							<Select
								IconComponent={(props) => (
									<Box>
										<SelectIcon {...props} />
									</Box>
								)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "right",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "right",
									},
									// getContentAnchorEl: null,
									className: classes.selectPopover,
								}}
								value={schoolType}
								onChange={handleSelectSchoolType}
							>
								<MenuItem value="DEFAULT">
									<em>{i18next.t("학교급 선택")}</em>
								</MenuItem>
								<MenuItem value={ProjectSchoolType.ELEM}>
									{i18next.t("초등학교")}
								</MenuItem>
								<MenuItem value={ProjectSchoolType.MIDD}>
									{i18next.t("중학교")}
								</MenuItem>
								<MenuItem value={ProjectSchoolType.HIGH}>
									{i18next.t("고등학교")}
								</MenuItem>
								<MenuItem value={ProjectSchoolType.NOGRADE}>
									{i18next.t("해당 없음")}
								</MenuItem>
							</Select>
						</FormControl>
					</Box>
					}
					<Box className={classes.textFieldBox} sx={{ marginTop: "10px" }}>
						<TextField
							id="outlined-basic"
							variant="outlined"
							placeholder={i18next.t("학교 입력")}
							value={school}
							onChange={handleChangeSchoolName}
							className={clsx(classes.dialogInput, classes.dialogInputSchool)}
						/>
						{/*TODO : 추후에 검색 API 붙여야 함*/}
						{/*<Button className={classes.blackBtn} disableRipple>*/}
						{/*    <Typography>검색</Typography>*/}
						{/*</Button>*/}
					</Box>
				</Box>

				<Box className={classes.stepContBtn}>
					<Box className={classes.fileTitle}>
						<Typography className={classes.stepNumber}>2</Typography>
						<Typography className={classes.listBoxTitleEach}>
							{i18next.t("엑셀 파일을 다운로드")}
						</Typography>
					</Box>
					<Button className={classes.fileBtn} onClick={downloadExcelSample} disableRipple>
						<Typography>{i18next.t("엑셀 파일 다운로드")}</Typography>
					</Button>
				</Box>
				<Box className={classes.stepCont}>
					<Typography className={classes.stepNumber}>3</Typography>
					<Typography className={classes.listBoxTitleEach}>
						{i18next.t("아래 규칙에 맞게 엑셀에 입력")}
					</Typography>
				</Box>
				<Box className={classes.borderBox}>
					<Typography className={classes.greenTitle}>
						<CheckListSave />
						{i18next.t("이메일")}
					</Typography>
					<Typography className={classes.guideTextList}>
						{i18next.t("- 앞부분만 입력")}
						<br />
						{i18next.t(
							"- 4 ~ 20자의 영문 소문자, 점(.), 하이픈(-) 포함 가능"
						)}
						<br />
						{i18next.t(
							"- 영문, 숫자, 특수문자를 혼용할 수 있으나 첫 글자는 반드시 영문으로 시작"
						)}
					</Typography>
					<br />
					<Typography className={classes.greenTitle}>
						<CheckListSave />
						{i18next.t("이름")}
					</Typography>
					<Typography className={classes.guideTextList}>
						{i18next.t("- 최대 50자")}
					</Typography>
					<br />
					<Typography className={classes.greenTitle}>
						<CheckListSave />
						{i18next.t("학년 / 학반 / 학생 반번호")}
					</Typography>
					<Typography className={classes.guideTextList}>
						{i18next.t("- 1~99까지 숫자만 입력 가능")}
						<br />
						{i18next.t(
							"- 학생 반번호: 중복 불가하므로 필요 시 수정 필요"
						)}
					</Typography>
					<br />
					<Typography className={classes.redTitle}>
						<InformationRed />
						{i18next.t("비밀번호")}
					</Typography>
					<Typography className={classes.guideTextListRed}>
						{i18next.t("* 시스템에서 자동 부여하므로 입력 불가")}
						<br />
						{i18next.t("* 초기 비밀번호는 이메일 주소 아이디와 동일")}
						<br />
						{i18next.t(
							"* 학생은 로그인하여 비밀번호를 수정 후 사용 가능"
						)}
						<br />
					</Typography>
				</Box>
				<Box className={classes.stepContBtn}>
					<Box>
						<Box className={classes.stepCont}>
							<Typography className={classes.stepNumber}>4</Typography>
							<Typography className={classes.listBoxTitleEach}>
								{i18next.t("엑셀 파일 업로드")}
							</Typography>
						</Box>
						<Typography className={classes.nexStepText}>
							{i18next.t("입력한 파일을 업로드 합니다.")}
						</Typography>
					</Box>
					<input
						type="file"
						accept={".xlsx, .xls"}
						hidden
						ref={hiddenFileInput}
						onChange={readExcel}
						style={{ display: "none" }} // input 요소 숨기기
					/>
					<Box>
						<Button
							className={classes.fileBtn}
							// type={"input"}
							onClick={handleClickExcelUpload}
							disableRipple
						>
							<Typography>{i18next.t("엑셀 파일 업로드")}</Typography>
						</Button>
					</Box>
				</Box>
				<Box className={classes.deleteTextBoxEdit}>
					<Typography className={classes.textBoxName}>{fileName}</Typography>
					{/*<Typography className={classes.boxNoticeText}>선택 결과 학교명이 여기에 표시됩니다.</Typography>*/}
					<IconButton
						className={classes.iconBtnReset}
						onClick={handleClickUploadClear}
						disableRipple
					>
						<GroupDeleteIcon />
					</IconButton>
				</Box>

				<Box className={classes.stepContFive}>
					<Typography className={clsx(classes.stepNumber, classes.stepNumberLast)}>
						5
					</Typography>
					<Typography className={classes.listBoxTitleEach}>
						{i18next.t("등록 결과 확인하고 수정")}
					</Typography>
				</Box>
				<Typography className={classes.nexStepText}>
					{i18next.t("다음 단계에서 확인할 수 있습니다.")}
				</Typography>

				<Box className={classes.tableInner}>
					<Box className={classes.tableBoxTopBtn}>
						<Typography>
							{i18next.t("총")} <span>{studentList.length}</span>
							{i18next.t("건")}
						</Typography>
						<Button
							className={classes.duplicationCheckBt}
							disabled={
								studentList.length === 0 ||
								localStNumDuplicate ||
								localEmailFormBad ||
								!readyForStNumCheck()
							}
							onClick={handleClickCheckDuplication}
							disableRipple
						>
							<Typography>{i18next.t("계정 중복 체크")}</Typography>
						</Button>
					</Box>
					<TableContainer component={Paper} className={classes.tableBox}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell width="12%" className={classes.tableNoBox}>
										No.
									</TableCell>
									<TableCell width="22%">{i18next.t("학년반/번호")}</TableCell>
									<TableCell width="19%">{i18next.t("이름")}</TableCell>
									<TableCell width="37%">{i18next.t("이메일")}</TableCell>
									<TableCell width="10%">{i18next.t("삭제")}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{currentRows &&
									currentRows.length > 0 &&
									currentRows.map((user, index) => {
										const checkedEmail = checkedEmails.find(
											(data) => data.email === user.email
										);
										const keyValue = `${user.grade}-${user.className}-${user.number}`;
										const checkedUser = checkedStNumList.find(
											(data) => `${data.grade}-${data.className}-${data.number}` === keyValue
										);
										return (
											<TableRow
												id={`batch-upload-list-${index}`}
												key={index}
												sx={{ "&:last-child .MuiTableCell-body": { border: "0px !important" } }}
											>
												<TableCell className={classes.tableNoBox}>
													{(index + 1) + (currentPage - 1) * rowsPerPage}
												</TableCell>
												<TableCell>
													<Typography
														style={
															user.duplicate
																? { color: "#d92020", fontSize: "0.875rem" }
																: checkedUser?.stNumDuplicate
																	? { color: "#d92020", fontSize: "0.875rem" }
																	: { fontSize: "0.875rem" }
														}
													>
														{convertGradeInfoForView(user)}
													</Typography>
												</TableCell>
												<TableCell>{user.name}</TableCell>
												<TableCell>
													<Typography
														style={
															!user.emailFormGood
																? { fontSize: "0.875rem", color: "#d2bb4d" }
																: user.emailDuplicate
																	? { fontSize: "0.875rem", color: "#d92020" }
																	: checkedEmail?.duplicate
																		? { fontSize: "0.875rem", color: "#d92020" }
																		: { fontSize: "0.875rem" }
														}
													>
														{user.email}
													</Typography>
												</TableCell>
												<TableCell>
													<IconButton
														className={classes.iconBtnReset}
														onClick={() => handleClickDeleteStudent(user, index)}
														disableRipple
													>
														<GroupDeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								{currentRows.length !== 0
									? currentRows.length < rowsPerPage &&
									Array.from({ length: rowsPerPage - currentRows.length }, (_, index) => (
										<TableRow id={`batch-list-empty-${index}`} key={index}>
											<TableCell colSpan={5} style={{ border: 0 }}>
												&nbsp;
											</TableCell>
										</TableRow>
									))
									: (
										<TableRow id={`batch-list-empty`} key={0}>
											<TableCell colSpan={5} style={{ border: 0 }}>
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
													<Typography>{i18next.t("엑셀을 업로드 해주세요.")}</Typography>
												</Box>
											</TableCell>
										</TableRow>
									)}
							</TableBody>
						</Table>
					</TableContainer>

					<Box className={clsx(classes.pageNumber, classes.pageNumberBorder)}>
						<Stack spacing={2}>
							<Pagination
								count={pageCount}
								page={currentPage}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleChangePage}
								variant="outlined"
							/>
						</Stack>
					</Box>

					{/*{emailDuplicated &&*/}
					<Box
						className={classes.tableNoticeText}
						style={!emailDuplicated ? { display: "none" } : {}}
					>
						<WarningTableIcon />
						<Typography style={{ color: "#d92020" }}>
							{i18next.t("이메일 중복된 계정이 있습니다. 다시 입력해 주세요.")}
						</Typography>
					</Box>
					{/*}*/}

					{/*{localEmailDuplicate &&*/}
					<Box
						className={classes.tableNoticeText}
						style={!localEmailDuplicate ? { display: "none" } : {}}
					>
						<WarningTableIcon />
						<Typography style={{ color: "#d92020" }}>
							{i18next.t("파일내 중복된 계정이 있습니다. 다시 입력해 주세요.")}
						</Typography>
					</Box>
					{/*}*/}
					{/*{localEmailFormBad &&*/}
					<Box
						className={classes.tableNoticeText}
						style={!localEmailFormBad ? { display: "none" } : {}}
					>
						<WarningTableIcon />
						<Typography style={{ color: "#d2bb4d" }}>
							{i18next.t("올바르지 않은 이메일 형식이 있습니다. 다시 입력해 주세요.")}
						</Typography>
					</Box>
					{/*}*/}
					{/*{localStNumDuplicate || stDuplicated &&*/}
					<Box
						className={classes.tableNoticeText}
						style={localStNumDuplicate || stDuplicated ? {} : { display: "none" }}
					>
						<WarningTableIcon />
						<Typography style={{ color: "#d92020" }}>
							{i18next.t("학년/반/번호 가 중복된 계정이 있습니다. 다시 입력해 주세요.")}
						</Typography>
					</Box>
					{/*}*/}
					<Box className={classes.tableNoticeText} style={canSaveBatch ? {} : { display: "none" }}>
						{/*<WarningTableIcon />*/}
						<Typography style={{ color: "#2377cc", fontSize: 16, fontWeight: 700 }}>
							{i18next.t("모든 학생 정보가 확인 되었습니다.")}
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				className={classes.dialogBtnGroup}
			>
				<Button className={classes.dialogBtn} disableRipple onClick={handleClose}>
					<Typography>{i18next.t("취소")}</Typography>
				</Button>
				<Button
					className={classes.dialogBtnSave}
					onClick={handleClickSaveBatch}
					disabled={
						emailDuplicated ||
						localEmailDuplicate ||
						localEmailFormBad ||
						localStNumDuplicate ||
						stDuplicated ||
						!canSaveBatch
					}
					disableRipple
				>
					<Typography>{i18next.t("저장")}</Typography>
				</Button>
			</Box>

			<Backdrop
				style={{ color: "#fff", zIndex: (theme as Theme).zIndex.modal + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Dialog>
	);
}

export default observer<typeof CreateBatchSTDialog>(CreateBatchSTDialog);
