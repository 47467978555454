import { Repository } from "./Repository";

type Props = {
	serverContextPath: string;
};

export default class ProjectSubmissionAnswerRepository extends Repository {
	public requestPrefix: string;
	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/projects";
	}

	public async createSubmissionAnswers(submissionCode: string, data) {
		const result = await this.getRequestPromise("post", `${this.requestPrefix}/submissions/${submissionCode}/answers`, {}, data);
		return result;
	}


	public async getSubmissionAnswers(submissionCode) {
		const result = await this.getRequestPromise("get", `${this.requestPrefix}/submissions/${submissionCode}/answers`);
		return result;
	}

	public async updateSubmissionAnswers(data) {
		const result = await this.getRequestPromise("put", `${this.requestPrefix}/submissions/answers`, {}, data);
		return result;
	}

	public async getAnswerCodesBySubmissionCodes(submissionCodes: string[]) {
		const result = await this.getRequestPromise("post", `${this.requestPrefix}/submissions/answers/bulk`, {}, { submissionCodes });
		return result;
	}
}