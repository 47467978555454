import { ProjectSchoolType } from "../stores/enums/ProjectStoreEnums";
import { Repository } from "./Repository";
import { IMyGroup } from "./model/IMyGroup";
import { IStudentInfoForSave } from "./model/transfer/IStudentInfoForSave";
import { IUserProfile } from "./model/IUserProfile";
import { ICheckedEmail } from "./model/transfer/ICheckedEmail";
import { IUser } from "./model/IUser";
import { ISchoolCodeTransfer } from "./model/transfer/ISchoolCodeTransfer";
import { IUserTransfer } from "./model/transfer/IUserTransfer";
import { IMyGroupPagination } from "./model/transfer/IMyGroupPagination";
import { IUserResponse } from "./model/transfer/IUserResponse";
import { IMyUserTableTransfer } from "./model/transfer/IMyUserTableTransfer";

type Props = {
	serverContextPath: string;
};

export default class UserRepository extends Repository {
	public requestPrefix: string;
	public requestProjectPrefix: string;
	public requestGroupPrefix: string;

	constructor(props: Props) {
		super();

		this.requestPrefix = props.serverContextPath + "/api/v1/users";
		this.requestProjectPrefix = props.serverContextPath + "/api/v1/projects";
		this.requestGroupPrefix = props.serverContextPath + "/api/v1/groups";
	}

	public async getUsers(params) {
		const result = await this.getRequestPromise("get", this.requestPrefix, params);
		return result;
	}

	public async getMySchools() {
		const result = await this.getRequestPromise<ISchoolCodeTransfer[]>("get", this.requestPrefix + "/schools");
		return result;
	}
	public async getUserInfo(userCode: string) {
		const result = await this.getRequestPromise<IUserTransfer>("get", this.requestPrefix + `/info/${userCode}`);
		return result;
	}

	public async getGroups(params) {
		const result = await this.getRequestPromise<IMyGroup[]>("get", this.requestGroupPrefix, params);
		return result;
	}

	public async getGroupsForConfig(params) {
		const result = await this.getRequestPromise<IMyGroupPagination>("get", this.requestGroupPrefix + '/config', params);
		return result;
	}
	public async getUserProfile(code: string) {
		const result = await this.getRequestPromise<IUserProfile>("get", this.requestPrefix + `/profiles/${code}`);
		return result;
	}


	public async deleteUser(code) {
		const result = await this.getRequestPromise<void>("delete", this.requestPrefix + `/${code}`, {});
		return result;
	}


	// public async getAllUsers() {
	// 	const result = await this.getRequestPromise("get", this.requestPrefix + "/all", {});
	// 	return result;
	// }


	// public async getUserProfileGroupTransfers(loginId: string) {
	// 	const result = await this.getRequestPromise(
	// 		"get",
	// 		this.requestPrefix + `/userProfileGroupTransfer/${loginId}`,
	// 		{}
	// 	);
	// 	return result;
	// }

	// public async getGroupTransfersByCreatorId(loginId: string) {
	// 	const result = await this.getRequestPromise(
	// 		"get",
	// 		this.requestGroupPrefix + `/code/${loginId}`,
	// 		{}
	// 	);
	// 	return result;
	// }

	public async getMyUsersByParams(userCode: string, params: { groupCode?: string, keyword?: string, page?: any, rowsPerPage?: number }) {
		const result = await this.getRequestPromise<IUserResponse>("get", this.requestPrefix + `/${userCode}/my`, params);
		return result;
	}

	public async getMyUsers(params: { groupCode?: string, keyword?: string, page?: any, rowsPerPage?: number }, data) {
		const result = await this.getRequestPromise<IMyUserTableTransfer>("post", this.requestPrefix + `/my/table`, params, data);
		return result;
	}

	public async getMyUsersForManage(params: { page?: any, rowsPerPage?: number, groupCode?: string, keyword?: string }, data) {
		// place a timestamp to measure the time it takes to get the response

		const t0 = performance.now();

		const result = await this.getRequestPromise<IMyUserTableTransfer>("post", this.requestPrefix + `/my/table/manage`, params, data);
		const t1 = performance.now();

		console.log(`getMyUsersForManage: ${t1 - t0} ms`);
		return result;
	}


	public async getMyUsersForEvaluation() {
		const result = await this.getRequestPromise<IUserTransfer[]>("get", this.requestPrefix + `/my/users/evaluation`);
		return result;
	}


	public async checkDuplicatedEmail(params) {
		const result = await this.getRequestPromise<IUser>("get", this.requestPrefix + `/check/email`, params);
		return result;
	}

	public async checkEmails(data) {
		const result = await this.getRequestPromise<ICheckedEmail[]>("post", this.requestPrefix + `/my/check/emails`, null, data);
		return result;
	}


	public async checkStNum(data:
		{
			city: string; // "DEFAULT"
			country: string; // "DEFAULT"
			schoolType: string; // "DEFAULT"
			schoolName: string; // "네오랩"
			grade: string; // "1"
			className: string; // "1"
			number: string; // "1"
		}[]
	) {
		const copied = data.map((item) => {
			const c = { ...item };
			if (c.schoolType === "DEFAULT") {
				c.schoolType = String(ProjectSchoolType.NOGRADE);
			}

			return c;
		});

		const result = await this.getRequestPromise<IUserTransfer[]>("post", this.requestPrefix + `/my/check/stNum`, null, copied);
		return result;
	}


	public async saveMyUsers(data: IStudentInfoForSave[]) {
		const result = await this.getRequestPromise<string>("post", this.requestPrefix + `/my/save/myUsers`, null, data);
		return result;
	}

	public async deleteMyUsers(userCodes: String[]) {
		const result = await this.getRequestPromise<string>("delete", this.requestPrefix + `/my/delete/myUsers`, null, userCodes);
		return result;
	}


	public async modifyMyUsers(data) {
		const result = await this.getRequestPromise<string>("post", this.requestPrefix + `/my/modify/myUsers`, null, data);
		return result;
	}

	public async appointGroup(data) {
		const result = await this.getRequestPromise<void>("post", this.requestGroupPrefix + `/my/appoint/group`, null, data);
		return result;
	}

	public async allClearGroup(data) {
		const result = await this.getRequestPromise<void>("delete", this.requestGroupPrefix + `/my/allClear/group`, null, data);
		return result;
	}

	public async saveMyGroups(data) {
		const result = await this.getRequestPromise<void>("post", this.requestGroupPrefix + `/my/save/myGroups`, null, data);
		return result;
	}

	public async addMyUsers(newUsers) {
		const result = await this.getRequestPromise<IMyUserTableTransfer>("post", this.requestPrefix + `/my`, {}, newUsers);
		return result;
	}
	public async doSignUp(data: {
		email: string;
		password: string;
		nickname: string;
		type: string;

		furigana: string;
		lastName: string;
		firstName: string;
		lastFurigana: string;
		firstFurigana: string;
	}) {
		// createUser
		const result = this.getRequestPromise<void>("post", this.requestPrefix, {}, data);
		return result;
	}
}
