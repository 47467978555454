import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	FormControl,
	FormControlLabel,
	IconButton, MenuItem, Select,
	TextField,
	Theme,
	Typography
} from "@mui/material";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckLogin } from "../common/images/CheckLogin.svg";
import { ReactComponent as CheckLoginOff } from "../common/images/CheckLoginOff.svg";
import { ReactComponent as DialogClose } from "../common/images/DialogClose.svg";
import { ReactComponent as GroupDeleteIcon } from "../common/images/GroupDeleteIcon.svg";
import { ReactComponent as SelectIcon } from "../common/images/SelectIcon.svg";
import { useStores } from "../hooks/useStores";
import { ProjectSchoolType } from "../stores/enums/ProjectStoreEnums";
import { accountEmailAddress } from "../stores/UserStore";
import useEmotionStyles from "../views/main/styles/useEmotionStyles";
import { ManageStudentStlye } from "../views/main/styles/ManageStudentStlye";

type Props = {
	open: boolean,
	onClose: any,
	selectedUser: any,
}

function ModifyStudentDialog(props: Props) {
	const classes = useEmotionStyles(ManageStudentStlye);
	const { onClose, open, selectedUser } = props;
	const { navigateStore, userStore } = useStores();

	const { checkedEmails, checkEmailLoading, sidoList, userInfo,
		backdropOpen, siGunGooList, checkedStNumList, myGroups
	} = userStore;

	const theme = useTheme();
	const i18next = useTranslation();

	const [email, setEmail] = React.useState('');
	const [name, setName] = React.useState('');


	const [furigana, setFurigana] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [firstName, setFirstName] = React.useState('');
	const [lastFurigana, setLastFurigana] = React.useState('');
	const [firstFurigana, setFirstFurigana] = React.useState('');



	const [userCode, setUserCode] = React.useState('');

	const [first, setFirst] = React.useState(true);
	const [originEmail, setOriginEmail] = React.useState('');

	const [sido, setSido] = React.useState("DEFAULT");
	const [siGunGoo, setSiGunGoo] = React.useState("DEFAULT");
	const [schoolType, setSchoolType] = React.useState("DEFAULT");
	const [school, setSchool] = React.useState('');
	const [grade, setGrade] = React.useState(undefined);
	const [classNumber, setClassNumber] = React.useState(undefined);
	const [studentNumber, setStudentNumber] = React.useState(undefined);
	const [selectedGroup, setSelectedGroup] = React.useState("DEFAULT");
	const [groupList, setGroupList] = React.useState([]);
	const [newGroupName, setNewGroupName] = React.useState('');

	const dialogRef = React.useRef(null);

	const duplicate = checkedEmails.length > 0 && checkedEmails[0]?.duplicate;
	const duplicateStNum = checkedStNumList.length > 0 && checkedStNumList[0]?.stNumDuplicate;

	const handleClose = () => {
		onClose();
	};

	const checkEmailLength = () => {
		return !!(email.length >= 4 && email.length <= 20);
	}

	const checkEmailStartWord = () => {
		return /^\b[a-z]/.test(email);
	}

	React.useEffect(() => {
		userStore.getSido({ setFirstFn: setFirst, region: navigateStore.region });

		console.log("user in dialog : ", selectedUser.name);

		userStore.getUserInfo(selectedUser.userCode, "modify");

		return () => {
			userStore.initUserInfo();
		}
	}, [])

	React.useEffect(() => {
		if (sidoList.length > 0 && userInfo && userInfo.code && first) {
			const city = userInfo.city;
			const s_type = userInfo.schoolType;
			const s_name = userInfo.schoolName;

			const index = userInfo.email.indexOf('@');
			const email = userInfo.email.substring(0, index);

			setSido(city);
			setSchoolType(s_type);
			setSchool(s_name);

			setUserCode(userInfo.code);
			setEmail(email);
			setOriginEmail(email);
			setName(userInfo.name);
			setGrade(userInfo.grade);
			setClassNumber(userInfo.className);
			setStudentNumber(userInfo.number);
			setGroupList(userInfo.myGroups);

			const stNumData = {
				city: city,
				country: userInfo.country,
				schoolType: s_type,
				schoolName: s_name,
				grade: userInfo.grade,
				className: userInfo.className,
				number: userInfo.number,
				stNumDuplicate: false
			}

			const emailData = {
				email: userInfo.email,
				duplicate: false
			}

			userStore.settingForModifyDialog(emailData, stNumData);

			userStore.getSiGunGoo(city);
		}
	}, [sidoList, userInfo])

	React.useEffect(() => {
		if (siGunGooList.length > 0 && first) {
			const country = userInfo.country;
			setSiGunGoo(country);

			setFirst(false);
		}
	}, [siGunGooList])

	const handleChangeEmail = (event) => {
		setEmail(event.target.value);
		userStore.initCheckedEmails();
		if (originEmail === event.target.value) {
			const emailData = {
				email: event.target.value,
				duplicate: false
			}
			userStore.settingCheckedEmails(emailData);
		}
	}


	const handleChangeName = (event) => {
		// setName(event.target.value);
	}

	const handleChangeFirstName = (event) => {
		const firstNameInput = event.target.value;
		setFirstName(firstNameInput);

		if (lastName !== '') {
			switch (navigateStore.language) {
				case "ja":
				case "ko":
					setName(`${lastName} ${firstNameInput}`);
					break;
				default:
					setName(`${firstNameInput} ${lastName}`);
					break;
			}
		}
	}

	const handleChangeLastName = (event) => {
		const lastNameInput = event.target.value;
		setLastName(lastNameInput);

		if (firstName !== '') {
			switch (navigateStore.language) {
				case "ja":
				case "ko":
					setName(`${lastNameInput} ${firstName}`);
					break;
				default:
					setName(`${firstName} ${lastNameInput}`);
					break;
			}
		}
	}


	const handleChangeFirstFurigana = (event) => {
		const firstNameInput = event.target.value;
		setFirstFurigana(firstNameInput);

		if (lastFurigana !== '') {
			switch (navigateStore.language) {
				case "ja":
				case "ko":
					setFurigana(`${lastFurigana} ${firstNameInput}`);
					break;
				default:
					setFurigana(`${firstNameInput} ${lastFurigana}`);
					break;
			}
		}
	}

	const handleChangeLastFurigana = (event) => {
		const lastNameInput = event.target.value;
		setLastFurigana(lastNameInput);

		if (firstFurigana !== '') {
			switch (navigateStore.language) {
				case "ja":
				case "ko":
					setFurigana(`${lastNameInput} ${firstFurigana}`);
					break;
				default:
					setFurigana(`${firstFurigana} ${lastNameInput}`);
					break;
			}
		}
	}



	const handleChangeFurigana = (event) => {
		setFurigana(event.target.value);
	}

	const handleClickEmailCheck = () => {
		userStore.checkEmails([email]);
	}

	const handleSelectSido = (event) => {
		setSido(event.target.value);
		userStore.initCheckedStNums();
		userStore.getSiGunGoo(event.target.value);
	}

	const handleSelectSiGunGoo = (event) => {
		setSiGunGoo(event.target.value);
		userStore.initCheckedStNums();
	}

	const handleSelectSchoolType = (event) => {
		setSchoolType(event.target.value);
		userStore.initCheckedStNums();
	}

	const handleChangeSchoolName = (event) => {
		setSchool(event.target.value);
		userStore.initCheckedStNums();
	}

	const readyForStNumCheck = () => {
		const ready = (grade !== '' && grade !== undefined && classNumber !== undefined && classNumber !== '' && studentNumber !== undefined && studentNumber !== '');
		return ready;
	}

	const handleClickStNumCheck = () => {
		console.log("handleClickStNumCheck");
		const data = {
			city: sido,
			country: siGunGoo,
			schoolType: schoolType,
			schoolName: school,
			grade: grade,
			className: classNumber,
			number: studentNumber
		}
		userStore.checkStNum([data]);
	}

	const handleChangeGroup = (e) => {
		setSelectedGroup(e.target.value);

		const newGroup = myGroups.find(group => String(group.code) === e.target.value);

		setGroupList(prevGroupList => {
			const isDuplicate = prevGroupList.some(group => group.code === newGroup.code);
			if (!isDuplicate) {
				return [...prevGroupList, newGroup];
			} else {
				return prevGroupList;
			}
		})

		setTimeout(() => {
			dialogRef.current.scrollTop = dialogRef.current.scrollHeight;
		}, 200)
	}

	const removeGroup = (removeGroup) => {
		if (removeGroup.exist === 'N') {
			setGroupList(prevState => {
				let find = false;
				return prevState.filter(group => {
					if (!find && group.exist === 'N' && group.name === removeGroup.name) {
						find = true;
						return false;
					}
					return true;
				});
			})
		} else {
			setGroupList(prevGroupList => {
				return prevGroupList.filter(group => group.code !== removeGroup.code);
			});

			if (selectedGroup === removeGroup.code) {
				setSelectedGroup("DEFAULT");
			}
		}
	}

	const handleClickNewGroupAdd = () => {
		const newGroupData = {
			name: newGroupName,
			exist: 'N',
		}

		setGroupList(prevGroupList => {
			return [...prevGroupList, newGroupData];
		})

		setTimeout(() => {
			dialogRef.current.scrollTop = dialogRef.current.scrollHeight;
		}, 200)
	}

	const handleClickSave = () => {
		console.log("handleClickSave");
		const data = {
			code: userCode,
			email: email,
			name: name,
			city: sido,
			country: siGunGoo,
			schoolType: schoolType,
			schoolName: school,
			grade: grade,
			className: classNumber,
			number: studentNumber,
			myGroups: groupList,

			furigana: furigana,

			lastName: lastName,
			firstName: firstName,
			lastFurigana: lastFurigana,
			firstFurigana: firstFurigana
		}

		userStore.modifyMyUsers([data]);
	}


	const submitDisabled = !((checkedEmails.length > 0 && !duplicate) 
		&& (checkedStNumList.length > 0 && !duplicateStNum)
	);

	return (
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dataOpenDialog}>
			<Box display='flex' alignItems='center' justifyContent='space-between' className={classes.dialogTop}>
				<Typography>{i18next.t("학생 정보 편집")}</Typography>
				<IconButton className={classes.iconBtnReset} onClick={handleClose} disableRipple>
					<DialogClose />
				</IconButton>
			</Box>
			<Box ref={dialogRef} className={classes.dialogContent}>
				<Box className={classes.listBox}>
					<Typography className={classes.listBoxTitle}>{i18next.t("이메일")}</Typography>
					<Box className={classes.emailList}>
						<TextField
							id="outlined-modify-student-email"
							variant="outlined"
							autoComplete="off"
							placeholder={i18next.t("이메일")}
							className={classes.dialogInput}
							value={email}
							error={duplicate}
							InputLabelProps={{
								className: duplicate ? classes.emailError : "",
							}}
							onChange={handleChangeEmail}
						/>
						<Typography className={classes.emailText}>{accountEmailAddress}</Typography>
						{checkEmailLoading ?
							<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "160px", height: "36px", }}>
								<CircularProgress size={30} />
							</Box>
							:
							<Button className={classes.emailBtn}
								disableRipple
								disabled={!checkEmailLength() || !checkEmailStartWord() ? true : checkedEmails.length > 0 ? checkedEmails[0]?.duplicate ? false : true : false}
								onClick={handleClickEmailCheck}>
								<Typography>{i18next.t("이메일 중복 체크")}</Typography>
							</Button>
						}
					</Box>
					{duplicate ?
						<Box>
							<Typography style={{ color: 'red', fontSize: '0.875rem' }}>{i18next.t("이미 존재하는 아이디 입니다.")}</Typography>
						</Box>
						:
						checkedEmails.length > 0 && !checkedEmails[0]?.duplicate &&
						<Box sx={{ marginTop: "5px" }}>
							<Typography style={{ color: '#2377cc', fontSize: '0.875rem' }}>{i18next.t("사용 할 수 있는 아이디 입니다.")}</Typography>
						</Box>
					}

					<Box className={classes.passwordCheckBoxInner}>
						<FormControlLabel
							control={
								<Checkbox
									icon={<CheckLoginOff />}
									checked={checkEmailLength()}
									checkedIcon={<CheckLogin className={classes.checkedColor} />}
									disableRipple
									disabled
								/>
							}
							label={i18next.t("4 ~ 20자의 영문 소문자, 점(.), 하이픈(-) 포함 가능")}
							className={classes.rememberId}
						/>
						<FormControlLabel
							control={
								<Checkbox
									icon={<CheckLoginOff />}
									checked={checkEmailStartWord()}
									checkedIcon={<CheckLogin className={classes.checkedColor} />}
									disableRipple
									disabled
								/>
							}
							label={i18next.t("영문, 숫자, 특수문자를 혼용할 수 있으나 첫 글자는 반드시 영문으로 시작")}
							className={classes.rememberId}
						/>
					</Box>
				</Box>
				<Box className={classes.listBox}>
					<Typography className={classes.listBoxTitle}>{i18next.t("비밀번호")}</Typography>
					<Typography className={classes.passText}>
						{i18next.t("* 초기 비밀번호는 이메일 주소 아이디와 동일합니다.")}<br />
						{i18next.t("* 학생은 로그인하여 비밀번호를 수정 후 사용할 수 있습니다.")}
					</Typography>
				</Box>


				<Box className={classes.listBox}>
					<Typography className={classes.listBoxTitle}>{i18next.t("이름")}</Typography>
					<Box className={classes.textFieldBox}>
						{/* LastName */}
						<Typography className={classes.dialogInputLastNameTitle}>{i18next.t("last-name")}</Typography>
						<TextField
							id="outlined-basic-last-name"
							autoComplete="off"
							variant="outlined"
							placeholder={i18next.t("최대 50자")}
							className={clsx(classes.dialogInput, classes.dialogInputLastName)}
							value={lastName}
							onChange={handleChangeLastName}
						/>

						{/* LastName */}
						<Typography className={classes.dialogInputFirstNameTitle}>{i18next.t("first-name")}</Typography>
						<TextField
							id="outlined-basic-first-name"
							autoComplete="off"
							variant="outlined"
							placeholder={i18next.t("최대 50자")}
							className={clsx(classes.dialogInput, classes.dialogInputFirstName)}
							value={firstName}
							onChange={handleChangeFirstName}
						/>

						{/* Full name */}
						<Typography className={classes.dialogInputFullNameTitle}>{i18next.t("이름")}</Typography>
						<TextField
							id="outlined-basic-new-name"
							autoComplete="off"
							variant="outlined"
							placeholder={i18next.t("자동생성됨")}
							className={clsx(classes.dialogInput, classes.dialogInputFullName)}
							value={name}
							disabled={true}
							onChange={handleChangeName}
						/>
					</Box>
					{(navigateStore.language === "ja") &&
						<>
							<Typography sx={{ marginTop: "20px" }} className={classes.listBoxTitle}>{i18next.t("フリガナ")}</Typography>
							<Box className={classes.textFieldBox}>
								{/* LastName */}
								<Typography className={classes.dialogInputLastNameTitle}>{i18next.t("last-name")}</Typography>
								<TextField
									id="outlined-basic-last-name"
									autoComplete="off"
									variant="outlined"
									placeholder={i18next.t("최대 50자")}
									className={clsx(classes.dialogInput, classes.dialogInputLastName)}
									value={lastFurigana}
									onChange={handleChangeLastFurigana}
								/>

								{/* LastName */}
								<Typography className={classes.dialogInputFirstNameTitle}>{i18next.t("first-name")}</Typography>
								<TextField
									id="outlined-basic-first-name"
									autoComplete="off"
									variant="outlined"
									placeholder={i18next.t("최대 50자")}
									className={clsx(classes.dialogInput, classes.dialogInputFirstName)}
									value={firstFurigana}
									onChange={handleChangeFirstFurigana}
								/>

								{/* Full name */}
								<Typography className={classes.dialogInputFullNameTitle}>{i18next.t("이름")}</Typography>
								<TextField
									id="outlined-basic-new-name"
									autoComplete="off"
									variant="outlined"
									placeholder={i18next.t("자동생성됨")}
									className={clsx(classes.dialogInput, classes.dialogInputFullName)}
									value={furigana}
									disabled={true}
									onChange={handleChangeFurigana}
								/>
							</Box>
						</>
					}
				</Box>


				<Box className={classes.listBox}>
					<Typography className={classes.listBoxTitle}>{i18next.t("학교")}</Typography>

					{(!navigateStore.simpleMode) && <Box>
						<FormControl className={clsx(classes.dialogInput, classes.area1)}>
							<Select
								IconComponent={(props) => (
									<Box>
										<SelectIcon  {...props} />
									</Box>
								)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "right"
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
									// getContentAnchorEl: null,
									className: classes.selectPopover
								}}
								value={sido}
								onChange={handleSelectSido}
							>
								<MenuItem value="DEFAULT">
									<em>{i18next.t("시•도 선택")}</em>
								</MenuItem>
								{sidoList.map((sido, index) => (
									<MenuItem id={`sido-list-${index}`} key={index} value={sido.ctprvnCd}>{sido.ctprvnNm}</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={clsx(classes.dialogInput, classes.area2)}>
							<Select
								IconComponent={(props) => (
									<Box>
										<SelectIcon  {...props} />
									</Box>
								)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "right"
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
									// getContentAnchorEl: null,
									className: classes.selectPopover
								}}
								value={siGunGoo}
								onChange={handleSelectSiGunGoo}
							>
								<MenuItem value="DEFAULT">
									<em>{i18next.t("시•군•구 선택")}</em>
								</MenuItem>
								{siGunGooList.map((siGunGoo, index) => (
									<MenuItem id={`siGunGoo-list-${index}`} key={index} value={siGunGoo.signguCd}>{siGunGoo.signguNm}</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={clsx(classes.dialogInput, classes.area3)}>
							<Select
								IconComponent={(props) => (
									<Box>
										<SelectIcon  {...props} />
									</Box>
								)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "right"
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'right',
									},
									// getContentAnchorEl: null,
									className: classes.selectPopover
								}}
								value={schoolType}
								onChange={handleSelectSchoolType}
							>
								<MenuItem value="DEFAULT">
									<em>{i18next.t("학교급 선택")}</em>
								</MenuItem>
								<MenuItem value={ProjectSchoolType.ELEM}>{i18next.t("초등학교")}</MenuItem>
								<MenuItem value={ProjectSchoolType.MIDD}>{i18next.t("중학교")}</MenuItem>
								<MenuItem value={ProjectSchoolType.HIGH}>{i18next.t("고등학교")}</MenuItem>
								<MenuItem value={ProjectSchoolType.NOGRADE}>{i18next.t("해당 없음")}</MenuItem>
							</Select>
						</FormControl>
					</Box>
					}
					<Box className={classes.textFieldBox} sx={{ marginTop: "10px" }}>
						<TextField
							id="outlined-basic-school-name"
							autoComplete="off"
							variant="outlined"
							placeholder={i18next.t("학교 입력")}
							value={school}
							onChange={handleChangeSchoolName}
							className={clsx(classes.dialogInput, classes.dialogInputSchool)}
						/>
					</Box>
				</Box>
				<Box className={classes.listBox}>
					<Typography className={classes.listBoxTitle}>{i18next.t("학년반번호")}</Typography>
					<Box className={classes.textFieldBox}>
						<TextField
							id="outlined-basic-grade"
							variant="outlined"
							autoComplete="off"
							placeholder={i18next.t("숫자만 입력")}
							type={"number"}
							value={grade}
							onChange={(e) => {
								setGrade(e.target.value);
								userStore.initCheckedStNums();
							}}
							className={clsx(classes.dialogInput, classes.dialogInputRoomNo1)}
							inputProps={{
								min: "1"
							}}
						/>
						<Typography className={classes.textBoxSub1}>{i18next.t("학년")}</Typography>

						<TextField
							id="outlined-basic-class-number"
							variant="outlined"
							autoComplete="off"
							placeholder={i18next.t("숫자 또는 문자")}
							value={classNumber}
							onChange={(e) => {
								setClassNumber(e.target.value);
								userStore.initCheckedStNums();
							}}
							className={clsx(classes.dialogInput, classes.dialogInputRoomNo2)}
						/>
						<Typography className={classes.textBoxSub2}>{i18next.t("반")}</Typography>

						<TextField
							id="outlined-basic-student-number"
							variant="outlined"
							autoComplete="off"
							placeholder={i18next.t("숫자만 입력")}
							type={"number"}
							value={studentNumber}
							onChange={(e) => {
								setStudentNumber(e.target.value);
								userStore.initCheckedStNums();
							}}
							className={clsx(classes.dialogInput, classes.dialogInputRoomNo3)}
							inputProps={{
								min: "1"
							}}
						/>
						<Typography className={classes.textBoxSub3}>{i18next.t("번")}</Typography>
						<Button className={classes.roomNoBtn}
							disableRipple
							disabled={!readyForStNumCheck()
								? true
								: (
									checkedStNumList.length > 0
										? (checkedStNumList[0].stNumDuplicate ? false : true)
										: false
								)}
							onClick={handleClickStNumCheck}
						>
							<Typography>{i18next.t("학년반번호 중복체크")}</Typography>
						</Button>
					</Box>
					{duplicateStNum ?
						<Box>
							<Typography style={{ color: 'red', fontSize: '0.875rem', marginTop: '8px' }}>{i18next.t("중복된 학교 및 학년 반 번호가 존재 합니다.")}</Typography>
						</Box>
						:
						checkedStNumList.length > 0 && !checkedStNumList[0]?.stNumDuplicate &&
						<Box>
							<Typography style={{ color: '#2377cc', fontSize: '0.875rem', marginTop: '8px' }}>{i18next.t("사용 할 수 있는 정보 입니다.")}</Typography>
						</Box>
					}
				</Box>
				<Box className={clsx(classes.listBox, classes.listBoxLast)}>
					<Typography className={classes.listBoxTitle}>{i18next.t("그룹")}<span>{i18next.t("(선택)")}</span></Typography>

					<FormControl className={clsx(classes.dialogInput, classes.area4)}>
						<Select
							IconComponent={(props) => (
								<Box>
									<SelectIcon  {...props} />
								</Box>
							)}
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "right"
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								// getContentAnchorEl: null,
								className: classes.selectPopover
							}}
							value={selectedGroup}
							onChange={(e) => handleChangeGroup(e)}
						>
							<MenuItem disabled value="DEFAULT">
								<em>{i18next.t("그룹 선택")}</em>
							</MenuItem>
							{myGroups && myGroups.length > 0 && myGroups.map((group, index) => (
								<MenuItem key={index} value={group.code}>{group.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<Box className={classes.textFieldBox} sx={{ marginTop: "10px", paddingBottom: "5px" }}>
						<TextField
							id="outlined-basic-new-group-name"
							autoComplete="off"
							variant="outlined"
							placeholder={i18next.t("새 그룹명 입력")}
							value={newGroupName}
							className={clsx(classes.dialogInput, classes.dialogInputNewGroupName)}
							onChange={(e) => { setNewGroupName(e.target.value) }}
						/>
						<Button className={classes.blackBtn}
							onClick={handleClickNewGroupAdd}
							disableRipple>
							<Typography>{i18next.t("추가")}</Typography>
						</Button>
					</Box>

					{groupList.length > 0 && groupList.map((group, index) => (
						<Box id={`add-group-list-${index}`} key={index} className={classes.deleteTextBoxEdit}>
							<Typography className={classes.textBoxName}>{group.name}</Typography>
							<IconButton className={classes.iconBtnReset}
								onClick={() => removeGroup(group)}
								disableRipple>
								<GroupDeleteIcon />
							</IconButton>
						</Box>
					))}

				</Box>
			</Box>

			<Box display='flex' alignItems='center' justifyContent='center' className={classes.dialogBtnGroup}>
				<Button className={classes.dialogBtn} disableRipple onClick={handleClose}>
					<Typography>{i18next.t("취소")}</Typography>
				</Button>
				<Button className={classes.dialogBtnSave}
					onClick={handleClickSave}
					disabled={submitDisabled}
					disableRipple>
					<Typography>{i18next.t("저장")}</Typography>
				</Button>
			</Box>

			<Backdrop
				style={{ color: '#fff', zIndex: (theme as Theme).zIndex.modal + 1 }}
				open={backdropOpen || first}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

		</Dialog>
	);
}

export default observer<typeof ModifyStudentDialog>(ModifyStudentDialog);
