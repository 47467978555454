import { observer } from "mobx-react";
import { Trans, useTranslation } from 'react-i18next';
// import { withRouter } from "../../../../components/WithRouter";

import clsx from "clsx";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { LoginStyle } from "../../styles/LoginStyle";

import { ReactComponent as LoginBorderLine } from '../../../../common/images/LoginBorderLine.svg';
import { ReactComponent as LoginSideImg } from '../../../../common/images/LoginSideImg.svg';
import { ReactComponent as LogoWithTile } from "../../../../common/images/logo_and_title_Green_BG.svg";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { NativeMethods } from "../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-native-methods/NativeMethods";
import useOSAndBrowserInfo from "../../../../hooks/useOSVersion";
import { appUrl } from "../../../../appConfig";

type Props = {
}

export const acrobatReaderUrl = "https://get.adobe.com/reader/";
export const wordAutomationUrl = "/download";
export const msWordDownloadUrl = "https://www.microsoft.com/ko-kr/microsoft-365/word"
export const hwpToWordConverterUrl = "https://www.microsoft.com/ko-kr/download/details.aspx?id=36772";

export async function fetchDownloadUrl(): Promise<{ version: string, downloadUrl: string }> {
	const defaultDownloadUrl = "WordAutomationSetup.msi";
	const versionUrl = "/version.json";

	try {
		const response = await fetch(versionUrl);
		if (!response.ok) {
			console.warn(`Failed to fetch version.json: ${response.statusText}`);
			return {
				version: null,
				downloadUrl: defaultDownloadUrl,
			};
		}

		const data = await response.json();
		if (data && data.downloadUrl) {
			return {
				version: data.version,
				downloadUrl: data.downloadUrl
			};
		} else {
			console.warn(`downloadUrl not found in version.json.`);
			return {
				version: null,
				downloadUrl: defaultDownloadUrl,
			};
		}
	} catch (error) {
		console.error(`Error fetching version.json: ${error}`);
		return {
			version: null,
			downloadUrl: defaultDownloadUrl,
		};
	}
}


function DownloadPage(props: Props) {
	const classes = useEmotionStyles( LoginStyle );
	const i18next = useTranslation();

	const [open, setOpen] = React.useState(true);
	const osAndBrowser = useOSAndBrowserInfo(appUrl, open);

	const [isAutomationInstalled, setIsAutomationInstalled] = React.useState<null | "false" | "true" | "unknown">(null);
	const [isWordInstalled, setIsWordInstalled] = React.useState<null | "false" | "true" | "unknown">(null);
	
	const [automationAppServerVersion, setAutomationAppServerVersion] = React.useState<string>(null);
	const [automationAppClientVersion, setAutomationAppClientVersion] = React.useState<string>(null);
	const [shouldUpgrade, setShouldUpgrade] = React.useState<boolean>(false);


	React.useEffect(() => {
		fetchDownloadUrl().then(({ version }) => {
			// a.b.c.d 와 a.b.c.d 를 비교
			const serverVersion = version.split('.');
			while (serverVersion.length < 4) {
				serverVersion.push('0');
			}
			const sv = serverVersion.join('.');
			setAutomationAppServerVersion(sv);
		});
	}, []);


	React.useEffect(() => {
		if (!automationAppServerVersion || !automationAppClientVersion) {
			return
		}

		// a.b.c.d 와 a.b.c.d 를 비교
		const serverVersion = automationAppServerVersion.split('.');
		while (serverVersion.length < 4) {
			serverVersion.push('0');
		}

		const clientVersion = automationAppClientVersion.split('.');
		while (clientVersion.length < 4) {
			clientVersion.push('0');
		}

		const sv = serverVersion.map((v, i) => { return parseInt(v) });
		const cv = clientVersion.map((v, i) => { return parseInt(v) });

		let upgrade = false;
		for (let i = 0; i < 4; i++) {
			if (sv[i] < cv[i]) { break; }
			if (sv[i] > cv[i]) {
				upgrade = true;
				break;
			}
		}

		setShouldUpgrade(upgrade);
	}, [automationAppServerVersion, automationAppClientVersion]);



	React.useEffect(() => {
		const checkAutomation = async () => {
			let ret = null;
			const version = await NativeMethods.GetAutomationVersion();
			if (version) {
				ret = true;
				setAutomationAppClientVersion(version);
				setIsAutomationInstalled("true");
			} else {
				ret = false;
				setIsAutomationInstalled("false");
			}

			return ret;
		};

		const checkWord = async () => {
			const ret = await NativeMethods.IsWordInstalled();
			if (typeof ret === "boolean") {
				setIsWordInstalled(ret ? "true" : "false");
			}
			else if (!ret) {
				setIsWordInstalled("unknown");
			}
			return ret;
		}

		// 매 5초마다 체크
		const intervalId = setInterval(() => {
			if (isAutomationInstalled === "true" && isWordInstalled === "true") {
				clearInterval(intervalId);
				return;
			}

			if (isAutomationInstalled !== "true") {
				checkAutomation().then((ret) => {
					if (ret === true) {
						if (isWordInstalled !== "true") {
							setIsWordInstalled(null);
							checkWord();
						}
						
					} else {
						setIsWordInstalled("unknown");
					}
				});
			}
		}, 5000);

		return () => clearInterval(intervalId); // 상태 변화마다 이전 타이머 정리

	}, [isAutomationInstalled, isWordInstalled]);



	const handleClickDownload = async () => {
		const ret = await fetchDownloadUrl();
		window.location.href = ret.downloadUrl; // '/WordAutomationSetup.msi';
	}

	const handleClickDownloadAcrobat = () => {
		window.location.href = acrobatReaderUrl;
	}

	const handleClickDownloadHwpToWord = () => {
		window.location.href = hwpToWordConverterUrl;
	}

	const handleClickDownloadMsword = () => {
		window.location.href = msWordDownloadUrl;
	}


	const handleClickBackToHome = () => {
		window.location.href = '/';
	}

	const stateToString = (state: null | "false" | "true" | "unknown") => {
		switch (state) {
			case "unknown":
				return i18next.t(`파악안됨`);
			case "true":
				return i18next.t(`OK`);
			case "false":
				return i18next.t("다운로드");
			default:
				return i18next.t("(확인 중)");
		}
	}

	const appStateToString = (state: null | "false" | "true" | "unknown") => {
		switch (state) {
			case "true":
				{
					if (shouldUpgrade) {
						return i18next.t(`{{ automationAppClientVersion }} 사용 중, {{ automationAppServerVersion }}으로 업데이트 필요`, {
							automationAppClientVersion, automationAppServerVersion
						});
					}
					return i18next.t(`최신 버전{{ automationAppClientVersion }} 사용 중 OK`, { automationAppClientVersion });
				}
			case "false":
				return i18next.t("실행되고 있지 않음, 다운로드");
			default:
				return i18next.t("(확인 중)");
		}
	}


	return (
		<div className={classes.root}>
			{/*<TopBar leftItem={" "} rightItem={" "}/>*/}
			<Box className={classes.body}>
				<Box className={classes.center}>
					<Box className={classes.main}>
						<Box>
							<LogoWithTile className={classes.logo} />
						</Box>
						<Box className={classes.borderLine}>
							<LoginBorderLine />
						</Box>

						<Box className={classes.loginBox}>
							<Box className={classes.loginBoxTitle}>
								<Typography>{i18next.t("관련 프로그램 다운로드")}</Typography>
							</Box>
							<Box>
								<Box className={classes.loginInput} sx={{ marginBottom: "24px", position: 'relative' }}>
									<Trans i18nKey="다운로드되는 Automation 도구는 스마트펜 자동 연결 등에 사용되는 자동화 도구입니다.">
										다운로드되는 Automation 도구는 스마트펜 자동 연결 등에 사용되는 자동화 도구입니다.
										< br />
										아래의 버튼을 눌러 프로그램을 다운로드하고 프로그램을 설치한 다음 아이글에 다시 접속하십시오.
										<br />
										<br />
										모든 프로그램이 설치되어 OK이면 별도의 다운로드와 설치의 필요는 없습니다.
									</Trans>
								</Box>
								{/*<Box className={classes.checkBoxInner}>*/}
								{/*    <FormControlLabel control={<Checkbox className={classes.iconBtnReset} disableRipple/>}*/}
								{/*                      label="아이디 기억하기"*/}
								{/*                      className={classes.rememberId}/>*/}
								{/*</Box>*/}
							</Box>

							<Box className={clsx(classes.loginPageBtn, classes.loginPageBtnActive)}>
								<Button
									style={{ textTransform: "none" }}
									// disabled={isAutomationInstalled === "true"}
									onClick={handleClickDownload}
									disableRipple
								>
									<Typography sx={{marginRight: "10px"}}>
										{isAutomationInstalled === null && <CircularProgress size={16} sx={{ marginRight: "10px" }} />}
										{i18next.t("NeoStudio2Lite")}
									</Typography>
									<Typography style={{ fontWeight: 500 }}>
										{appStateToString(isAutomationInstalled)}
									</Typography>
								</Button>
							</Box>

							<Box className={clsx(classes.loginPageBtn, classes.loginPageBtnActive)}>
								<Button
									style={{ textTransform: "none" }}
									disabled={isWordInstalled === "true"}
									onClick={handleClickDownloadMsword}
									disableRipple
								>
									<Typography sx={{marginRight: "10px"}}>
										{isWordInstalled === null && <CircularProgress size={16} sx={{ marginRight: "10px" }} />}
										{i18next.t("Microsoft Word")}
									</Typography>
									<Typography style={{ fontWeight: 500 }}>
										{stateToString(isWordInstalled)}
									</Typography>
								</Button>
							</Box>

							<Box className={clsx(classes.loginPageBtn, classes.loginPageBtnActive)}>
								<Button onClick={handleClickBackToHome} disableRipple >
									<Typography>{i18next.t("홈으로 돌아가기")}</Typography>
								</Button>
							</Box>
						</Box>


						<LoginSideImg className={classes.sideImg} />
					</Box>
				</Box>


			</Box>
		</div >
	);
}

export default observer<typeof DownloadPage>(DownloadPage);
