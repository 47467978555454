import { Box, Button, Popover, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";


import { ReactComponent as MarkCheckList } from '../../../../../common/images/MarkCheckList.svg';
import { ReactComponent as MarkCheckListActive } from '../../../../../common/images/MarkCheckListActive.svg';
import { ReactComponent as MatchRefresh } from '../../../../../common/images/MatchRefresh.svg';
import { ReactComponent as OcrLoaderIcon } from '../../../../../common/images/OcrLoaderIcon.svg';
import { ReactComponent as SmartPenEnroll } from '../../../../../common/images/SmartPenEnroll.svg';
import { ReactComponent as TooltipTipIcon } from '../../../../../common/images/TooltipTipIcon.svg';


import { Tooltip } from "@mui/material";
// import 'dayjs/locale/ko';
import { convertUTCToKST } from "../../../../../hooks/convertUTCToKST";

import React from "react";
import { useTranslation } from "react-i18next";
import { OcrStatus } from "../../../../../repositories/model/support/OcrStatus";
import { ProjectStateType } from "../../../../../repositories/model/support/ProjectStateType";
import { ProjectSubmitType } from "../../../../../repositories/model/support/ProjectSubmitType";
import { IProjectSubmissionTransfer } from "../../../../../repositories/model/transfer/IProjectSubmissionTransfer";
import useEmotionStyles from "../../../styles/useEmotionStyles";
import { SubmitManagementStyle } from "../../../styles/SubmitManagementStyle";

// const convertSubmitForOnline = (state) =>{
//     switch (state) {
//         case HomeworkStateType.CREATED:
//         case HomeworkStateType.POSED:
//         case HomeworkStateType.SUBMITTED:
//         case HomeworkStateType.TRANSLATED:
//             return false;
//         case HomeworkStateType.COMPARED:
//         case HomeworkStateType.SCORED:
//         case HomeworkStateType.EXPORTED:
//             return true;
//     }
// }

const isSubmitted = (state: ProjectStateType) => {
	switch (state) {
		case ProjectStateType.CREATED:
		case ProjectStateType.POSED:
			return false;
		case ProjectStateType.OFFLINE_UPLOADED:
		case ProjectStateType.SUBMITTED:
		case ProjectStateType.TRANSLATED:
		case ProjectStateType.COMPARED:
		case ProjectStateType.SCORED:
		case ProjectStateType.EXPORTED:
			return true;
	}
}

const isStrokesUploaded = (state: ProjectStateType) => {
	switch (state) {
		case ProjectStateType.CREATED:
		case ProjectStateType.POSED:
		case ProjectStateType.SUBMITTED:
		case ProjectStateType.TRANSLATED:
			return false;

		case ProjectStateType.OFFLINE_UPLOADED:
		case ProjectStateType.COMPARED:
		case ProjectStateType.SCORED:
		case ProjectStateType.EXPORTED:
			return true;
	}
}

const isHWRWCompleted = (state: ProjectStateType) => {
	switch (state) {
		case ProjectStateType.CREATED:
		case ProjectStateType.POSED:
		case ProjectStateType.SUBMITTED:
		case ProjectStateType.TRANSLATED:
		case ProjectStateType.OFFLINE_UPLOADED:
			return false;

		case ProjectStateType.COMPARED:
		case ProjectStateType.SCORED:
		case ProjectStateType.EXPORTED:
			return true;
	}
}

const canBeDeleted = (state: ProjectStateType) => {
	switch (state) {
		case ProjectStateType.CREATED:
		case ProjectStateType.POSED:
			return false;
		case ProjectStateType.SUBMITTED:
		case ProjectStateType.TRANSLATED:
			return true;
		case ProjectStateType.COMPARED:
		case ProjectStateType.SCORED:
		case ProjectStateType.EXPORTED:
			return false;
	}
}

type Props = {
	ncodeAllocated: boolean,
	submissionTransfer: IProjectSubmissionTransfer,
	submissionIndex: number,
	handleClickView: (submissionTransfer: IProjectSubmissionTransfer, submissionIndex: number) => void,
	handleClickComparing: (submissionTransfer: IProjectSubmissionTransfer, submissionIndex: number) => void,
	handleClickScored: (submissionCode: any) => void,
	handleOpenPenDialog(submissionTransfer: IProjectSubmissionTransfer, submissionIndex: number): void,
//	handleFileUpload: (e: any, submissionIndex: number) => Promise<void>,
//	handleClickFileUpload: (index: number) => void,
//	handleOpenDialog: (dialogType: any, index: number) => void,
//	handleClickRetryOCR: (userCode: string) => Promise<void>

}

export function SubmitStatusRow(props: Props) {
	const { submissionTransfer, submissionIndex, handleClickView, handleClickComparing, handleClickScored, handleOpenPenDialog, ncodeAllocated } = props;

	const classes = useEmotionStyles(SubmitManagementStyle);
	const i18next = useTranslation();
	const [anchorEl, setAnchorEl] = useState<Element>(null);

	const openTool = Boolean(anchorEl);
	const id = openTool ? 'simple-popover' : undefined;
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [showIcon, setShowIcon] = React.useState(false);
/*
	const renderOCRProcessing = (type: OcrStatus, userCode: string) => {
		switch (type) {
			case OcrStatus.RQST:
				return (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Box className={classes.aiSpinner}>
							<OcrLoaderIcon style={{ width: "24px", height: "24px" }} />
						</Box>
						<Typography className={classes.ocrProgress}>{i18next.t("OCR 판독 중")}</Typography>
					</Box>
				)
			case OcrStatus.CMPL:
				return (
					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
						<Typography className={classes.ocrSucess}>{i18next.t("OCR 판독 완료")}</Typography>
					</Box>
				)
			case OcrStatus.FAIL:
				return (
					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
						<Tooltip
							title={i18next.t("OCR 판독 재요청")}
							placement="bottom"
							classes={{
								tooltip: classes.lightTooltip,
							}}
							style={{ display: 'flex' }}
						>
							<Box style={{ display: "flex", alignItems: "center" }}>
								<Typography className={classes.matchTemp}>{i18next.t("OCR 판독 실패")}</Typography>
								<MatchRefresh
									style={{ cursor: "pointer", padding: 0, height: 18 }}
									onClick={(e) => handleClickRetryOCR(userCode)}
								>
									<title>{i18next.t("OCR 판독 재요청")}</title>
								</MatchRefresh>
							</Box>
						</Tooltip >

						{}
					</Box >
				)
			case OcrStatus.REFL:
				return (
					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
						<Typography className={classes.matchTemp}>{i18next.t("OCR 판독 재실패")}</Typography>
					</Box>
				)

			default:
				return (
					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
						<Typography className={classes.matchTemp}>{i18next.t("대조업무 전")}</Typography>
					</Box>
				)

		}
	}
*/

	const renderSubmissionPaperStatus = (st: IProjectSubmissionTransfer) => {
		if (isSubmitted(st.state)) {
			if (isHWRWCompleted(st.state)) {
				return (
					<Button
						className={classes.tableStudentNameBtn}
						style={{ padding: 0, margin: 0 }}
						onClick={e => handleClickView(st, submissionIndex)}
						disableRipple
					>
						<Typography>{i18next.t("보기")}</Typography>
					</Button>
				)
			} else {
				if (
					st.ocrStatus === OcrStatus.RQST) {
					return (
						<Typography className={classes.noDataText}>
							{i18next.t("미등록")}
						</Typography>
					)
				} else {
					return (
						<Button className={classes.tableStudentNameBtn} style={{ padding: 0, margin: 0 }} onClick={e => handleClickComparing(st, submissionIndex)} disableRipple>
							<Typography>
								{i18next.t("대조업무하기")}
							</Typography>
						</Button>
					)
				}
			}
		} else {
			return (
				<Typography className={classes.noDataText}>{i18next.t("미등록")}</Typography>
			)
		}
	}


	const isSmartpenUploaded = (submissionTransfer.submitType === ProjectSubmitType.PEN);
	const isOnlineTypedByStudent = (submissionTransfer.submitType === ProjectSubmitType.ONLINE);
	

	const renderSubmissionStatus = (st: IProjectSubmissionTransfer) => {
		if (isSubmitted(st.state)) {
			if (isHWRWCompleted(st.state)) {
				if (st.state === ProjectStateType.SCORED) {
					return (
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
							<Typography className={classes.matchAfter}>
								{i18next.t("채점 완료")}
							</Typography>
						</Box>)
				}
				else {
					return (
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
							<Typography className={classes.matchAfter}>
								{i18next.t("대조 완료")}
								{
									isSmartpenUploaded ? i18next.t("(펜)") : i18next.t("(파일)")
								}
							</Typography>
						</Box>)
				}
			} else {
				if (isSubmitted(st.state)) {
					return (
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
							<Typography className={classes.matchTemp}>{i18next.t("대조업무 전")}</Typography>
						</Box>
					)
//					return renderOCRProcessing(st.ocrStatus, st.userCode);
				}
				else {
					return (
						<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
							<Typography className={classes.matchTemp}>{i18next.t("대조 미완료")}</Typography>
						</Box>
					)
				}
			}
		}
		else {
			return (
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
					<Typography className={classes.matchBefore}>{i18next.t("미등록")}</Typography>
				</Box>)
		}
	}

	// console.log(`submissionTransfer.state=${submissionTransfer.state}`);

	const isSubmitDisabled = (isSmartpenUploaded && (submissionTransfer.state !== ProjectStateType.OFFLINE_UPLOADED));
	const isSubmitDisabledWithNCode = isSubmitDisabled || !ncodeAllocated;
	const isOnlineSubmittedByTyping = (submissionTransfer.state === ProjectStateType.COMPARED) && isOnlineTypedByStudent;

	return (
		< TableRow key={`rows${submissionIndex}`} sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}>
			<TableCell align="center">
				{submissionIndex + 1}
			</TableCell>
			<TableCell>
				{submissionTransfer.userProfile.name}
			</TableCell>
			<TableCell>
				{
					i18next.t("{{grade}}학년 {{className}}반 {{number}}번",
						{
							grade: submissionTransfer.userProfile.grade,
							className: submissionTransfer.userProfile.className,
							number: submissionTransfer.userProfile.number
						}
					)
				}
			</TableCell>

			<TableCell>
				{props.ncodeAllocated ? i18next.t("할당됨") : i18next.t("미할당")}
			</TableCell>


			<TableCell>
				{renderSubmissionPaperStatus(submissionTransfer)}
			</TableCell>
			<TableCell>
				{renderSubmissionStatus(submissionTransfer)}
			</TableCell>
			{/*<TableCell style={{display :"flex", alignItems:'center'}}>*/}
			{/*    {convertSubmitForOnline(submissionTransfer.state) && (submissionTransfer.submitType === ProjectSubmitType.ONLINE) ?*/}
			{/*        <>*/}
			{/*            <Typography className={classes.submit}>{i18next.t("제출함")}</Typography>*/}
			{/*            /!*todo 추후에 학생 제출페이지를 붙인다.(값수정 X 보기만 가능하게)*!/*/}
			{/*            /!*<Button className={classes.tableStudentNameBtn} style={{padding:0,margin:0}} onClick={(e)=>handleClickScored(submissionTransfer.submissionCode)} disableRipple>*!/*/}
			{/*            /!*    <Typography>(view)</Typography>*!/*/}
			{/*            /!*</Button>*!/*/}
			{/*        </>*/}

			{/*        :*/}
			{/*        <Typography className={classes.unsubmit}>{i18next.t("미제출")}</Typography>*/}
			{/*    }*/}
			{/*</TableCell>*/}
			<TableCell>
				{submissionTransfer.createdDatetime && `${convertUTCToKST(submissionTransfer.createdDatetime).format("YYYY-MM-DD HH:mm")}`}
			</TableCell>

			<TableCell>
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>

					<Button
						style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}
						className={isSubmitDisabledWithNCode || isOnlineSubmittedByTyping ? classes.enrollDelete : classes.enroll}
						onClick={() => handleOpenPenDialog(submissionTransfer, submissionIndex)}
						disableRipple
						disabled={isSubmitDisabled || isOnlineSubmittedByTyping}
					>
						<SmartPenEnroll />
						{submissionTransfer.state === ProjectStateType.OFFLINE_UPLOADED ?
							<Typography>{i18next.t("재업로드")}</Typography> :
							<Typography>{i18next.t("스마트펜")}</Typography>
						}
					</Button>


					{/*
					<Button
						style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}
						className={isPenUploaded || isSubmitted(submissionTransfer.state) ? classes.enrollDelete : classes.enroll}

						onClick={(e) => handleClickFileUpload(submissionIndex)}
						disableRipple
						disabled={isPenUploaded || isSubmitted(submissionTransfer.state)}
					>
						<FileEnroll />
						<Typography>{i18next.t("스캔")}</Typography>
						<input id={`fileInputNum${submissionIndex}`} type={"file"} accept={".pdf"} multiple onChange={(e) => handleFileUpload(e, submissionIndex)} hidden></input>
					</Button>
					*/}


					<Popover
						id={id}
						open={openTool}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						className={classes.popoverTooltip}
					>
						{showIcon ?
							<>
								<Box className={classes.tooltipTop}>
									<TooltipTipIcon />
								</Box>
								<Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} sx={{ marginTop: "10px" }}>
									<Typography className={classes.tooltipText}>{i18next.t("등록된 펜 정보가 없습니다.")}</Typography>
								</Box>
							</>
							:
							<>
								<Typography className={classes.tooltipText}>
									{i18next.t("펜 기록을 업로드 하려면<br/> 펜 연결 버튼을 눌러 진행하세요.")}
								</Typography>
								<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Button className={classes.tooltipUploadBtn} disableRipple>
										<Typography>{i18next.t("펜 연결 후 업로드")}</Typography>
									</Button>
								</Box>
							</>
						}
					</Popover>

					{/* {
						canBeDeleted(submissionTransfer.state) === false || submissionTransfer.submitType === ProjectSubmitType.ONLINE || submissionTransfer.ocrStatus === OcrStatus.RQST ?
							<Button style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} className={classes.enrollDelete}
								disabled disableRipple>
								<TempNonActiveDeleteIcon />
								<Typography>{i18next.t("파일삭제")}</Typography>
							</Button>
							:
							<Button style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} className={classes.enrollDeleteActive}
								onClick={(e) => handleOpenDialog(0, submissionIndex)}
								disableRipple>
								<EnrollDeleteIcon />
								<Typography>{i18next.t("파일삭제")}</Typography>
							</Button>

					} */}
				</Box>
			</TableCell>
			<TableCell>
				{isHWRWCompleted(submissionTransfer.state) || (isSubmitted(submissionTransfer.state)) ?
					<Tooltip
						title={i18next.t("채점하기")}
						placement="bottom"
						classes={{
							tooltip: classes.lightTooltip,
						}}
						style={{ display: 'flex' }}
					>
						<MarkCheckListActive style={{ cursor: "pointer" }} onClick={(e) => handleClickScored(submissionTransfer.submissionCode)} />
					</Tooltip>
					:
					<MarkCheckList />
				}
			</TableCell>
		</TableRow >
	)
}
