import { Box, Button, FormControl, IconButton, InputBase, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "@mui/x-date-pickers/AdapterDayjs";
import clsx from "clsx";
import "dayjs/locale/ko";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddTaskIcon } from "../../../../common/images/AddTaskIcon.svg";
import { ReactComponent as SearchNameIcon } from "../../../../common/images/SearchNameIcon.svg";
import { ReactComponent as SelectIcon } from "../../../../common/images/SelectIcon.svg";
import { ReactComponent as TableListOrder } from "../../../../common/images/TableListOrder.svg";
import useNaviCustom from "../../../../hooks/useNaviCustom";
import { useStores } from "../../../../hooks/useStores";
import { ProjectStateType } from "../../../../repositories/model/support/ProjectStateType";
import useEmotionStyles from "../../styles/useEmotionStyles";
import { TableHomeStlye } from "../../styles/TableHomeStlye";
import { AssignmentRow } from "./AssignmentRow";

type Props = {
}

function AssignmentHome(props: Props) {
	const { navigateStore, projectStore } = useStores();
	const i18next = useTranslation();
	const classes = useEmotionStyles(TableHomeStlye);

	const [createdSortRotated, setCreatedSortRotated] = React.useState(false);
	const [deadLineSortRotated, setDeadLineSortRotated] = React.useState(false);
	const [stepFilter, setStepFilter] = React.useState("all");
	const [searchKeyword, setSearchKeyword] = React.useState("");
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [selectedSortBtn, setSelectedSortBtn] = React.useState(1);
	const [sortingHints, setSortingHints] = React.useState([{ column: "created_datetime", hint: "DESC" }]);

	const naviCustom = useNaviCustom();

	const sortByCreateTime = "created_datetime";
	const sortByEndTime = "end_datetime";

	const { assignmentProjectList: customProjectList } = projectStore;

	React.useLayoutEffect(() => {
		projectStore.getCustomProjectList(null, searchKeyword, page - 1, rowsPerPage, sortingHints);
		projectStore.getTemplateProjectList(null, searchKeyword, page - 1, 16384, sortingHints);
	}, []);

	React.useEffect(() => {
		let state = null;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== "all") state = stepFilter;

		if (selectedSortBtn === 2) {
			sort = sortByEndTime;
			dir = deadLineSortRotated ? "ASC" : "DESC";
		}
		const sortingHints = [{ column: sort, hint: dir }];
		projectStore.getCustomProjectList(state, searchKeyword, page - 1, rowsPerPage, sortingHints);
	}, [stepFilter, rowsPerPage, createdSortRotated, deadLineSortRotated, page]);

	const handleCreatedDateSortClick = () => {
		setSelectedSortBtn(1);
		setCreatedSortRotated(!createdSortRotated);
	};

	const handleDeadLineSortClick = () => {
		setSelectedSortBtn(2);
		setDeadLineSortRotated(!deadLineSortRotated);
	};

	const handleSelectState = async (e) => {
		setStepFilter(e.target.value);
	};

	const handleClickNewProject = () => {
		naviCustom.go(`/assignments/create/info`);
	};

	const handleClickPage = (e, value) => {
		setPage(value);
	};

	const handleChangeSearchKeyword = (e) => {
		setSearchKeyword(e.target.value);
	};

	const handleClickKeywordSearch = () => {
		searchProjectList();
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			searchProjectList();
		}
	};


	const searchProjectList = () => {
		let state = null;
		let sort = sortByCreateTime;
		let dir = createdSortRotated ? "ASC" : "DESC";
		if (stepFilter !== "all") state = stepFilter;

		if (selectedSortBtn === 2) {
			sort = sortByEndTime;
			dir = deadLineSortRotated ? "ASC" : "DESC";
		}
		const sortingHints = [{ column: sort, hint: dir }];
		projectStore.getCustomProjectList(state, searchKeyword, page - 1, rowsPerPage, sortingHints);
	};

	return (
		<div className={classes.root}>
			{/* ******콘텐츠 영역******* */}
			<Box component="main"
				sx={{
					flexGrow: 1,
					background: "#F2F7F3",
					height: "calc(100vh - 86px)",
				}}
				display="flex" alignItems="center" justifyContent="center"
				className={classes.padding}
			>
				<Box className={classes.mainContentBox}>
					<Box className={classes.titleTopMg}>
						<Typography className={classes.commonSubTitle}>
							{i18next.t('평가과제운영')} (<span>{customProjectList && customProjectList.totalCount ? customProjectList.totalCount : 0}</span>)
						</Typography>
					</Box>

					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<Typography className={classes.selectText}>{i18next.t('상태')}</Typography>
							<Box>
								<FormControl className={classes.formControl}>
									<Select IconComponent={(props) => (
										<Box>
											<SelectIcon {...props} />
										</Box>
									)}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "right",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "right",
											},
											// getContentAnchorEl: null,
										}}
										value={stepFilter}
										onChange={handleSelectState}
									>
										<MenuItem value={"all"}>{i18next.t('전체')}</MenuItem>
										<MenuItem value={ProjectStateType.CREATED}>
											{i18next.t('출제하기')}
										</MenuItem>
										<MenuItem value={ProjectStateType.POSED}>
											{i18next.t('제출물 등록하기')}
										</MenuItem>
										<MenuItem value={ProjectStateType.COMPARED}>
											{i18next.t('채점하기')}
										</MenuItem>
										<MenuItem value={ProjectStateType.SCORED}>
											{i18next.t('결과 내보내기')}
										</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Paper
								component="form"
								sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
								className={classes.urlInputBox}
							>
								<IconButton
									className={classes.iconBtnReset}
									onClick={handleClickKeywordSearch}
									disableRipple
								>
									<SearchNameIcon />
								</IconButton>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder={i18next.t('평가과제운영 검색')}
									value={searchKeyword}
									onChange={handleChangeSearchKeyword}
									onKeyDown={handleKeyDown}
									inputProps={{ "aria-label": i18next.t('평가과제운영 검색') }}
								/>
							</Paper>
						</Box>
						<Box>
							<Button
								className={clsx(classes.greenBtn, classes.iconAdd)}
								disableRipple
								onClick={handleClickNewProject}
							>
								<AddTaskIcon />
								<Typography>{i18next.t('과제 제시하기')}</Typography>
							</Button>
						</Box>
					</Box>

					<Box>
						<TableContainer component={Paper} className={classes.tableBox}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width='4%' className={classes.tableNoBox}>{i18next.t('No.')}</TableCell>
										<TableCell width='12%'>{i18next.t('평가과제운영명')}</TableCell>

										<TableCell width='10%'>{i18next.t('평가과제')}</TableCell>


										{(!navigateStore.simpleMode) &&
											<>
												<TableCell width="6%">{i18next.t('학교급')}</TableCell>
												<TableCell width="10%">{i18next.t('학년도 / 학기')}</TableCell>
											</>
										}

										
										<TableCell width="8%">
											<IconButton
												onClick={handleCreatedDateSortClick}
												className={clsx(classes.iconBtnReset, classes.sortButton)}
												disableRipple
											>
												<Typography>{i18next.t('등록일')}</Typography>
												<TableListOrder
													style={{
														transform: createdSortRotated
															? "rotate(180deg)"
															: "none",
														transition: "transform 0.3s ease",
													}}
												/>
											</IconButton>
										</TableCell>

										<TableCell width="8%">
											<IconButton
												onClick={handleDeadLineSortClick}
												className={clsx(classes.iconBtnReset, classes.sortButton)}
												disableRipple
											>
												<Typography>{i18next.t('제출 기한')}</Typography>
												<TableListOrder
													style={{
														transform: deadLineSortRotated ? "rotate(180deg)" : "none",
														transition: "transform 0.3s ease",
													}}
												/>
											</IconButton>
										</TableCell>

										<TableCell width="15%">
											{i18next.t('제출 진행율')}
										</TableCell>
										<TableCell width="15%">
											{i18next.t('채점 진행율')}
										</TableCell>
										<TableCell width="8%">
											{i18next.t('제시 상태')}
										</TableCell>
										<TableCell width="10%" align="center">
											{i18next.t('실행 단계')}
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{customProjectList
										&& customProjectList.projects
										&& customProjectList.projects.map((project, index) =>
											<AssignmentRow
												project={project}
												rowIndex={index}
												page={page}
												rowsPerPage={rowsPerPage}
												key={project.code}
											// projectStateButton={projectStateButton}
											// checkPosed={checkPosed}
											/>)
									}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Box
						className={classes.pageNumber}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Stack spacing={2}>
							<Pagination
								count={customProjectList && customProjectList.projects && customProjectList.totalCount > 0 ?
									Math.ceil(customProjectList.totalCount / rowsPerPage) : 1}
								boundaryCount={2}
								showFirstButton
								showLastButton
								onChange={handleClickPage}
								variant="outlined"
							/>
						</Stack>
					</Box>
				</Box>
			</Box>
		</div>
	);
}

export default observer<typeof AssignmentHome>(AssignmentHome);
