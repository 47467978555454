export const codeUtil = {
    /**
     * Generates a unique alphanumeric code.
     * @param {number} length - Length of the generated code (default is 8)
     * @returns {string} Random uppercase alphanumeric code
     */
    createCode: (length = 8) => {
        return [...Array(length)]
            .map(() => Math.random().toString(36).charAt(2).toUpperCase())
            .join('');
    }
};