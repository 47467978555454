import { makeAutoObservable, runInAction, toJS } from "mobx";
import i18next from "../lang";
import { ChangeFlag } from "../repositories/model/support/ChangeFlag";
import { ProjectExamListUpdateType } from "../repositories/model/support/ProjectExamListUpdateType";
import { ProjectExamType } from "../repositories/model/support/ProjectExamType";
import { IExamInfo } from "../repositories/model/transfer/IExamInfo";
import { IQuestionInfo } from "../repositories/model/transfer/IQuestionInfo";
import ProjectExamQuestionRepository from "../repositories/ProjectExamQuestionRepository";
import ProjectRepository from "../repositories/ProjectRepository";
import { DataUtils } from "../util/data-utils";
import { codeUtil } from '../util/codeUtil';
import { IExampleAnswerInfo } from "../repositories/model/transfer/IExampleAnswerInfo";

const LogPrefix = "[ProjectExamStore]";


const EmptyProjectExamQuestion: IQuestionInfo = {
	projectExamCode: "",
	projectExamQuestionNum: 1,
	order: 1,
	question: "",
	reference: "",

	score: 15,

	rag: false,
	correctedAnswer: "",
	correctedKeyword: "",
	auto: false,
	aiAnswer: "",
	aiKeyword: "",
	aiPrompt: "",
	aiResult: "",

	changeFlag: ChangeFlag.CREATED,
	initialQuestion: "",
	initialReference: "",
	keywordArr: [{
		key: 0,
		keyword: ""
	}]
};

const EmptyProjectExamTransfer = (): IExamInfo => ({
	code: codeUtil.createCode(),
	projectCode: "",
	type: ProjectExamType.DESCRIPTIVE,
	order: 1,
	directive: "",
	paragraph: "",
	source: "",

	// needs to have EmptyProjectExamTransfer's code
	questions: Array(1).fill({ ...EmptyProjectExamQuestion }),
	changeFlag: ChangeFlag.CREATED,
	key: 1,
	initialParagraph: ""
});

type ProjectExamStoreProps = {
	projectRepository: ProjectRepository;
	projectExamQuestionRepository: ProjectExamQuestionRepository;
};

export default class ProjectExamStore {
	public projectRepository: ProjectRepository;
	public projectExamQuestionRepository: ProjectExamQuestionRepository;

	public projectCode: string;
	public projectExamList: IExamInfo[];
	public oriProjectExamList: IExamInfo[];
	public addedImageIdList: string[];

	// state
	public isNew: boolean;
	public isSubmitted: boolean;

	public isSelectingProjectExamList: boolean;
	public isCreatingProjectExamList: boolean;
	public isUpdatingProjectExamList: boolean;
	public isConfirmForUpdateRequired: boolean;


	constructor(props: ProjectExamStoreProps) {
		makeAutoObservable(this);

		const ps = props;
		const { projectRepository, projectExamQuestionRepository } = ps;

		this.projectRepository = projectRepository;
		this.projectExamQuestionRepository = projectExamQuestionRepository;

		this.projectCode = "";
		this.projectExamList = [];
		this.oriProjectExamList = [];
		this.addedImageIdList = [];

		// state
		this.isNew = true; // new or existing
		this.isSubmitted = false;

		this.isSelectingProjectExamList = false;
		this.isCreatingProjectExamList = false;
		this.isUpdatingProjectExamList = false;
		this.isConfirmForUpdateRequired = false;

	}

	init() {
		this.projectCode = "";
		this.projectExamList = [];
		this.oriProjectExamList = [];
		this.addedImageIdList = [];

		this.isNew = true; // exam to create(true) / update(false)
		this.isSubmitted = false; // before(false) / after(true) creating or updating

		this.isSelectingProjectExamList = false;
		this.isCreatingProjectExamList = false;
		this.isUpdatingProjectExamList = false;
		this.isConfirmForUpdateRequired = false;
	};

	initProjectExamList() {
		this.projectExamList = [];
	}

	initProjectExamListWithProjectCode(code: string) {
		this.projectExamList = Array(1).fill({ ...EmptyProjectExamTransfer(), projectCode: code });
	};

	setProjectCode(code: string) {
		this.projectCode = code;
	}

	getExamByOrder(examOrder: number) {
		return this.projectExamList.find(exam => exam.order === examOrder);
	};

	getQuestionOrder(questionList: IQuestionInfo[], questionOrder: number) {
		return questionList.find(question => question.order === questionOrder);
	};

	addImageId(id: number) {
		this.addedImageIdList = [...this.addedImageIdList, id.toString()];
	};

	applyChangesToProjectExamListWithoutCheckConfirmForUpdate() {
		this.projectExamList = [...this.projectExamList];
	};

	applyChangesToProjectExamList() {
		this.projectExamList = [...this.projectExamList];
		this.changeIsConfirmForUpdateRequired(true);
	};

	convertExamFlagToUpdated(examOrder: number, changeFlag: ChangeFlag) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;
		if (selectedExam.changeFlag !== ChangeFlag.CREATED) { // 새로 만든 exam은 flag를 updated로 변경하지 않는다
			selectedExam.changeFlag = ChangeFlag.UPDATED;
		}

		this.applyChangesToProjectExamList();
	};

	convertQuestionFlagToUpdated(examOrder: number, questionOrder: number, changeFlag: ChangeFlag) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;
		if (selectedQuestion.changeFlag !== ChangeFlag.CREATED) { // 새로 만든 question은 flag를 updated로 변경하지 않는다
			selectedQuestion.changeFlag = changeFlag;
		}

		this.applyChangesToProjectExamList();
	};

	changeIsNewState(sw: boolean) {
		this.isNew = sw;
	}

	changeIsSubmittedState(sw: boolean) {
		this.isSubmitted = sw;
	}

	changeExamType(examOrder: number, type: ProjectExamType) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		selectedExam.type = type;

		this.convertExamFlagToUpdated(examOrder, ChangeFlag.UPDATED);
	};

	changeExamDirective(examOrder: number, directive: string) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		selectedExam.directive = directive;

		this.convertExamFlagToUpdated(examOrder, ChangeFlag.UPDATED);
	};

	changeExamParagraph(examOrder: number, paragraph: string) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		selectedExam.paragraph = paragraph;

		this.convertExamFlagToUpdated(examOrder, ChangeFlag.UPDATED);
	};

	changeExamSource(examOrder: number, source: string) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		selectedExam.source = source;

		this.convertExamFlagToUpdated(examOrder, ChangeFlag.UPDATED);
	};

	changeQuestionScore(examOrder: number, questionOrder: number, score: number) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.score = score;

		this.convertQuestionFlagToUpdated(examOrder, questionOrder, ChangeFlag.UPDATED);
	};

	changeQuestionContent(examOrder: number, questionOrder: number, questionHtml: string) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.question = questionHtml;
		this.convertQuestionFlagToUpdated(examOrder, questionOrder, ChangeFlag.UPDATED);
	};

	changeQuestionReference(examOrder: number, questionOrder: number, reference) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.reference = reference;

		this.convertQuestionFlagToUpdated(examOrder, questionOrder, ChangeFlag.UPDATED);
	};

	changeQuestionCorrectedAnswer(examOrder: number, questionOrder: number, answer: string) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.correctedAnswer = answer;

		this.convertQuestionFlagToUpdated(examOrder, questionOrder, ChangeFlag.UPDATED);
	};

	changeQuestionAiAnswer(examOrder: number, questionOrder: number, aiAnswer) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.aiAnswer = aiAnswer;

		this.convertQuestionFlagToUpdated(examOrder, questionOrder, ChangeFlag.UPDATED);
	};

	changeQuestionCorrectedKeyword(examOrder: number, questionOrder: number, key, keyword) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		const keywordObj = selectedQuestion.keywordArr.find(c => c.key === key);
		if (!keywordObj) return;

		keywordObj.keyword = keyword;

		this.applyChangesToProjectExamList();
	};

	changeIsConfirmForUpdateRequired(isRequired: boolean) {
		if (this.isConfirmForUpdateRequired === isRequired) return;

		this.isConfirmForUpdateRequired = isRequired;
	};

	checkKeywordDuplication(examOrder: number, questionOrder: number, myKey, keyword) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		const isDuplicated = selectedQuestion.keywordArr.some(c => c.key !== myKey && c.keyword === keyword);
		return isDuplicated;
	};

	getKeywordByKey(examOrder: number, questionOrder: number, myKey) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		const keywordObj = selectedQuestion.keywordArr.find(c => c.key === myKey);
		return keywordObj;
	};

	deleteKeyword(examOrder: number, questionOrder: number, key) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		const newKeywordArr = selectedQuestion.keywordArr.filter(c => c.key !== key);

		selectedQuestion.keywordArr = [...newKeywordArr];

		if (newKeywordArr.length === 0) {
			this.addKeyword(examOrder, questionOrder);
		}

		this.applyChangesToProjectExamList();
	};

	addKeyword(examOrder: number, questionOrder: number) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		const maxKeywordKey = selectedQuestion.keywordArr.reduce((acc, cur) => {
			return acc > cur.key ? acc : cur.key
		}, 0);

		// const keywordObjWithMaxIdx = selectedQuestion.keywordArr.find(c => c.idx === maxKeywordIdx);
		// if (!keywordObjWithMaxIdx.keyword) return;

		const nextKey = maxKeywordKey + 1;
		selectedQuestion.keywordArr = [...selectedQuestion.keywordArr, {
			key: nextKey,
			keyword: ""
		}];

		this.convertQuestionFlagToUpdated(examOrder, questionOrder, ChangeFlag.UPDATED);
	}

	initExamInitialParagraph(examOrder: number) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		selectedExam.initialParagraph = "";

		this.applyChangesToProjectExamListWithoutCheckConfirmForUpdate();
		// this.applyChangesToProjectExamList();
	};

	initQuestionInitialQuestion(examOrder: number, questionOrder: number) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.initialQuestion = "";

		this.applyChangesToProjectExamListWithoutCheckConfirmForUpdate();
		// this.applyChangesToProjectExamList();
	};

	initQuestionInitialReference(examOrder: number, questionOrder: number) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.initialReference = "";

		this.applyChangesToProjectExamListWithoutCheckConfirmForUpdate();
		// this.applyChangesToProjectExamList();
	};

	initQuestionInitialCorrectedAnswer(examOrder: number, questionOrder: number) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.initialCorrectedAnswer = "";

		this.applyChangesToProjectExamListWithoutCheckConfirmForUpdate();
		// this.applyChangesToProjectExamList();
	};

	changeQuestionInitialCorrectedAnswer(examOrder: number, questionOrder: number, answer: string) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const selectedQuestion = this.getQuestionOrder(selectedExam.questions, questionOrder);
		if (!selectedQuestion) return;

		selectedQuestion.initialCorrectedAnswer = answer;

		this.applyChangesToProjectExamList();
	};

	addQuestion(examOrder: number) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const nextOrder = selectedExam.questions
			.reduce((acc, cur) => {
				return acc > cur.order ? acc : cur.order;
			}, 0) + 1;

		const nextProjectExamQuestionNum =
			selectedExam.questions
				.reduce((acc, cur) => {
					return acc > cur.projectExamQuestionNum ? acc : cur.projectExamQuestionNum;
				}, 0) + 1;

		const newQuestion: IQuestionInfo = {
			...EmptyProjectExamQuestion,
			projectExamCode: selectedExam.code ? selectedExam.code : "",
			order: nextOrder,
			projectExamQuestionNum: nextProjectExamQuestionNum,
		};

		selectedExam.questions = [...selectedExam.questions, newQuestion];

		this.applyChangesToProjectExamList();
	};

	addExam() {
		if (!this.projectCode) return;

		const nextOrder = this.projectExamList
			.reduce((acc, cur) => {
				return acc > cur.order ? acc : cur.order;
			}, 0) + 1;

		const nextKey = this.projectExamList.reduce((acc, cur) => {
			return acc > cur.key ? acc : cur.key;
		}, 0) + 1;

		const newExam = {
			...EmptyProjectExamTransfer(),
			projectCode: this.projectCode,
			order: nextOrder,
			key: nextKey
		};

		this.projectExamList.push(newExam);

		// this.projectExamList = [...this.projectExamList, newExam];

		this.applyChangesToProjectExamList();
	};

	deleteExam(examOrder: number) {
		const validOrder = this.projectExamList.some(exam => exam.order === examOrder);
		if (!validOrder) return;

		// let updatedExamList;

		const isCreated = this.projectExamList.some(exam => exam.order === examOrder && exam.changeFlag === ChangeFlag.CREATED);
		if (isCreated) { // 새로 생성된 exam - 삭제 & 이후 exam order는 1씩 감소
			
			this.projectExamList = this.projectExamList
    								.filter(exam => !(exam.order === examOrder && exam.changeFlag === ChangeFlag.CREATED))
								    .map(exam => {
       									if (exam.order > examOrder && exam.changeFlag !== ChangeFlag.CREATED) {
            								exam.changeFlag = ChangeFlag.UPDATED;
        								}

										if (exam.order === examOrder) {
											exam.changeFlag = ChangeFlag.DELETED;
										}

        								if (exam.order > examOrder) {
            								exam.order -= 1;
        								}

        							return exam;
    								});
			

		} else {
			// 기존에 있던 exam - 하위 question들과 해당 exam 모두 flag를 deleted로 변경 & 이후 exam order는 1씩 감소
			this.projectExamList
				.find(exam => exam.order === examOrder)
				.questions.map(question => {
					question.changeFlag = ChangeFlag.DELETED;

					return question;
				});

			this.projectExamList = this.projectExamList
				.map(exam => {
					if (exam.order === examOrder) {
						exam.changeFlag = ChangeFlag.DELETED;
						exam.order = 0;
					}

					if (exam.order > examOrder && exam.changeFlag !== ChangeFlag.CREATED) {
						exam.changeFlag = ChangeFlag.UPDATED;
					}

					if (exam.order > examOrder) {
						exam.order -= 1;
					}

					return exam;
				});
		}

		// this.projectExamList = [...updatedExamList];
		// this.changeIsConfirmForUpdateRequired(true);

		this.applyChangesToProjectExamList();
	};

	deleteQuestion(examOrder: number, questionOrder: number) {
		const selectedExam = this.getExamByOrder(examOrder);
		if (!selectedExam) return;

		const validOrder = selectedExam.questions.some(question => question.order === questionOrder);
		if (!validOrder) return;

		const updatedQuestionList = selectedExam.questions
			.filter(question => !(question.order === questionOrder && question.changeFlag === ChangeFlag.CREATED)) // 새로 생성된 question - 삭제 && 이후 order은 1씩 감소
			.map(question => {
				if (question.order === questionOrder) { // 기존에 있던 question - flag를 deleted로 변경 && 이후 order은 1씩 감소
					question.changeFlag = ChangeFlag.DELETED;
					question.order = 0;
				}

				if (question.order > questionOrder && question.changeFlag !== ChangeFlag.CREATED) {
					question.changeFlag = ChangeFlag.UPDATED;
				}

				if (question.order > questionOrder) {
					question.order -= 1;
				}

				return question;
			})

		selectedExam.questions = [...updatedQuestionList];

		this.applyChangesToProjectExamList();
	};

	getImgIdList(html: string) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, 'text/html');

		const imgElements = doc.querySelectorAll('img');
		const imgIdList = Array.from(imgElements).map(img => {
			return img.getAttribute('alt');
		});

		return imgIdList;
	};

	processImgIdList(list: string[]) {
		let idList = [];
		list.map(html => {
			const list = this.getImgIdList(html);
			if (list.length === 0) return;

			idList = idList.concat(list);
		});

		return new Set(idList);
	};

	getHtmlListFromExamList(examList: IExamInfo[]) {
		const htmlList = [];

		examList
			.filter(e => e.changeFlag !== ChangeFlag.DELETED)
			.map((exam) => {
				htmlList.push(exam.paragraph);

				exam.questions
					.filter(q => q.changeFlag !== ChangeFlag.DELETED)
					.map((question) => {
						htmlList.push(question.question);
						htmlList.push(question.reference);
					});
			});

		return htmlList;
	};

	setQuestionFullOrderByQuestionListLen(questionListLen: number, examOrder: number, questionOrder: number) {
		if ((questionListLen && questionListLen > 1))
		// || questionOrder)
		{
			return `${examOrder}-${questionOrder}`;
		}

		return `${examOrder}`;
	};


	setQuestionCorrectedKeyword() {
		this.projectExamList.map(exam => {
			exam.questions.map(question => {
				let keywordStr;

				let uniqueKeywords = [];

				let keywordArr = question.keywordArr.filter(item => {
					if (item.keyword === '' || uniqueKeywords.includes(item.keyword)) return false;

					uniqueKeywords.push(item.keyword);
					return true;
				});

				if (keywordArr.length === 0) return;

				// keywordArr = Array.from(new Set(keywordArr));

				keywordArr.map(item => {
					if (!item.keyword) return;

					// if (idx < keywordArr.length - 1) {
					//   item.keyword += '.';
					// }

					if (!keywordStr) {
						keywordStr = item.keyword;

					} else {
						keywordStr += `.${item.keyword}`;
					}
				});
				question.correctedKeyword = keywordStr;
				question.keywordArr = [...keywordArr];
				question.changeFlag = ChangeFlag.UPDATED;

				return question;
			})
		})
	};

	async invalidExamAndQuestion(): Promise<{ isInvalid: boolean, message?: string }> {
		const examList = toJS(this.projectExamList);
		for (let i = 0; i < examList.length; i++) {
			const exam = examList[i];

			//#region 문제 자체에 문제가 있는 경우
			if (!DataUtils.getTextFromHtml(exam.paragraph).trim() && !(DataUtils.getTagArrFromHtml(exam.paragraph, 'img').length > 0) && exam.source) { // 출처가 있는데 지문이 없는 경우

				
				// alert(`${exam.order}번 문제 : 출처를 입력한 경우, 지문을 입력해야 합니다.`);
				return { isInvalid: true, message: i18next.t(
					"{{order}}번 문제 : 출처를 입력한 경우, 지문을 입력해야 합니다.", { order: exam.order }
				)};
			}

			// if ((DataUtils.getTextFromHtml(exam.paragraph).trim() || DataUtils.getTagArrFromHtml(exam.paragraph, 'img').length > 0) && !exam.source) { // 지문은 있는데 출처가 없는 경우
			// 	this.messageStore.alert(
			// 		i18next.t(
			// 			"{{order}}번 문제 : 출처를 입력해주세요.", { order: exam.order }
			// 		));
			// 	return true;
			// }
			//#endregion



			//#region 문제별 문항에 문제가 있는 경우
			for (let j = 0; j < exam.questions.length; j++) {
				const q = exam.questions[j];

				let questionFullOrder = `${exam.order}`;
				if (exam.questions.length > 1) {
					questionFullOrder = `${exam.order}-${q.order}`
				}

				const tagArrayFromHtml = DataUtils.getTagArrFromHtml(q.question, 'img');
				const condition1 = tagArrayFromHtml.length === 0;
				const textFromHtml = DataUtils.getTextFromHtml(q.question);
				const condition2 = !textFromHtml.trim();
				const condition3 = !q.question;

				if (condition1 && condition2
					|| condition3) {
					// alert(`${questionFullOrder}번 문제 : 문항을 입력해주세요.`);
					
					return { isInvalid: true, message: i18next.t(
						"{{questionFullOrder}}번 문제 : 문항을 입력해주세요.", { questionFullOrder }
					)};

				} else if (q.score <= 0) {
					// alert(`${questionFullOrder}번 문제 : 점수를 입력해주세요.`);
					return { isInvalid: true, message: i18next.t(
						"{{questionFullOrder}}번 문제 : 점수를 입력해주세요.", { questionFullOrder }
					)};
				}
			}
			//#endregion
		}

		// this.projectExamList.map(exam => {

		// });

		return { isInvalid: false};
	};

	removeEmptyQuestionReference() {
		this.projectExamList.map(exam => {
			exam.questions.map(q => {
				if (DataUtils.getTagArrFromHtml(q.reference, 'img').length === 0 && !DataUtils.getTextFromHtml(q.reference).trim()) {
					q.reference = '';

					return q;
				}
			})

			return exam;
		});

		this.applyChangesToProjectExamList();
	};

	async invalidAnswerAndKeyword(): Promise<{ isInvalid: boolean, message?: string }> {
		let invalid = false;
	  
		// Iterate through exams
		for (const exam of this.projectExamList) {
		  if (invalid) break; // If invalid is already true, exit the loop early
	  
		  // Iterate through questions
		  for (const question of exam.questions) {
			if (invalid) break; // If invalid is already true, exit the loop early
	  
			let questionFullOrder = `${exam.order}`;
			if (exam.questions.length > 1) {
			  questionFullOrder = `${exam.order}-${question.order}`;
			}
	  
			// Check for invalid answer
			if (
			  (DataUtils.getTagArrFromHtml(question.correctedAnswer, 'img').length === 0 &&
				!DataUtils.getTextFromHtml(question.correctedAnswer).trim()) ||
			  !question.correctedAnswer
			) {
			  invalid = true;
			  // Return an object with isInvalid and message when invalid answer is found
			  return {
				isInvalid: true,
				message: i18next.t("{{questionFullOrder}}번 문제 : 답안을 입력해 주세요.", { questionFullOrder })
			  };
			}
	  
			// Optionally handle other conditions (e.g., correctedKeyword) if needed
			// else if (!question.correctedKeyword) {
			//   invalid = true;
			//   return {
			//     isInvalid: true,
			//     message: `${questionFullOrder}번 문제 : 답변 필수 문장을 입력해주세요.`
			//   };
			// }
		  }
		}
	  
		// If no invalid answers were found, return success with undefined message
		return { isInvalid: false };
	  }
	  

	// eslint-disable-next-line require-await
	getImgeFileIdListToBeDeleted() {
		if (this.isNew && this.addedImageIdList.length > 0) { // create
			if (this.isSubmitted) {
				const createdIdSet = this.processImgIdList(this.getHtmlListFromExamList(this.projectExamList));
				const addedIdSet = new Set(this.addedImageIdList);

				return Array.from(new Set(Array.from(addedIdSet).filter(id => !createdIdSet.has(id))));

			} else {
				return this.addedImageIdList;
			}

		} else { // update
			if (this.isSubmitted) {
				const oriIdSet = this.processImgIdList(this.getHtmlListFromExamList(this.oriProjectExamList));
				const addedIdSet = new Set(this.addedImageIdList);
				const allIdSet = new Set([Array.from(oriIdSet), Array.from(addedIdSet)]);

				const updatedIdSet = this.processImgIdList(this.getHtmlListFromExamList(this.projectExamList));

				return Array.from(new Set(Array.from(allIdSet).filter(id => !updatedIdSet.has(id))));

			} else if (this.addedImageIdList.length > 0) {
				return this.addedImageIdList;
			}
		}

		return [];
	};

	setKeywordArr(keywordStr: string) {
		const preKeywordArr = keywordStr.split('.');
		const keywordArr = [];

		preKeywordArr.map((keyword, idx) => {
			keywordArr.push({
				key: idx,
				keyword
			})
		});

		return keywordArr;
	};


	async downloadProjectExamListAndStore(projectCode: string) {
		try {
			this.isSelectingProjectExamList = true;

			if (!projectCode) {
				return;
			}

			const oriExamList = await this.projectRepository.getProjectExamList(projectCode) as IExamInfo[];
			runInAction(() => {
				if (!oriExamList || oriExamList.length === 0) {
					this.changeIsNewState(true);
					this.initProjectExamListWithProjectCode(projectCode);
					return;
				}

				this.oriProjectExamList = oriExamList;
				const examList = oriExamList.map((exam) => {
					exam.key = exam.order;
					exam.changeFlag = ChangeFlag.NONE;
					exam.initialParagraph = exam.paragraph;

					const mapped = exam.questions.map((q) => {
						q.changeFlag = ChangeFlag.NONE;
						q.initialQuestion = q.question;
						q.initialCorrectedAnswer = q.correctedAnswer;
						q.initialReference = q.reference;

						if (q.correctedKeyword) {
							q.keywordArr = this.setKeywordArr(q.correctedKeyword);
						} else {
							q.keywordArr = [{ key: 0, keyword: "" }];
						}

						// q.correctedAnswer = "";
						// q.correctedKeyword = "";

						return q;
					});

					mapped.sort((a, b) => {
						return a.order - b.order
					});

					return exam;
				}).sort((a, b) => a.order - b.order);

				this.projectExamList = examList;
				this.changeIsNewState(false);
				console.log(LogPrefix, "Success getProjectExamList ...", examList);
			});

			if (!oriExamList || oriExamList.length === 0) {
				return false;
			}
			return true;

		} catch (e) {
			console.log(LogPrefix, "Cannot getProjectExamList ...", e);
		} finally {
			runInAction(() => {
				this.isSelectingProjectExamList = false;
			});
		}
	};

	async createProjectExamList(): Promise<{ message?: string, success: boolean }> {
		try {
			this.isCreatingProjectExamList = true;

			const invalid = await this.invalidExamAndQuestion();
			if (invalid.isInvalid) {
				return {message: invalid.message, success:false};
			}

			this.removeEmptyQuestionReference();

			if (!this.projectCode) {
				return {message: null, success:false};
			}

			const response = await this.projectRepository.createProjectExamList(this.projectCode, this.projectExamList);

			const ret = runInAction(() => {
				if (!response) {
					this.changeIsSubmittedState(false);
					return false;
				}

				this.changeIsSubmittedState(true);
				console.log(LogPrefix, "Success createExamList ...", response);
				return true;
			});

			return {message: null, success:ret};

		} catch (e) {
			console.log(LogPrefix, "Cannot createExamList ...", e);
			return {message: null, success:false};;
		} finally {
			this.isCreatingProjectExamList = false;
		}
	};


	async updateProjectExamListSteve(calledFrom: ProjectExamListUpdateType): Promise<{ message: string, success: boolean }> {
		try {
			this.isUpdatingProjectExamList = true;

			if (!calledFrom) {
				return {message: null, success:false};
			}

			if (calledFrom === ProjectExamListUpdateType.QUESTION) {
				this.removeEmptyQuestionReference();
			}

			if (calledFrom === ProjectExamListUpdateType.ANSWER) {
				this.setQuestionCorrectedKeyword();
			}

			if (calledFrom === ProjectExamListUpdateType.QUESTION) {

				const invalidObject = (await this.invalidExamAndQuestion());
				const isInvalid = invalidObject.isInvalid;
				const invalidString = invalidObject.message;

				if (isInvalid) {
					return {message: invalidString, success:false};
				}
			} else {
				const isInvalidObject = await this.invalidAnswerAndKeyword();

				if (isInvalidObject && isInvalidObject.isInvalid) {
					return {message: isInvalidObject.message, success: !isInvalidObject.isInvalid};
				}
				
			}

/*
			// 이전에 DB에 있던 내용을 지운다
			const prevProject = await this.projectRepository.getProjectExamList(this.projectCode) as IExamInfo[];

			for (const exam of prevProject) {
				exam.changeFlag = ChangeFlag.DELETED;
				const { questions } = exam;
				for (const question of questions) {
					question.changeFlag = ChangeFlag.DELETED;
				}
			}
			await this.projectRepository.updateProjectExamList(this.projectCode, prevProject);
*/

			// 새로운 내용을 넣는다.
			const data = toJS(this.projectExamList);
			/*
			for (const exam of data) {
				exam.changeFlag = ChangeFlag.UPDATED;
				const { questions } = exam;
				for (const question of questions) {
					question.changeFlag = ChangeFlag.UPDATED;
				}
			}
*/
			const response = await this.projectRepository.updateProjectExamList(this.projectCode, data);

			const ret = runInAction(() => {
				if (!response) {
					this.changeIsSubmittedState(false);
					return false;
				}

				this.changeIsSubmittedState(true);
				console.log(LogPrefix, "Success updateExamList ...", response);
				return true;
			});

			return {message: null, success:ret};
		} catch (e) {
			console.log(LogPrefix, "Cannot updateExamList ...", e);
			return {message: null, success:false};
		} finally {
			runInAction(() => {
				this.isUpdatingProjectExamList = false;
			});
		}
	};

	async updateCorrectedAnswer(examCode: string, questionNum: number, answer: string) {
		try {
			
			// ✅ Create an array with a single `IExampleAnswerInfo` object
			const exampleAnswers: IExampleAnswerInfo[] = [
				{
					examCode: examCode,
					questionNum: questionNum,
					exampleAnswer: answer
				}
			];
	
			console.log("Example Answers Array:", exampleAnswers);
			await this.projectRepository.updateExampleAnswer(exampleAnswers);
			return true;

		} catch (e) {
			console.error('Failed to update answer:', e);
			return false;
		}
	}

/*
	async createAIAnswer(examCode: string, questionNum: number, language: string) {
		try {
			await this.projectExamQuestionRepository.createAIAnswer(examCode, questionNum, language);
			console.log(LogPrefix, "Success createAIAnswer ...");

		} catch (e) {
			console.log(LogPrefix, "Cannot createAIAnswer ...", e);
		}
	};

	async getAIAnswer(examCode: string, questionNum: number) {
		try {
			const response = await this.projectExamQuestionRepository.getAIAnswer(examCode, questionNum);
			console.log(LogPrefix, "Success getAIAnswer ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot getAIAnswer ...", e);
		}
	}
*/
	/*
	async getProjectFiles(projectCode: string) {
		try {
			const response = await this.projectExamQuestionRepository.getProjectFiles(projectCode);
			console.log(LogPrefix, "Success getProjectFiles ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot getProjectFiles ...", e);
		}
	}

	async getProjectFile(projectCode: string, fileId: number) {
		try {
			const response = await this.projectExamQuestionRepository.getProjectFile(projectCode, fileId);
			console.log(LogPrefix, "Success getProjectFile ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot getProjectFile ...", e);
		}
	}

	async uploadProjectPdfFile(projectCode: string, file: File) {
		try {
			const response = await this.projectExamQuestionRepository.uploadProjectPdfFile(projectCode, file);
			console.log(LogPrefix, "Success uploadProjectPdfFile ...", response);
			return response;

		} catch (e) {
			console.log(LogPrefix, "Cannot uploadProjectPdfFile ...", e);
		}
	}
*/
}
