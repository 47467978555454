import { Box, Button, CssBaseline, Dialog, LinearProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import useEmotionStyles from "../../../../styles/useEmotionStyles";
import { DownloadNativePopupStyle } from "../../../../styles/DownloadNativePopupStyle";

import CircularProgress from '@mui/material/CircularProgress';
import React from "react";
import { appUrl } from "../../../../../../appConfig";
import useOSAndBrowserInfo from "../../../../../../hooks/useOSVersion";
import { OperatingSystemInfo } from "../../../../../../neolab-libs/nl-lib3-common";
import { NativeMethods } from "../../../../../../neolab-libs/nl-lib3-pengateway/src/nl-lib-native-methods/NativeMethods";
import { acrobatReaderUrl, fetchDownloadUrl, hwpToWordConverterUrl, msWordDownloadUrl } from "../../../../00_SignUp_SignIn/3_DownloadNativeApp/DownloadPage";



type Props = {
	onClose: () => void;
	onNativeAvailibilityChanged?: (sw: boolean) => void;
};


export function DownloadNativePopup(props: Props) {
	const i18next = useTranslation();
	const classes = useEmotionStyles(DownloadNativePopupStyle);

	const { onClose, onNativeAvailibilityChanged } = props;
	const [open, setOpen] = React.useState(true);

	const osAndBrowser = useOSAndBrowserInfo(appUrl, open);

	const [isAutomationInstalled, setIsAutomationInstalled] = React.useState<null | "false" | "true" | "unknown">(null);
	const [isWordInstalled, setIsWordInstalled] = React.useState<null | "false" | "true" | "unknown">(null);
	
	const [automationAppServerVersion, setAutomationAppServerVersion] = React.useState<string>(null);
	const [automationAppClientVersion, setAutomationAppClientVersion] = React.useState<string>(null);
	const [shouldUpgrade, setShouldUpgrade] = React.useState<boolean>(false);


	React.useEffect(() => {
		fetchDownloadUrl().then(({ version }) => {
			// a.b.c.d 와 a.b.c.d 를 비교
			const serverVersion = version.split('.');
			while (serverVersion.length < 4) {
				serverVersion.push('0');
			}
			const sv = serverVersion.join('.');
			setAutomationAppServerVersion(sv);
		});
	}, []);


	React.useEffect(() => {
		if (!automationAppServerVersion || !automationAppClientVersion) {
			return
		}

		// a.b.c.d 와 a.b.c.d 를 비교
		const serverVersion = automationAppServerVersion.split('.');
		while (serverVersion.length < 4) {
			serverVersion.push('0');
		}

		const clientVersion = automationAppClientVersion.split('.');
		while (clientVersion.length < 4) {
			clientVersion.push('0');
		}

		const sv = serverVersion.map((v, i) => { return parseInt(v) });
		const cv = clientVersion.map((v, i) => { return parseInt(v) });

		let upgrade = false;
		for (let i = 0; i < 4; i++) {
			if (sv[i] < cv[i]) { break; }
			if (sv[i] > cv[i]) {
				upgrade = true;
				break;
			}
		}

		setShouldUpgrade(upgrade);
	}, [automationAppServerVersion, automationAppClientVersion]);



	React.useEffect(() => {
		const checkAutomation = async () => {
			let ret = null;
			const version = await NativeMethods.GetAutomationVersion();
			if (version) {
				ret = true;
				setAutomationAppClientVersion(version);
				setIsAutomationInstalled("true");
			} else {
				ret = false;
				setIsAutomationInstalled("false");
			}

			return ret;
		};

		const checkWord = async () => {
			const ret = await NativeMethods.IsWordInstalled();
			if (typeof ret === "boolean") {
				setIsWordInstalled(ret ? "true" : "false");
			}
			else if (!ret) {
				setIsWordInstalled("unknown");
			}
			return ret;
		}

		// 매 5초마다 체크
		const intervalId = setInterval(() => {
			if (isAutomationInstalled === "true" && isWordInstalled === "true") {
				clearInterval(intervalId);
				return;
			}

			if (isAutomationInstalled !== "true") {
				checkAutomation().then((ret) => {
					if (ret === true) {
						if (isWordInstalled !== "true") {
							setIsWordInstalled(null);
							checkWord();
						}
						
					} else {
						setIsWordInstalled("unknown");
					}
				});
			}
		}, 5000);

		return () => clearInterval(intervalId); // 상태 변화마다 이전 타이머 정리

	}, [isAutomationInstalled, isWordInstalled]);



	const handleClickDownload = async () => {
		const ret = await fetchDownloadUrl();
		window.location.href = ret.downloadUrl; // '/WordAutomationSetup.msi';
	}

	const handleClickDownloadAcrobat = () => {
		window.location.href = acrobatReaderUrl;
	}

	const handleClickDownloadHwpToWord = () => {
		window.location.href = hwpToWordConverterUrl;
	}

	const handleClickDownloadMsword = () => {
		window.location.href = msWordDownloadUrl;
	}


	const goBack = () => {
		setOpen(false);
		onClose();
	}

	const stateToString = (state: null | "false" | "true" | "unknown") => {
		switch (state) {
			case "unknown":
				return i18next.t(`파악안됨`);
			case "true":
				return i18next.t(`OK`);
			case "false":
				return i18next.t("다운로드");
			default:
				return i18next.t("(확인 중)");
		}
	}

	const appStateToString = (state: null | "false" | "true" | "unknown") => {
		switch (state) {
			case "true":
				{
					if (shouldUpgrade) {
						return i18next.t(`{{ automationAppClientVersion }} 사용 중, {{ automationAppServerVersion }}으로 업데이트 필요`, {
							automationAppClientVersion, automationAppServerVersion
						});
					}
					return i18next.t(`최신 버전{{ automationAppClientVersion }} 사용 중 OK`, { automationAppClientVersion });
				}
			case "false":
				return i18next.t("실행되고 있지 않음, 다운로드");
			default:
				return i18next.t("(확인 중)");
		}
	}

	React.useEffect(() => {
		onNativeAvailibilityChanged?.(osAndBrowser?.isWebSocketConnected);
	}, [osAndBrowser.isWebSocketConnected]);


	return (
		<Dialog
			open={
				open &&
				osAndBrowser.os === OperatingSystemInfo.Windows
				&& !osAndBrowser.isWebSocketConnected
			}
			onClose={() => setOpen(false)}
		>
			<div>
				<CssBaseline />
				{/*<TopBar leftItem={" "} rightItem={" "}/>*/}
				<Box className={classes.body}>
					<Box className={classes.center}>
						<Box className={classes.main}>

							{osAndBrowser.focusOut &&
								<Box className={classes.clickUpper}>
									{i18next.t("↑↑↑ 위의  [NeoStudio2Lite 열기]를 클릭해주세요 ↑↑↑")}
								</Box>
							}


							<Box className={classes.title}>
								{i18next.t("Automation 도구 다운로드")}
							</Box>

							<Box className={classes.detail}>
								<Trans i18nKey="다운로드되는 Automation 도구는 스마트펜 자동 연결 등에 사용되는 자동화 도구입니다.">
									자동화 도구를 다운로드해야 문자 인식 등이 제대로 서비스됩니다.
									< br />
									아래의 버튼을 눌러 설치 프로그램을 모두 다운로드하여, 설치한 다음 아이글에 다시 접속하십시오.
									<br />
									<br />
									모든 프로그램이 OK이면 별도의 다운로드와 설치의 필요는 없습니다.
								</Trans>
							</Box>

							<Box className={classes.loginBox}>
								<LinearProgress />

								<Box className={clsx(classes.loginPageBtn, classes.loginPageBtnActive)}>
									<Button
										style={{ textTransform: "none" }}
										// disabled={isAutomationInstalled === "true"}
										onClick={handleClickDownload}
										disableRipple
									>
										<Typography sx={{ marginRight: "10px" }}>
											{isAutomationInstalled === null && <CircularProgress size={16} sx={{ marginRight: "10px" }} />}
											{i18next.t("NeoStudio2Lite")}
										</Typography>
										<Typography style={{ fontWeight: 500 }}>
											{appStateToString(isAutomationInstalled)}
										</Typography>
									</Button>
								</Box>


								<Box className={clsx(classes.loginPageBtn, classes.loginPageBtnActive)}>
									<Button
										style={{ textTransform: "none" }}
										disabled={isWordInstalled === "true"}
										onClick={handleClickDownloadMsword}
										disableRipple
									>
										<Typography sx={{ marginRight: "10px" }}>
											{isWordInstalled === null && <CircularProgress size={16} sx={{ marginRight: "10px" }} />}
											{i18next.t("Microsoft Word")}
										</Typography>
										<Typography style={{ fontWeight: 500 }}>
											{stateToString(isWordInstalled)}
										</Typography>
									</Button>
								</Box>

								<Box>
									<Button onClick={goBack} disableRipple className={classes.backBtn} >
										<Typography>{i18next.t("돌아가기")}</Typography>
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</div>
		</Dialog >
	)
}
