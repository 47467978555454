import {
	Box,
	Button,
	IconButton,
	Paper,
	Popper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import clsx from "clsx";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ReactComponent as AddPlusIcon } from '../../../../common/images/AddPlusIcon.svg';
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { ReactComponent as SelectIconUp } from '../../../../common/images/SelectIconUp.svg';
import { ReactComponent as TooltipClose } from '../../../../common/images/TooltipClose.svg';
import { ReactComponent as TooltipTipIcon } from '../../../../common/images/TooltipTipIcon.svg';
import { ReactComponent as TrashDeleteIcon } from '../../../../common/images/TrashDeleteIcon.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { RubricStyle } from "../../styles/RubricStyle";


import React from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../../../lang';
import { IQuestionInfo } from "../../../../repositories/model/transfer/IQuestionInfo";
import { IRubricInfo } from "../../../../repositories/model/transfer/IRubricInfo";
import { ChangeFlag } from "../../../../repositories/model/support/ChangeFlag";
import { useStores } from "../../../../hooks/useStores";
import { useNeoConfirm } from "../../../../hooks/useNeoConfirm";


const minumumStandardCount = 3;
const maxStandardCount = 5;



type Props = {
	questionInfo: IQuestionInfo,
	rubricInfo: IRubricInfo,
	questionIdx: number,
	mounted: boolean,
	handleSetMountedFalse,
	countOpenTooltip,
	handleIncreaseCountOpenTooltip,
	requestingAIRubric: boolean
}


export const ProjectRubricTable = (props: Props) => {
	const i18next = useTranslation();
	const classes = useEmotionStyles(RubricStyle);
	const { questionInfo, rubricInfo, requestingAIRubric } = props;
	const { projectRubricStore } = useStores();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openTooltipWhenMount, setOpenTooltipWhenMount] = React.useState(false);

	const anchorRef = React.useRef(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	const {alert, confirm} = useNeoConfirm();

	const handleChangeProjectRubricName = (e) => {
		const name = e.target.value;
		projectRubricStore.changeProjectRubricName(rubricInfo.key, name);
	};

	const checkProjectRubricNameDuplicated = async () => {
		const isDuplicated = projectRubricStore.checkProjectRubricNameDuplicated(rubricInfo.key, questionInfo);

		return false;
		if (!isDuplicated) return false;

		await alert(i18n.t("동일한 평가항목명이 이미 있습니다."));
		return true;
	};

	const handleBlurProjectRubricName = async (e) => {
		checkProjectRubricNameDuplicated()
			.then(result => {
				if (!result) return;

				e.target.focus();
			})
	};

	const handleChangeProjectRubricScore = async (e) => {
		const score = e.target.value;
		const regForZeroOrMore = /^\d+$/;
		if (score && !regForZeroOrMore.test(score)) return;

		const invalidMessage = projectRubricStore.changeProjectRubricScore(rubricInfo.key, Number(score), questionInfo);

		if (invalidMessage) {
			await alert(i18n.t(invalidMessage));
		}
	};

	const handleClickIncreaseProjectRubricScore = () => {
		projectRubricStore.increaseProjectRubricScore(rubricInfo.key, questionInfo);
	};

	const handleClickDecreaseProjectRubricScore = () => {
		projectRubricStore.decreaseProjectRubricScore(rubricInfo.key, questionInfo);
	};

	const handleChangeProjectRubricItemName = (e, rubricItemNum) => {
		const standard = e.target.value;
		projectRubricStore.changeProjectRubricItemName(rubricInfo.key, rubricItemNum, standard);
	};

	const handleChangeProjectRubricItemStandard = (e, rubricItemNum) => {
		const standard = e.target.value;
		projectRubricStore.changeProjectRubricItemStandard(rubricInfo.key, rubricItemNum, standard);
	};

	const handleChangeProjectRubricItemScore = (e, rubricItemNum) => {
		const score = e.target.value;
		const regForZeroOrMore = /^\d+$/;
		if (score && !regForZeroOrMore.test(score)) return;

		projectRubricStore.changeProjectRubricItemScore(rubricInfo.key, rubricItemNum, score, questionInfo);
	};

	const handleClickIncreaseProjectRubricItemScore = async (e, rubricItemNum) => {
		const invalidMessage = projectRubricStore.increaseProjectRubricItemScore(rubricInfo.key, rubricItemNum, questionInfo);
		if (invalidMessage) {
			await alert(i18n.t(invalidMessage));
		}
	};

	const handleClickDecreaseProjectRubricItemScore = async (e, rubricItemNum) => {
		const invalidMessage = projectRubricStore.decreaseProjectRubricItemScore(rubricInfo.key, rubricItemNum, questionInfo);
		if (invalidMessage) {
			await alert(i18n.t(invalidMessage));
		}
	};

	const handleSetTooltipAnchorEl = () => {
		if (anchorRef.current) {
			setAnchorEl(anchorRef.current);
		}
	};

	const handleClickTooltipCloseBtn = () => {
		setOpenTooltipWhenMount(false);
		setAnchorEl(null);
	};

	const handleMouseEnterInTooltip = () => {
		handleSetTooltipAnchorEl();
	};

	const handleMouseLeaveFromTooltip = () => {
		if (openTooltipWhenMount) return;

		setAnchorEl(null);
	};

	const handleClickAddStandard = () => {
		projectRubricStore.addProjectRubricItem(rubricInfo.key);
	};

	const handleClickDeleteRubricItem = (_, itemIdx) => {
		confirm(i18next.t("선택한 평가기준(배점과 채점기준)을 삭제하시겠습니까?"))
			.then(result => {
				if (!result) return;

				projectRubricStore.deleteProjectRubricItem(rubricInfo.key, itemIdx);
			});
	};

	return (
		<Box sx={{ padding: '0px 20px' }}>
			<TableContainer component={Paper} className={classes.tableBox}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell width='28%' align="center">{i18next.t("평가항목")}</TableCell>
							<TableCell width='27%' colSpan={2} align="center">{i18next.t("배점(점)")}</TableCell>
							<TableCell width='55%' align="center">{i18next.t("채점 기준")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow >
							{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
							<TableCell width='28%' rowSpan={rubricInfo?.items?.length + 2 || 0}
								align="center" className={clsx(classes.visible, classes.tableCellRubricInfoName)}>
								<Box display='flex' flexDirection='column' alignItems='center'
									className={classes.boxRubricInfoName}>
									<TextField
										className={classes.textFieldRubricInfoName}
										inputProps={{ className: classes.textFieldInputRubricInfoName }}
										id="outlined-basic"
										variant="outlined"
										multiline
										minRows={1}
										maxRows={13}
										disabled={requestingAIRubric}
										value={rubricInfo.name}
										onChange={handleChangeProjectRubricName}
										onBlur={handleBlurProjectRubricName}
										placeholder={i18next.t("평가항목 입력")}
									/>

									<Box display='flex' alignItems='center' justifyContent='left' sx={{ marginRight: "10px", marginTop: "10px" }}>
										<Box className={classes.dialogBox} >
											<Popper id={id} open={open} anchorEl={anchorEl}
												disablePortal={true}
												placement="right"
												// modifiers={{
												// 	flip: {
												// 		enabled: false,
												// 		options: {
												// 			altBoundary: false,
												// 			rootBoundary: 'document',
												// 			padding: 8,
												// 		},
												// 	},
												// }}
												className={classes.popoverTooltip}
											>
												<Box className={classes.tooltipTop}>
													<TooltipTipIcon />
												</Box>
												<Box display={'flex'} justifyContent={'space-between'} className={clsx(classes.tooltipBtn, classes.supportTooltipBtn)}>
													<Box alignItems={'center'}>
														<Typography className={clsx(classes.tooltipTitle, classes.scoreTooltipTitle)}>{i18next.t("배점 입력")}</Typography>
													</Box>
													{openTooltipWhenMount &&
														<Button
															onClick={handleClickTooltipCloseBtn}
															disableRipple>
															<TooltipClose />
														</Button>
													}
												</Box>
												<Typography className={classes.tooltipText}>
													{i18next.t("문항에 부여된 점수를 초과할 수 없습니다.")}<br />
													<span>{i18next.t("'과제 평가 기준표'가 추가되어도 평가 항목의 배점은 문항 점수 내에서 입력해야 합니다.")}</span>
												</Typography>
											</Popper>

											<TextField
												id="outlined-basic"
												ref={anchorRef}
												variant="outlined"
												value={rubricInfo.score}
												onChange={handleChangeProjectRubricScore}
												onMouseEnter={handleMouseEnterInTooltip}
												onMouseLeave={handleMouseLeaveFromTooltip}
												placeholder={i18next.t("0")}
												inputProps={{ className: classes.textFieldScoreNumber }}
												aria-describedby={id}
											/>

											<Box className={classes.yearSelectIcon}>
												<IconButton
													className={clsx(classes.iconBtnReset, classes.iconYearSelect)}
													onClick={handleClickIncreaseProjectRubricScore}
													disableRipple>
													<SelectIconUp />
												</IconButton>
												<IconButton
													className={clsx(classes.iconBtnReset, classes.iconYearSelect)}
													onClick={handleClickDecreaseProjectRubricScore}
													disableRipple>
													<SelectIcon />
												</IconButton>
											</Box>
										</Box>
										<Typography className={classes.fontRubricPointText}>{i18next.t("점")}</Typography>
									</Box>
								</Box>
							</TableCell>
						</TableRow>

						{rubricInfo.items.length > 0 &&
							rubricInfo.items
								.filter(item => item.changeFlag !== ChangeFlag.DELETED)
								.map((item, idx) => {
									return (
										<TableRow key={idx} sx={{}}>
											<TableCell align="center" className={classes.tableCellRubricItemName}>
												<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti, classes.boxRubricItemName)} style={{ padding: 0 }}>
													<TextField
														className={classes.textFieldRubricItemName}
														id="outlined-basic"
														variant="outlined"
														inputProps={{ style: { textAlign: 'center' } }}
														value={item.name}
														onChange={e => handleChangeProjectRubricItemName(e, item.rubricItemNum)}
														placeholder={i18next.t("입력")}
													/>
												</Box>
											</TableCell>

											<TableCell className={classes.tableCellRubricPoint}>
												<Box>
													<Box display='flex' alignItems='center' justifyContent='center'>
														<Box className={classes.scoreBox}>
															<TextField
																id="outlined-basic"
																variant="outlined"
																value={item.score}
																onChange={e => handleChangeProjectRubricItemScore(e, item.rubricItemNum)}
																placeholder={i18next.t("0")}
																inputProps={{ className: classes.textFieldScoreNumber }}
															/>
															<Box className={classes.yearSelectIcon}>
																<IconButton
																	className={clsx(classes.iconBtnReset, classes.iconYearSelect)}
																	onClick={e => handleClickIncreaseProjectRubricItemScore(e, item.rubricItemNum)}
																	disableRipple>
																	<SelectIconUp />
																</IconButton>
																<IconButton
																	className={clsx(classes.iconBtnReset, classes.iconYearSelect)}
																	onClick={e => handleClickDecreaseProjectRubricItemScore(e, item.rubricItemNum)}
																	disableRipple>
																	<SelectIcon />
																</IconButton>
															</Box>
														</Box>
													</Box>
												</Box>
											</TableCell>

											<TableCell colSpan={3} align="left" 
												className={clsx(classes.borderLine, classes.tableCellRubricStandard)}>
												<Box className={clsx(classes.scoreMarkInput, classes.scoreMarkInputMulti, classes.boxRubricStandard)}>
													{requestingAIRubric ?
														<>
															<Box className={classes.skeletonShortUI} />
															<Box style={{ height: '10px' }} />
															<Box className={classes.skeletonLongUI} />
															<Box style={{ height: '10px' }} />
															<Box className={classes.skeletonMiddleUI} />
														</>
														:
														<Box className={classes.standardTextFieldBox} sx={{minHeight:'100px'}}>
															<TextField
																className={classes.textFieldRubricStandard}
																id="outlined-basic"
																variant="outlined"
																multiline
																value={item.standard}
																onChange={e => handleChangeProjectRubricItemStandard(e, item.rubricItemNum)}
																placeholder=""
																sx={{
																	"& .MuiOutlinedInput-root": {
																	  paddingTop: "50px",
																	  paddingBottom: "10px",
																	},
																	"& .MuiOutlinedInput-input": {
																	  lineHeight: "1.5",
																	},
																  }}
															/>
															<Box className={classes.deleteBoxWrap}>
																{rubricInfo.items.length > minumumStandardCount &&
																	<Tooltip
																		title={i18next.t("평가기준 삭제")}
																		classes={{ tooltip: classes.deleteTooltip }}
																	>
																		<Box className={classes.deleteBox} onClick={e => handleClickDeleteRubricItem(e, idx)}>
																			<TrashDeleteIcon />
																		</Box>
																	</Tooltip>
																}
															</Box>
														</Box>
													}
												</Box>
											</TableCell>

										</TableRow>
									)
								})
						}

						{rubricInfo.items.length < maxStandardCount &&
							<TableRow >
								{/* <TableRow sx={{ '&:last-child .MuiTableCell-body': { border: '0px !important' } }}> */}
								<TableCell align="center" className={classes.addStandardCell} colSpan={3}>
									<Box className={classes.addBox} style={{ padding: 0 }}>
										<Box className={classes.addStandardBox}
											onClick={e => handleClickAddStandard()}>
											<Box className={classes.plusBtn}>
												<AddPlusIcon />
											</Box>
											<Typography>{i18next.t("평가 기준 추가")}</Typography>
										</Box>
									</Box>
								</TableCell>
							</TableRow>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}
