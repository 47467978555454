import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {
	Box,
	IconButton,
	TextField,
	Typography,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	FormControlLabel,
	Checkbox
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as SelectIcon } from '../../../../common/images/SelectIcon.svg';
import { ReactComponent as SelectIconUp } from '../../../../common/images/SelectIconUp.svg';
import { ReactComponent as ToggleIcon } from '../../../../common/images/ToggleIcon.svg';
import { ReactComponent as TrashDeleteIcon } from '../../../../common/images/TrashDeleteIcon.svg';
import useEmotionStyles from "../../styles/useEmotionStyles";
import { ExamRangeUserStyle } from "../../styles/ExamRangeUserStyle";

import { toJS } from 'mobx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextEditor from "../../../../components/common/TextEditor";
import { useStores } from '../../../../hooks/useStores';
import { IQuestionInfo } from '../../../../repositories/model/transfer/IQuestionInfo';
import { observer } from 'mobx-react';
import { useNeoConfirm } from '../../../../hooks/useNeoConfirm';
import { CheckCircle as CheckCircleIcon, RadioButtonUnchecked as CheckCircleIconOff } from '@mui/icons-material';
import { SampleQuestionGenerator, SampleQuestionInfo } from './SampleQuestionGenerator';
import { questionSampleStore } from './stores/QuestionSampleStore';

type Props = {
	questionInfo: IQuestionInfo,
	examOrder: number,
	examCode: string,
	examQuestionListLen: number,
	onUploadFile: any,
	isNowBulkLoading: boolean,
	sampleQuestions: Array<{ title: string, content: string }>,
}

// Add interface for TextEditor ref
interface TextEditorRef {
	setHTML: (html: string) => void;
}

export const QuestionRow = observer((props: Props) => {
	const i18next = useTranslation();
	const { questionInfo: qi, examOrder, examQuestionListLen, onUploadFile, sampleQuestions, examCode } = props;
	const { projectExamStore } = useStores();

	const questionInfo = toJS(qi);
	const classes = useEmotionStyles( ExamRangeUserStyle );
	const { order: questionOrder } = questionInfo;
	const [openSupport, setOpenSupport] = React.useState(false);
	const {alert, confirm} = useNeoConfirm();
	const [selectedSample, setSelectedSample] = React.useState<SampleQuestionInfo>({
		examOrder: examOrder,
		questionOrder: questionOrder,
		sampleQuestionIndex: -1,

		projectExamCode: examCode,
		isEssay: false
	  });
	  
	const [isDescriptive, setIsDescriptive] = React.useState(true);
	
	const isAbleToDelete = React.useMemo(() => {
		return questionOrder > 1;
	}, [questionOrder]);

	const currentOrderString = React.useMemo(() => {
		if (examQuestionListLen > 1) {
			return `${examOrder}-${questionOrder}`;
		}

		return examOrder;
	}, [examQuestionListLen, examOrder, questionOrder]);

	useEffect(() => {
		if (selectedSample) {
			questionSampleStore.setSelectedSample(selectedSample);
		}
	}, [selectedSample]); // ✅ Runs after `selectedSample` is updated

	const [currentQuestionHTML, setCurrentQuestionHTML] = React.useState(
		questionInfo.initialQuestion || ""
	);

	const currentReferenceHTML = React.useMemo(() => {
		if (questionInfo.initialReference) {
			setOpenSupport(true);
		}

		return questionInfo.initialReference ? questionInfo.initialReference : ""
	}, [questionInfo.initialReference]);

	const handleClickDelete = async () => {
		const result = await confirm(i18next.t("{{examOrder}}-{{questionOrder}}번 문항을 삭제하시겠습니까?", { examOrder, questionOrder }));
		if (!result) return;

		setSelectedSample((prev) => ({
			...prev,
			sampleQuestionIndex: -1,
			projectExamCode: examCode,
			isEssay: !isDescriptive
		}));

		projectExamStore.deleteQuestion(examOrder, questionOrder);
		questionSampleStore.adjustAfterDeleteQuestion(examOrder, questionOrder);

	};

	const handleChangeScore = (e) => {
		const value = e.target.value;
		// console.log(value, questionOrder);

		if (!value) {
			projectExamStore.changeQuestionScore(examOrder, questionOrder, value);
			return;
		}

		const pattern = /^[1-9]\d*$/;
		const isNumber = pattern.test(value);
		if (!isNumber) return;

		projectExamStore.changeQuestionScore(examOrder, questionOrder, Number(value));
	};

	const handleIncreaseScore = () => {
		const currentScore = questionInfo.score;

		projectExamStore.changeQuestionScore(examOrder, questionOrder, currentScore + 1);
	};

	const handleDecreaseScore = () => {
		const currentScore = questionInfo.score;
		if (currentScore <= 0) return;

		projectExamStore.changeQuestionScore(examOrder, questionOrder, currentScore - 1);
	};

	const handleChangeQuestion = (questionHtml: string) => {
		projectExamStore.changeQuestionContent(examOrder, questionOrder, questionHtml);
	};

	const handleChangeReference = (reference) => {
		projectExamStore.changeQuestionReference(examOrder, questionOrder, reference);
	};

	const handleInitInitialQuestion = () => {
		console.log(`handleInitInitialQuestion`);
		projectExamStore.initQuestionInitialQuestion(examOrder, questionOrder);
	};

	const handleInitInitialReference = () => {
		projectExamStore.initQuestionInitialReference(examOrder, questionOrder);
	};

	const handleClickSupportBtn = () => {
		setOpenSupport(prev => !prev);
	};

	//#region 문제 자동 fill-in
	const editorRef = React.useRef(null);

	// React.useEffect(()=>{
	// 	if(questionInfo.question.length > 0){
	// 		setInnerHtml(questionInfo.question);
	// 	}
	// },[questionInfo.question])

	const setInnerHtml = (html: string) => {
		const inst = editorRef.current;
		inst.setHTML(html);
	}
	//#endregion

	React.useEffect(() => {
		setCurrentQuestionHTML(questionInfo.question || questionInfo.initialQuestion || "");
	}, [questionInfo.question, questionInfo.initialQuestion]);

	const handleSampleChange = async (event: SelectChangeEvent) => {
		const selectedIndex = parseInt(event.target.value);
		if (isNaN(selectedIndex)) return;
		
		const confirmed = await confirm(i18next.t("선택한 예시 문항을 추가하시겠습니까?"));
		if (!confirmed) {
			return;
		}

		const sample = sampleQuestions[selectedIndex];
		projectExamStore.changeQuestionContent(examOrder, questionOrder, sample.content);
		setCurrentQuestionHTML(sample.content);
		
		// Call onSampleSelect with the selected sample
		const essaySampleState: [number, boolean] = [selectedIndex, !isDescriptive];
		
		setSelectedSample((prev) => ({
			...prev,
			sampleQuestionIndex: selectedIndex,
			projectExamCode: examCode,
			isEssay: !isDescriptive
		}));

	};

	return (
		<>
		<Box display='flex' alignItems='center' gap={0} sx={{ marginTop: "20px" }}>
				<FormControlLabel
					control={
						<Checkbox
							onChange={() => {
								setIsDescriptive(true);
								setSelectedSample((prev) => ({
									...prev,
									projectExamCode: examCode,
									isEssay: false
								}));
							}}
							checked={isDescriptive}
							icon={<CheckCircleIconOff />}
							checkedIcon={<CheckCircleIcon className={classes.checkedColor} />}
							disableRipple
						/>
					}
					label={i18next.t("서술형")}
					className={classes.checkMargin}
				/>
				<FormControlLabel
					control={
						<Checkbox
							onChange={() => {
								setIsDescriptive(false);
								setSelectedSample((prev) => ({
									...prev,
									projectExamCode: examCode,
									isEssay: true
								}));
							}}
							checked={!isDescriptive}
							icon={<CheckCircleIconOff />}
							checkedIcon={<CheckCircleIcon className={classes.checkedColor} />}
							disableRipple
						/>
					}
					label={i18next.t("논술형")}
					className={classes.checkMargin}
				/>
				<FormControl size="small" sx={{ minWidth: 200, margin: 0, padding: 0 }} disabled={isDescriptive}>
					<Select
						value={String(selectedSample.sampleQuestionIndex < 0 ? "" : selectedSample.sampleQuestionIndex)}
						onChange={handleSampleChange}
						displayEmpty
						className={classes.sampleSelect}
					>
						<MenuItem value="" disabled>
							<Typography>{i18next.t("예시 문항 선택")}</Typography>
						</MenuItem>
						{sampleQuestions.map((sample, index) => (
							<MenuItem key={index} value={index}>
								{sample.title}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box display='flex' alignItems='center' justifyContent='space-between' sx={{ marginTop: "0px" }}>
				
				<Box display='flex' alignItems='center' justifyContent='center'>
					<Typography className={classes.userTitle}>{i18next.t("{{currentOrder}}번 문항 입력", { currentOrder: currentOrderString })}</Typography>
					{isAbleToDelete &&
						<IconButton
							className={clsx(classes.iconBtnReset, classes.titleTrashIcon)}
							onClick={handleClickDelete}
							disableRipple>
							<TrashDeleteIcon />
						</IconButton>
					}
				</Box>
				<Box display='flex' alignItems='center' justifyContent='right'>
					<Typography className={classes.scoreMarkText}>{i18next.t("점수")}</Typography>
					<Box className={classes.scoreMark}>
						<TextField
							id="outlined-basic"
							variant="outlined"
							value={questionInfo.score}
							onChange={handleChangeScore}
							placeholder="15" />
						<Box className={classes.scoreMarkIcon}>
							<IconButton
								className={clsx(classes.iconBtnReset, classes.iconYearSelect)}
								onClick={handleIncreaseScore}
								disableRipple>
								<SelectIconUp />
							</IconButton>
							<IconButton
								className={clsx(classes.iconBtnReset, classes.iconYearSelect)}
								onClick={handleDecreaseScore}
								disableRipple>
								<SelectIcon />
							</IconButton>
						</Box>
					</Box>
					<Typography className={classes.scoreMarkSubText}>{i18next.t("점")}</Typography>
				</Box>
			</Box>
			<>
				<div style={{ overflow: "hidden" }}>
					<Box className={classes.editerBox} sx={{ position: 'relative' }}>
						<TextEditor
							placeholder={i18next.t("서술형 문항은 응답의 내용 범위와 서술 방식, 조건을 포함하여 기재할 수 있습니다.")}
							onUploadFile={onUploadFile}
							onSetHTML={handleChangeQuestion}
							currentHTML={currentQuestionHTML}
							onInitInitialContent={handleInitInitialQuestion}
							isNowBulkLoading={props.isNowBulkLoading}
							newHtml={questionInfo.question}
						/>

						{/*<Box className={classes.addViewBtn} onClick={handleClickSupportBtn}>*/}
						{/*    <Typography>보기 및 조건 추가</Typography>*/}
						{/*    <IconButton disableRipple*/}
						{/*                style={{ transform: !openSupport ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s ease' }}*/}
						{/*    >*/}
						{/*        <ToggleIcon />*/}
						{/*    </IconButton>*/}
						{/*</Box>*/}

					</Box>
				</div>
			</>
			
		</>
	)
});
